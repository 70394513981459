/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import {
  Customer,
  CustomerAddBody,
  CustomerDeleteBody,
  CustomerItemPayload,
  CustomerListParams,
  CustomerPasswordBody,
  CustomerUpdateBody,
} from './types';

const rootAPI = 'customers';

export const getCustomerListAPI = (payload?: CustomerListParams) => {
  const pagination = {
    ...payload?.pagination,
    limit: Number(payload?.pagination?.limit) > 50 ? 50 : payload?.pagination?.limit,
  };
  return axiosQuery.get<ResponseStructure<Customer[]>>(rootAPI, {
    params: { ...pagination, ...payload?.filterable },
  });
};

export const getCreditLimitListAPI = (payload?: CustomerListParams) => {
  const pagination = {
    ...payload?.pagination,
    limit: Number(payload?.pagination?.limit) > 50 ? 50 : payload?.pagination?.limit,
  };
  return axiosQuery.get<ResponseStructure<Customer[]>>(rootAPI + '/credit-limit', {
    params: { ...pagination, ...payload?.filterable },
  });
};

export const getCustomerWithSearchListAPI = (payload?: CustomerListParams) => {
  return axiosQuery.get<ResponseStructure<Customer[]>>(rootAPI + '/get-with-search', {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getCustomerItemAPI = (payload?: CustomerItemPayload) => {
  return axiosQuery.get<ResponseStructure<Customer>>(`${rootAPI}/${payload?.id}`);
};

export const addCustomerItemAPI = (payload?: CustomerAddBody) => {
  return axiosQuery.post<ResponseStructure<Customer>>(rootAPI, payload);
};

export const updateCustomerItemAPI = (payload?: CustomerUpdateBody) => {
  delete payload?.password;
  return axiosQuery.post<ResponseStructure<Customer>>(`${rootAPI}/${payload?.id}`, {
    _method: 'PATCH',
    ...payload,
  });
};

export const updateCreditLimitItemAPI = (payload?: CustomerUpdateBody) => {
  delete payload?.password;
  return axiosQuery.post<ResponseStructure<Customer>>(`${rootAPI}/credit-limit/${payload?.id}`, {
    _method: 'PUT',
    ...payload,
  });
};

export const deleteCustomerItemAPI = (payload?: CustomerDeleteBody) => {
  return axiosQuery.delete<ResponseStructure<Customer>>(`${rootAPI}/${payload?.id}`);
};

export const updateCustomerPasswordAPI = (payload: CustomerPasswordBody) => {
  return axiosQuery.post<ResponseStructure<Customer>>(`${rootAPI}/${payload.id}/update-password`, {
    _method: 'PATCH',
    ...payload,
  });
};

export const importCustomerFileAPI = (payload?: CustomerAddBody) => {
  return axiosQuery.post(`${rootAPI}/import`, payload?.file);
};
