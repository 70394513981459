/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { call, cancel, race, select, take, takeLatest } from 'redux-saga/effects';
import { GET_CURRENT_USER, LOGIN, LOGOUT, UPDATE_PASSWORD, UPDATE_PROFILE } from './actions';
import {
  getCurrentUserWorker,
  loginWorker,
  logoutWorker,
  updatePasswordWorker,
  updateProfileWorker,
} from './workers';

export function* authenticationSaga() {
  yield takeLatest(LOGIN, loginWorker);
  yield takeLatest(LOGOUT, logoutWorker);
  yield takeLatest(GET_CURRENT_USER, getCurrentUserWorker);
  yield takeLatest(UPDATE_PASSWORD, updatePasswordWorker);
  yield takeLatest(UPDATE_PROFILE, updateProfileWorker);
}
