/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { CustomerReturnItemBody } from './types';

export const createCustomerReturnItem = createAction<CustomerReturnItemBody>(
  'customerReturnItem/createCustomerReturnItem'
);
export const createCustomerReturnItemSuccess = createAction(
  'customerReturnItem/createCustomerReturnItem/success'
);

export const updateCustomerReturnItem = createAction<CustomerReturnItemBody>(
  'customerReturnItem/updateCustomerReturnItem'
);
export const updateCustomerReturnItemSuccess = createAction(
  'customerReturnItem/updateCustomerReturnItem/success'
);

export const deleteCustomerReturnItem = createAction<CustomerReturnItemBody>(
  'customerReturnItem/deleteCustomerReturnItem'
);
export const deleteCustomerReturnItemSuccess = createAction(
  'customerReturnItem/deleteCustomerReturnItem/success'
);

export const customerReturnItemError = createAction('customerReturnItem/error');
