import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import OverlayLoading from 'src/components/common/loading/OverlayLoading';
import { CustomerReturnItem } from 'src/features/customerReturnItem/types';
import { convertToCurrency, sumByField } from 'src/helpers/common.helpers';

type CustomerReturnTablePrintProps = {
  customerReturnItems?: CustomerReturnItem[];
  loading?: boolean;
};

const CustomerReturnTablePrint: React.FC<CustomerReturnTablePrintProps> = ({
  customerReturnItems,
  loading,
}) => {
  return (
    <>
      <strong>Customer Return</strong>
      <OverlayLoading loading={loading}>
        <CTable className="table-print" bordered responsive>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '5%' }}>
                #
              </CTableHeaderCell>
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '55%' }}>
                Item Name
              </CTableHeaderCell>
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '10%' }}>
                List Price
              </CTableHeaderCell>
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '5%' }}>
                Dis
              </CTableHeaderCell>
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '10%' }}>
                Sale Price
              </CTableHeaderCell>
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '5%' }}>
                Qty
              </CTableHeaderCell>
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '10%' }}>
                Total
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {customerReturnItems?.map((item, index) => (
              <CTableRow key={item.id}>
                <CTableHeaderCell className="text-center" scope="row">
                  {index + 1}
                </CTableHeaderCell>
                <CTableDataCell>
                  <span className="two-line">{item?.item_name}</span>
                </CTableDataCell>
                <CTableDataCell className="text-right">
                  {convertToCurrency(item.item_price)}
                </CTableDataCell>
                <CTableDataCell className="text-right"></CTableDataCell>
                <CTableDataCell className="text-right">
                  {convertToCurrency(item.item_price)}
                </CTableDataCell>
                <CTableDataCell className="text-right">{item.item_quantity}</CTableDataCell>
                <CTableDataCell className="text-right">
                  {convertToCurrency(item.total_price || 0)}
                </CTableDataCell>
              </CTableRow>
            ))}
            <CTableRow>
              <CTableHeaderCell className="text-center" scope="row">
                Total
              </CTableHeaderCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell className="text-right">
                {convertToCurrency(sumByField(customerReturnItems ?? [], 'item_price'))}
              </CTableDataCell>
              <CTableDataCell className="text-right"></CTableDataCell>
              <CTableDataCell className="text-right">
                {convertToCurrency(sumByField(customerReturnItems ?? [], 'item_price'))}
              </CTableDataCell>
              <CTableDataCell className="text-right">
                {sumByField(customerReturnItems ?? [], 'item_quantity')}
              </CTableDataCell>
              <CTableDataCell className="text-right">
                {convertToCurrency(sumByField(customerReturnItems ?? [], 'total_price'))}
              </CTableDataCell>
            </CTableRow>
          </CTableBody>
        </CTable>
      </OverlayLoading>
    </>
  );
};

export default React.memo(CustomerReturnTablePrint);
