/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import {
  addCustomerItemError,
  addCustomerItemSuccess,
  clearCustomerList,
  customerError,
  customerImportSuccess,
  deleteCustomerItemError,
  deleteCustomerItemSuccess,
  getCreditLimitListSuccess,
  getCustomerListError,
  getCustomerListSuccess,
  updateCreditLimitItemSuccess,
  updateCustomerItemError,
  updateCustomerItemSuccess,
  updateCustomerPasswordError,
  updateCustomerPasswordSuccess,
} from './actions';
import {
  addCustomerItemAPI,
  deleteCustomerItemAPI,
  getCreditLimitListAPI,
  getCustomerListAPI,
  importCustomerFileAPI,
  updateCreditLimitItemAPI,
  updateCustomerItemAPI,
  updateCustomerPasswordAPI,
} from './apis';
import {
  Customer,
  CustomerAddBody,
  CustomerDeleteBody,
  CustomerListParams,
  CustomerPasswordBody,
  CustomerUpdateBody,
} from './types';

export function* getCustomerListWorker(action: ActionSaga<CustomerListParams>) {
  try {
    const { data }: SagaReturnType<typeof getCustomerListAPI> = yield call(
      getCustomerListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getCustomerListSuccess({
        customers: data.data,
        pagination: newPagination,
      })
    );
  } catch {
    yield put(getCustomerListError());
  }
}

export function* getCreditLimitListWorker(action: ActionSaga<CustomerListParams>) {
  try {
    const { data }: SagaReturnType<typeof getCreditLimitListAPI> = yield call(
      getCreditLimitListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getCreditLimitListSuccess({
        customers: data.data,
        pagination: newPagination,
      })
    );
  } catch {
    yield put(getCustomerListError());
  }
}

export function* setFilterCustomerListWorker(action: ActionSaga<CustomerListParams>) {
  yield put(clearCustomerList());
  yield call(getCustomerListWorker, action);
}

export function* addCustomerItemWorker(
  action: ActionSaga<CustomerAddBody & { callback?: (customer?: Customer) => void }>
) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof addCustomerItemAPI> = yield call(
        addCustomerItemAPI,
        action.payload
      );
      yield put(addCustomerItemSuccess(data.data));
      if (action.payload?.callback) {
        action.payload?.callback?.(data.data);
      }
      showSuccessToast();
    }
  } catch (e) {
    yield put(addCustomerItemError());
    const { data } = e.response;
    if (data?.errors && data.errors?.code) {
      showErrorToast(data.errors.code);
    } else {
      showErrorToast(data.message);
    }
    // showErrorToast('Customer create error, Please check information and try again.');
  }
}

export function* customerImportWorker(action: ActionSaga<CustomerAddBody>) {
  try {
    if (action.payload) {
      yield call(importCustomerFileAPI, action.payload);
      yield put(customerImportSuccess());
      showSuccessToast();
    }
  } catch (e) {
    yield put(customerError());
    showErrorToast('Import failed');
  }
}

export function* updateCustomerItemWorker(action: ActionSaga<CustomerUpdateBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateCustomerItemAPI> = yield call(
        updateCustomerItemAPI,
        action.payload
      );
      yield put(updateCustomerItemSuccess(data.data));
      showSuccessToast();
    }
  } catch {
    yield put(updateCustomerItemError());
    showErrorToast();
  }
}

export function* updateCreditLimitItemWorker(action: ActionSaga<CustomerUpdateBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateCreditLimitItemAPI> = yield call(
        updateCreditLimitItemAPI,
        action.payload
      );
      yield put(updateCreditLimitItemSuccess(data.data));
      showSuccessToast();
    }
  } catch {
    yield put(updateCustomerItemError());
    showErrorToast();
  }
}

export function* deleteCustomerItemWorker(action: ActionSaga<CustomerDeleteBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof deleteCustomerItemAPI> = yield call(
        deleteCustomerItemAPI,
        action.payload
      );
      yield put(deleteCustomerItemSuccess({ ...data.data, id: action.payload.id }));
      showSuccessToast();
    }
  } catch {
    yield put(deleteCustomerItemError());
    showErrorToast();
  }
}

export function* updateCustomerPasswordWorker(action: ActionSaga<CustomerPasswordBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateCustomerPasswordAPI> = yield call(
        updateCustomerPasswordAPI,
        action.payload
      );
      yield put(updateCustomerPasswordSuccess(data.data));
      showSuccessToast();
    }
  } catch {
    yield put(updateCustomerPasswordError());
    showErrorToast();
  }
}

// logic CRUD flow
// link tham khảo: https://redux-saga.js.org/docs/advanced/NonBlockingCalls
