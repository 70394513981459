import { createReducer } from '@reduxjs/toolkit';
import { RequestOrderItemReducer } from './types';
import {
  createOrUpdateRequestOrderItem,
  createRequestOrderItem,
  createRequestOrderItemSuccess,
  deleteRequestOrderItem,
  deleteRequestOrderItemSuccess,
  requestOrderItemError,
  updateRequestOrderItem,
  updateRequestOrderItemSuccess,
} from './actions';

const initialState: RequestOrderItemReducer = {};

const requestOrderItemReducer = createReducer(initialState, (builder) => {
  builder.addCase(createOrUpdateRequestOrderItem, (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addCase(createRequestOrderItem, (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addCase(updateRequestOrderItem, (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addCase(createRequestOrderItemSuccess, (state) => {
    state.ui = { ...state.ui, loadingBtn: false, key: (state.ui?.key || 0) + 1 };
  });
  builder.addCase(updateRequestOrderItemSuccess, (state) => {
    state.ui = { ...state.ui, loadingBtn: false, key: (state.ui?.key || 0) + 1 };
  });
  builder.addCase(deleteRequestOrderItem, (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addCase(deleteRequestOrderItemSuccess, (state) => {
    state.ui = { ...state.ui, loadingBtn: false, key: (state.ui?.key || 0) + 1 };
  });
  builder.addCase(requestOrderItemError, (state) => {
    state.ui = {};
  });
});

export default requestOrderItemReducer;
