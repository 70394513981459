/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  createCustomerReturn,
  deleteCustomerReturn,
  getListCustomerReturn,
  getCustomerReturn,
  updateCustomerReturn,
} from './actions';
import {
  createCustomerReturnWORKER,
  deleteCustomerReturnWORKER,
  getListCustomerReturnWORKER,
  getCustomerReturnWORKER,
  updateCustomerReturnWORKER,
} from './workers';

export function* customerReturnSaga() {
  yield takeLatest(getListCustomerReturn, getListCustomerReturnWORKER);
  yield takeLatest(getCustomerReturn, getCustomerReturnWORKER);
  yield takeLatest(createCustomerReturn, createCustomerReturnWORKER);
  yield takeLatest(updateCustomerReturn, updateCustomerReturnWORKER);
  yield takeLatest(deleteCustomerReturn, deleteCustomerReturnWORKER);
}
