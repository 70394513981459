import { Permission } from 'src/features/permission/types';

export const checkHasPermission = (name: string, permissions: Permission[]): boolean => {
  let hasPermission = false;
  switch (name) {
    case 'MyTruckCart':
      hasPermission =
        permissions.findIndex(
          (e) => e.name === 'view truck cart' || e.name === 'view truck cart for everyone'
        ) >= 0;
      break;
    case 'LoadInToTruckDetail':
      hasPermission =
        permissions.findIndex(
          (e) => e.name === 'view load in' || e.name === 'view load in for everyone'
        ) >= 0;
      break;
    case 'LogLoadInGroupList':
    case 'LogLoadInGroupDetail':
    case 'LogLoadInInventoryPrint':
      hasPermission =
        permissions.findIndex(
          (e) => e.name === 'view history load in' || e.name === 'view history load in for everyone'
        ) >= 0;
      break;
    case 'LoadOutChooseTruck':
      hasPermission =
        permissions.findIndex(
          (e) => e.name === 'view unload' || e.name === 'view unload for everyone'
        ) >= 0;
      break;
    case 'DeliveryByUser':
    case 'DeliveryDetailByUser':
      hasPermission =
        permissions.findIndex((e) => e.name === 'view balance sheet for everyone') >= 0;
      break;
    case 'DeliveryByMe':
    case 'DeliveryDetailByMe':
      hasPermission = permissions.findIndex((e) => e.name === 'view balance sheet') >= 0;
      break;
    case 'InvoiceList':
      hasPermission = permissions.findIndex((e) => e.name === 'view invoice') >= 0;
      break;
    case 'InvoiceListManager':
      hasPermission = permissions.findIndex((e) => e.name === 'view invoice for everyone') >= 0;
      break;
    case 'InvoiceDetail':
    case 'InvoicePrint':
      hasPermission =
        permissions.findIndex(
          (e) => e.name === 'view invoice for everyone' || e.name === 'view invoice'
        ) >= 0;
      break;
    case 'RequestOrderList':
    case 'RequestOrderDetail':
    case 'RequestOrderPrint':
      hasPermission =
        permissions.findIndex(
          (e) => e.name === 'view request order' || e.name === 'view request order for everyone'
        ) >= 0;
      break;
    case 'HistoryCount':
      hasPermission = permissions.findIndex((e) => e.name === 'view history count') >= 0;
      break;
    case 'WarehouseReturnList':
    case 'WarehouseReturnDetail':
    case 'WarehouseReturnPrint':
      hasPermission =
        permissions.findIndex(
          (e) => e.name === 'view warehouse' || e.name === 'view warehouse for everyone'
        ) >= 0;
      break;
    case 'CustomerReturnList':
    case 'CustomerReturnDetail':
    case 'CustomerReturnPrint':
      hasPermission =
        permissions.findIndex(
          (e) => e.name === 'view customer return' || e.name === 'view customer return for everyone'
        ) >= 0;
      break;
    case 'InvoiceCreate':
      hasPermission =
        permissions.findIndex(
          (e) => e.name === 'create sale order' || e.name === 'create sale order for everyone'
        ) >= 0;
      break;
    case 'RequestOrderCreate':
      hasPermission =
        permissions.findIndex(
          (e) => e.name === 'create request order' || e.name === 'create request order for everyone'
        ) >= 0;
      break;
    case 'WarehouseReturnCreate':
      hasPermission =
        permissions.findIndex(
          (e) => e.name === 'create warehouse' || e.name === 'create warehouse for everyone'
        ) >= 0;
      break;
    case 'CustomerReturnCreate':
      hasPermission =
        permissions.findIndex(
          (e) =>
            e.name === 'create customer return' || e.name === 'create customer return for everyone'
        ) >= 0;
      break;
    case 'InventoryList':
      hasPermission = permissions.findIndex((e) => e.name === 'view inventory') >= 0;
      break;
    default:
      hasPermission = true;
      break;
  }

  return hasPermission;
};
