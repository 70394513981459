import dayjs from 'dayjs';
import React from 'react';
import TablePrint from './components/TablePrint';
import TemplatePrint from 'src/components/app/print/TemplatePrint';
import {
  addPrefixTruckCodeAndId,
  formatPhoneNumber,
  showDateTime,
} from 'src/helpers/common.helpers';
import InvoiceStatus from './components/InvoiceStatus';
import PaymentTablePrint from './components/PaymentTablePrint';
import CustomerReturnTablePrint from './components/CustomerReturnTablePrint';
import { CCol, CRow } from '@coreui/react';
import { Invoice } from 'src/features/invoice/types';

type InvoicePrint1Props = {
  invoice?: Invoice;
  loading?: boolean;
};

const InvoicePrint1: React.FC<InvoicePrint1Props> = ({ invoice, loading }) => {
  return (
    <div className="for-print mt-5">
      <TemplatePrint>
        <CRow className="mt-2" xs={{ gutter: 3 }}>
          <CCol md={4} className="col-print-4">
            <table style={{ width: '100%', fontSize: '0.9rem' }}>
              <tbody>
                <tr>
                  <td>
                    <strong>Inv No: </strong> {invoice?.id}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Inv Date: </strong>
                    {invoice?.date_time ? dayjs(invoice.date_time).format('YYYY-MM-DD HH:mm') : ''}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Print Date: </strong>{' '}
                    {showDateTime(new Date().toISOString(), 'MM/DD/YY HH:mm')}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Status: </strong>{' '}
                    {invoice?.id ? <InvoiceStatus isConvertString invoice={invoice} /> : null}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Prefix: </strong>{' '}
                    {invoice?.sub_index
                      ? addPrefixTruckCodeAndId(invoice.truck?.code, invoice.sub_index)
                      : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </CCol>
          <CCol md={4} className="col-print-4">
            <table style={{ width: '100%', fontSize: '0.9rem' }}>
              <tbody>
                <tr>
                  <td>
                    <strong>Sales Rep:</strong> {invoice?.user?.name}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Phone:</strong> {formatPhoneNumber(invoice?.user?.phone)}
                  </td>
                </tr>
              </tbody>
            </table>
          </CCol>
          <CCol md={4} className="col-print-4">
            <table style={{ width: '100%' }}>
              <tbody>
                <tr>
                  <td className="text-center table-header">Invoice</td>
                </tr>
              </tbody>
            </table>
          </CCol>

          <CCol md={4} className="col-print-4" style={{ fontSize: '0.9rem' }}>
            <table className="w-100">
              <tbody>
                <tr style={{ lineHeight: 1.5 }}>
                  <td>
                    <strong>Customer:</strong> {invoice?.customer?.name}
                  </td>
                </tr>
                <tr style={{ lineHeight: 1.5 }}>
                  <td>
                    {invoice?.customer?.address}
                    {invoice?.customer?.city ? `, ${invoice.customer.city}` : ''}
                    {invoice?.customer?.state ? `, ${invoice.customer.state}` : ''}
                    {invoice?.customer?.zipcode ? `, ${invoice.customer.zipcode}` : ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </CCol>

          <CCol md={8} className="col-print-8">
            <PaymentTablePrint
              invoice_payments={invoice?.invoice_payments ?? undefined}
              loading={loading}
              invoice_created_by={invoice?.user?.name}
            />
          </CCol>

          {!!invoice?.note && (
            <CCol md={12} style={{ fontSize: '1rem' }}>
              <strong>Note: </strong>
              {invoice?.note}
            </CCol>
          )}

          <CCol md={12}>
            <TablePrint invoice={invoice} loading={loading} />
          </CCol>

          {invoice?.customer_returns?.[0] ? (
            <CCol md={12}>
              <CustomerReturnTablePrint
                customerReturnItems={invoice.customer_returns[0].customer_return_items ?? []}
                loading={loading}
              />
            </CCol>
          ) : null}
        </CRow>
      </TemplatePrint>
    </div>
  );
};

export default React.memo(InvoicePrint1);
