import { createAction } from '@reduxjs/toolkit';
import { InvoicePayment, InvoicePaymentBody } from './types';

export const createInvoicePayment = createAction<InvoicePaymentBody>(
  'invoicePayment/createInvoicePayment'
);
export const createInvoicePaymentSuccess = createAction<InvoicePayment>(
  'invoicePayment/createInvoicePayment/success'
);

export const deleteInvoicePayment = createAction<InvoicePaymentBody>(
  'invoicePayment/deleteInvoicePayment'
);
export const deleteInvoicePaymentSuccess = createAction<InvoicePayment>(
  'invoicePayment/deleteInvoicePayment/success'
);

export const invoicePaymentError = createAction('invoicePayment/invoicePaymentError');
