/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { WarehouseReturn, WarehouseReturnBody } from './types';

export const getListWarehouseReturn = createAction<WarehouseReturnBody | undefined>(
  'warehouseReturn/getListWarehouseReturn'
);
export const getListWarehouseReturnSuccess = createAction<WarehouseReturn[]>(
  'warehouseReturn/getListWarehouseReturn/success'
);

export const getWarehouseReturn = createAction<WarehouseReturnBody>(
  'warehouseReturn/getWarehouseReturn'
);
export const getWarehouseReturnSuccess = createAction<WarehouseReturn>(
  'warehouseReturn/getWarehouseReturn/success'
);

export const createWarehouseReturn = createAction<WarehouseReturnBody>(
  'warehouseReturn/createWarehouseReturn'
);
export const createWarehouseReturnSuccess = createAction<WarehouseReturn>(
  'warehouseReturn/createWarehouseReturn/success'
);

export const updateWarehouseReturn = createAction<WarehouseReturnBody>(
  'warehouseReturn/updateWarehouseReturn'
);
export const updateWarehouseReturnSuccess = createAction<WarehouseReturn>(
  'warehouseReturn/updateWarehouseReturn/success'
);

export const deleteWarehouseReturn = createAction<WarehouseReturnBody>(
  'warehouseReturn/deleteWarehouseReturn'
);
export const deleteWarehouseReturnSuccess = createAction(
  'warehouseReturn/deleteWarehouseReturn/success'
);

export const warehouseReturnError = createAction('warehouseReturn/error');
