/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import {
  TruckCart,
  TruckCartAddBody,
  TruckCartDeleteBody,
  TruckCartItemPayload,
  TruckCartListInsideTruckParams,
  TruckCartListParams,
  TruckCartUpdateBody,
} from './types';

const rootAPI = 'truck-carts';

export const getTruckCartListAPI = (payload?: TruckCartListParams) => {
  return axiosQuery.get<ResponseStructure<TruckCart[]>>(rootAPI, {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getTruckCartListByDeliveryAPI = (payload: { truck_delivery_id: string }) => {
  return axiosQuery.get<ResponseStructure<TruckCart[]>>(
    rootAPI + '/truck-delivery/' + payload.truck_delivery_id
  );
};

export const getTruckCartListInsideTruckAPI = (payload: TruckCartListInsideTruckParams) => {
  return axiosQuery.get<ResponseStructure<TruckCart[]>>(`${rootAPI}/trucks/${payload.truck_id}`, {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getTruckCartItemAPI = (payload?: TruckCartItemPayload) => {
  return axiosQuery.get<ResponseStructure<TruckCart>>(`${rootAPI}/${payload?.id}`);
};

export const checkInventoryInTruckCartAPI = (payload?: TruckCartAddBody) => {
  return axiosQuery.post<ResponseStructure<TruckCart>>(`${rootAPI}/check-inventory`, payload);
};

export const addTruckCartItemAPI = (payload?: TruckCartAddBody) => {
  return axiosQuery.post<ResponseStructure<TruckCart>>(rootAPI, payload);
};

export const updateTruckCartItemAPI = (payload?: TruckCartUpdateBody) => {
  return axiosQuery.post<ResponseStructure<TruckCart>>(`${rootAPI}/${payload?.id}`, {
    _method: 'PUT',
    ...payload,
  });
};

export const deleteTruckCartItemAPI = (payload?: TruckCartDeleteBody) => {
  return axiosQuery.delete<ResponseStructure<TruckCart>>(`${rootAPI}/${payload?.id}`);
};
