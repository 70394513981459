/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { WarehouseReturnItemReducer } from './types';
import {
  createWarehouseReturnItem,
  createWarehouseReturnItemSuccess,
  deleteWarehouseReturnItem,
  deleteWarehouseReturnItemSuccess,
  updateWarehouseReturnItem,
  updateWarehouseReturnItemSuccess,
  warehouseReturnItemError,
} from './actions';

const initialState: WarehouseReturnItemReducer = {};

const warehouseReturnItemReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(warehouseReturnItemError, (state) => {
      state.ui = {};
    })
    .addMatcher(
      isAnyOf(updateWarehouseReturnItem, createWarehouseReturnItem, deleteWarehouseReturnItem),
      (state) => {
        state.ui = { ...state.ui, loadingBtn: true };
      }
    )
    .addMatcher(
      isAnyOf(
        updateWarehouseReturnItemSuccess,
        createWarehouseReturnItemSuccess,
        deleteWarehouseReturnItemSuccess
      ),
      (state) => {
        state.ui = { ...state.ui, loadingBtn: false, key: (state.ui?.key ?? 0) + 1 };
      }
    )
);

export default warehouseReturnItemReducer;
