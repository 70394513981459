/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { ActionSaga } from 'src/types/saga.types';
import { getLoadInInventoriesError, getLoadInInventoriesSuccess } from './actions';
import { getLoadInInventoriesAPI } from './apis';
import { LoadInInventoriesParams } from './types';

export function* getLoadInInventoriesWorker(action: ActionSaga<LoadInInventoriesParams>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof getLoadInInventoriesAPI> = yield call(
        getLoadInInventoriesAPI,
        payload
      );
      yield put(getLoadInInventoriesSuccess(data.data));
    }
  } catch (e) {
    console.error(e);
    yield put(getLoadInInventoriesError);
  }
}
