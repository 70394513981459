import { ActionSaga } from 'src/types/saga.types';
import { InvoicePaymentBody } from './types';
import { createInvoicePaymentAPI, deleteInvoicePaymentAPI } from './apis';
import { SagaReturnType, call, put } from 'redux-saga/effects';
import {
  createInvoicePaymentSuccess,
  deleteInvoicePaymentSuccess,
  invoicePaymentError,
} from './actions';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import {
  addPaymentItemFromPayment,
  removePaymentItemFromPayment,
  updateInvoiceItemError,
} from '../invoice/actions';

export function* createInvoicePaymentWorker(action: ActionSaga<InvoicePaymentBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof createInvoicePaymentAPI> = yield call(
        createInvoicePaymentAPI,
        action.payload
      );
      yield put(createInvoicePaymentSuccess(data.data));
      yield put(addPaymentItemFromPayment(data.data));
      showSuccessToast();

      if (action.payload.callback) {
        action.payload.callback(data.data);
      }
    }
  } catch {
    yield put(invoicePaymentError());
    showErrorToast();
  }
}

export function* deleteInvoicePaymentWorker(action: ActionSaga<InvoicePaymentBody>) {
  try {
    if (action.payload?.id) {
      const { data }: SagaReturnType<typeof deleteInvoicePaymentAPI> = yield call(
        deleteInvoicePaymentAPI,
        action.payload
      );
      yield put(deleteInvoicePaymentSuccess(data.data));
      yield put(removePaymentItemFromPayment({ ...data.data, id: action.payload.id }));
      if (action.payload.callback) {
        action.payload.callback(data.data);
      }
      showSuccessToast();
    }
  } catch {
    yield put(invoicePaymentError());
    showErrorToast();
  }
}
