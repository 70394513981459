/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { ActionSaga } from 'src/types/saga.types';
import { CustomerReturnItemBody } from './types';
import { SagaReturnType, call, put } from 'redux-saga/effects';
import {
  createCustomerReturnItemAPI,
  deleteCustomerReturnItemAPI,
  updateCustomerReturnItemAPI,
} from './apis';
import {
  createCustomerReturnItemSuccess,
  deleteCustomerReturnItemSuccess,
  updateCustomerReturnItemSuccess,
  customerReturnItemError,
} from './actions';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';

export function* updateCustomerReturnItemWORKER({ payload }: ActionSaga<CustomerReturnItemBody>) {
  try {
    if (!payload?.id) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof updateCustomerReturnItemAPI> = yield call(
      updateCustomerReturnItemAPI,
      payload
    );
    yield put(updateCustomerReturnItemSuccess());
    showSuccessToast('Update Customer Return Item Success');
    if (payload.callback) {
      payload.callback(data.data);
    }
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(customerReturnItemError());
  }
}

export function* createCustomerReturnItemWORKER({ payload }: ActionSaga<CustomerReturnItemBody>) {
  try {
    if (!payload) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof createCustomerReturnItemAPI> = yield call(
      createCustomerReturnItemAPI,
      payload
    );
    yield put(createCustomerReturnItemSuccess());
    showSuccessToast('Add Customer Return Item Success');
    if (payload.callback) {
      payload.callback(data.data);
    }
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(customerReturnItemError());
  }
}

export function* deleteCustomerReturnItemWORKER({ payload }: ActionSaga<CustomerReturnItemBody>) {
  try {
    if (!payload?.id) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof deleteCustomerReturnItemAPI> = yield call(
      deleteCustomerReturnItemAPI,
      payload
    );
    yield put(deleteCustomerReturnItemSuccess());
    showSuccessToast('Delete Customer Return Item Success');

    if (payload.callback) {
      payload.callback(data.data);
    }
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(customerReturnItemError());
  }
}
