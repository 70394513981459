/**
 * The event send to the store
 */

import { ActionSaga } from 'src/types/saga.types';
import {
  InvoiceDraftItemAddBody,
  InvoiceDraftItemDeleteBody,
  InvoiceDraftItemFilterable,
  InvoiceDraftSubItemPayload,
  InvoiceDraftSubItemSuccessPayload,
  InvoiceDraftItemListParams,
  InvoiceDraftItemListSuccessPayload,
  InvoiceDraftItemUI,
  InvoiceDraftItemUpdateBody,
  InvoiceDraftItem,
} from './types';

export const GET_INVOICE_DRAFT_ITEM_LIST = 'GET_INVOICE_DRAFT_ITEM_LIST';
export const GET_INVOICE_DRAFT_ITEM_LIST_SUCCESS = 'GET_INVOICE_DRAFT_ITEM_LIST_SUCCESS';
export const GET_INVOICE_DRAFT_ITEM_LIST_ERROR = 'GET_INVOICE_DRAFT_ITEM_LIST_ERROR';
export const SET_FILTER_INVOICE_DRAFT_ITEM_LIST = 'SET_FILTER_INVOICE_DRAFT_ITEM_LIST';
export const CLEAR_INVOICE_DRAFT_ITEM_LIST = 'CLEAR_INVOICE_DRAFT_ITEM_LIST';

export const GET_INVOICE_DRAFT_ITEM_ITEM = 'GET_INVOICE_DRAFT_ITEM_ITEM';
export const GET_INVOICE_DRAFT_ITEM_ITEM_SUCCESS = 'GET_INVOICE_DRAFT_ITEM_ITEM_SUCCESS';
export const GET_INVOICE_DRAFT_ITEM_ITEM_ERROR = 'GET_INVOICE_DRAFT_ITEM_ITEM_ERROR';

export const ADD_INVOICE_DRAFT_ITEM_ITEM = 'ADD_INVOICE_DRAFT_ITEM_ITEM';
export const ADD_MULTIPLE_INVOICE_DRAFT_ITEM_ITEM = 'ADD_MULTIPLE_INVOICE_DRAFT_ITEM_ITEM';
export const ADD_INVOICE_DRAFT_ITEM_ITEM_SUCCESS = 'ADD_INVOICE_DRAFT_ITEM_ITEM_SUCCESS';
export const ADD_INVOICE_DRAFT_ITEM_ITEM_ERROR = 'ADD_INVOICE_DRAFT_ITEM_ITEM_ERROR';

export const UPDATE_INVOICE_DRAFT_ITEM_ITEM = 'UPDATE_INVOICE_DRAFT_ITEM_ITEM';
export const UPDATE_INVOICE_DRAFT_ITEM_ITEM_SUCCESS = 'UPDATE_INVOICE_DRAFT_ITEM_ITEM_SUCCESS';
export const UPDATE_INVOICE_DRAFT_ITEM_ITEM_ERROR = 'UPDATE_INVOICE_DRAFT_ITEM_ITEM_ERROR';

export const DELETE_INVOICE_DRAFT_ITEM_ITEM = 'DELETE_INVOICE_DRAFT_ITEM_ITEM';
export const DELETE_INVOICE_DRAFT_ITEM_ITEM_SUCCESS = 'DELETE_INVOICE_DRAFT_ITEM_ITEM_SUCCESS';
export const DELETE_INVOICE_DRAFT_ITEM_ITEM_ERROR = 'DELETE_INVOICE_DRAFT_ITEM_ITEM_ERROR';

export const SET_INVOICE_DRAFT_ITEM_VISIBLE = 'SET_INVOICE_DRAFT_ITEM_VISIBLE';
export const PUSH_INVOICE_DRAFT_ITEM_TO_NEW = 'PUSH_INVOICE_DRAFT_ITEM_TO_NEW';

export const getInvoiceDraftItemList = (
  payload?: InvoiceDraftItemListParams
): ActionSaga<InvoiceDraftItemListParams> => ({
  type: GET_INVOICE_DRAFT_ITEM_LIST,
  payload: payload ? { ...payload } : null,
});

export const getInvoiceDraftItemListSuccess = (
  payload: InvoiceDraftItemListSuccessPayload
): ActionSaga<InvoiceDraftItemListSuccessPayload> => ({
  type: GET_INVOICE_DRAFT_ITEM_LIST_SUCCESS,
  payload: { ...payload },
});

export const getInvoiceDraftItemListError = (): ActionSaga => ({
  type: GET_INVOICE_DRAFT_ITEM_LIST_ERROR,
});

export const setFilterInvoiceDraftItemList = (
  payload?: InvoiceDraftItemFilterable
): ActionSaga<InvoiceDraftItemListParams> => ({
  type: SET_FILTER_INVOICE_DRAFT_ITEM_LIST,
  payload: { filterable: { ...payload } },
});

export const clearInvoiceDraftItemList = (): ActionSaga<InvoiceDraftItemListSuccessPayload> => ({
  type: CLEAR_INVOICE_DRAFT_ITEM_LIST,
  payload: { invoiceDraftItems: [] },
});

export const getInvoiceDraftSubItem = (
  payload: InvoiceDraftSubItemPayload
): ActionSaga<InvoiceDraftSubItemPayload> => ({
  type: GET_INVOICE_DRAFT_ITEM_ITEM,
  payload: { ...payload },
});

export const getInvoiceDraftSubItemSuccess = (
  payload: InvoiceDraftSubItemSuccessPayload
): ActionSaga<InvoiceDraftSubItemSuccessPayload> => ({
  type: GET_INVOICE_DRAFT_ITEM_ITEM_SUCCESS,
  payload: { ...payload },
});

export const getInvoiceDraftSubItemError = (): ActionSaga => ({
  type: GET_INVOICE_DRAFT_ITEM_ITEM_ERROR,
});

export const addInvoiceDraftSubItem = (
  payload: InvoiceDraftItemAddBody
): ActionSaga<InvoiceDraftItemAddBody> => ({
  type: ADD_INVOICE_DRAFT_ITEM_ITEM,
  payload: { ...payload },
});

export const addMultipleInvoiceDraftSubItem = (
  payload: InvoiceDraftItemAddBody
): ActionSaga<InvoiceDraftItemAddBody> => ({
  type: ADD_MULTIPLE_INVOICE_DRAFT_ITEM_ITEM,
  payload: { ...payload },
});

export const addInvoiceDraftSubItemSuccess = (
  payload: InvoiceDraftSubItemSuccessPayload
): ActionSaga<InvoiceDraftSubItemSuccessPayload> => ({
  type: ADD_INVOICE_DRAFT_ITEM_ITEM_SUCCESS,
  payload: { ...payload },
});

export const addInvoiceDraftSubItemError = (): ActionSaga => ({
  type: ADD_INVOICE_DRAFT_ITEM_ITEM_ERROR,
});

export const updateInvoiceDraftSubItem = (
  payload: InvoiceDraftItemUpdateBody
): ActionSaga<InvoiceDraftItemUpdateBody> => ({
  type: UPDATE_INVOICE_DRAFT_ITEM_ITEM,
  payload: { ...payload },
});

export const updateInvoiceDraftSubItemSuccess = (
  payload: InvoiceDraftSubItemSuccessPayload
): ActionSaga<InvoiceDraftSubItemSuccessPayload> => ({
  type: UPDATE_INVOICE_DRAFT_ITEM_ITEM_SUCCESS,
  payload: { ...payload },
});

export const updateInvoiceDraftSubItemError = (): ActionSaga => ({
  type: UPDATE_INVOICE_DRAFT_ITEM_ITEM_ERROR,
});

export const deleteInvoiceDraftSubItem = (
  payload: InvoiceDraftItemDeleteBody
): ActionSaga<InvoiceDraftItemDeleteBody> => ({
  type: DELETE_INVOICE_DRAFT_ITEM_ITEM,
  payload: { ...payload },
});

export const deleteInvoiceDraftSubItemSuccess = (
  payload: InvoiceDraftSubItemSuccessPayload
): ActionSaga<InvoiceDraftSubItemSuccessPayload> => ({
  type: DELETE_INVOICE_DRAFT_ITEM_ITEM_SUCCESS,
  payload: { ...payload },
});

export const deleteInvoiceDraftSubItemError = (): ActionSaga => ({
  type: DELETE_INVOICE_DRAFT_ITEM_ITEM_ERROR,
});

export const setInvoiceDraftItemVisible = (
  payload: InvoiceDraftItemUI<{ visible: boolean }>
): ActionSaga<InvoiceDraftItemUI<{ visible: boolean }>> => ({
  type: SET_INVOICE_DRAFT_ITEM_VISIBLE,
  payload: { ...payload },
});

export const pushInvoiceDraftItemToNew = (payload: {
  mutable: InvoiceDraftItem;
}): ActionSaga<{ mutable: InvoiceDraftItem }> => ({
  type: PUSH_INVOICE_DRAFT_ITEM_TO_NEW,
  payload: { ...payload },
});
