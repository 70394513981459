import { CButton } from '@coreui/react';
import React, { useEffect, useState } from 'react';

const SCROLL_CHECK = 500;

const ScrollToTop = () => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', checkIsScrollShow, false);
    return () => window.removeEventListener('scroll', checkIsScrollShow, false);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const checkIsScrollShow = () => {
    return setShow(document.documentElement.scrollTop > SCROLL_CHECK);
  };

  return show ? (
    <CButton className="btn-to-top" variant="outline" onClick={handleScrollToTop}>
      <i className="icofont-arrow-up"></i>
    </CButton>
  ) : null;
};

export default React.memo(ScrollToTop);
