import { createAction } from '@reduxjs/toolkit';
import { Permission, Role } from './types';

const root = 'permission';

export const getListPermission = createAction(`${root}/getListPermission`);
export const getListPermissionSuccess = createAction<Permission[]>(
  `${root}/getListPermission/success`
);

export const getListRole = createAction(`${root}/getListRole`);
export const getListRoleSuccess = createAction<Role[]>(`${root}/getListRole/success`);

export const setPermission = createAction(`${root}/setPermission`);
export const setPermissionSuccess = createAction<Permission>(`${root}/setPermission/success`);

export const permissionError = createAction(`${root}/error`);
