/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { CustomerReturn, CustomerReturnBody } from './types';

export const getListCustomerReturn = createAction<CustomerReturnBody | undefined>(
  'customerReturn/getListCustomerReturn'
);
export const getListCustomerReturnSuccess = createAction<CustomerReturn[]>(
  'customerReturn/getListCustomerReturn/success'
);

export const getCustomerReturn = createAction<CustomerReturnBody>(
  'customerReturn/getCustomerReturn'
);
export const getCustomerReturnSuccess = createAction<CustomerReturn>(
  'customerReturn/getCustomerReturn/success'
);

export const createCustomerReturn = createAction<CustomerReturnBody>(
  'customerReturn/createCustomerReturn'
);
export const createCustomerReturnSuccess = createAction<CustomerReturn>(
  'customerReturn/createCustomerReturn/success'
);

export const updateCustomerReturn = createAction<CustomerReturnBody>(
  'customerReturn/updateCustomerReturn'
);
export const updateCustomerReturnSuccess = createAction<CustomerReturn>(
  'customerReturn/updateCustomerReturn/success'
);

export const deleteCustomerReturn = createAction<CustomerReturnBody>(
  'customerReturn/deleteCustomerReturn'
);
export const deleteCustomerReturnSuccess = createAction(
  'customerReturn/deleteCustomerReturn/success'
);

export const customerReturnError = createAction('customerReturn/error');
