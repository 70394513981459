import { takeLatest } from 'redux-saga/effects';
import {
  createCustomerReturnItem,
  deleteCustomerReturnItem,
  updateCustomerReturnItem,
} from './actions';
import {
  createCustomerReturnItemWORKER,
  deleteCustomerReturnItemWORKER,
  updateCustomerReturnItemWORKER,
} from './workers';

/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */
export function* customerReturnItemSaga() {
  yield takeLatest(createCustomerReturnItem, createCustomerReturnItemWORKER);
  yield takeLatest(updateCustomerReturnItem, updateCustomerReturnItemWORKER);
  yield takeLatest(deleteCustomerReturnItem, deleteCustomerReturnItemWORKER);
}
