import React from 'react';

// FOR TEST
const Test = React.lazy(() => import('./views/dashboard/TestDashboard'));

const UserList = React.lazy(() => import('./views/dashboard/user/UserList'));
const TruckList = React.lazy(() => import('./views/dashboard/truck/TruckList'));
const TruckCartInsideTruckList = React.lazy(
  () => import('./views/dashboard/truckCart/TruckCartInsideTruckList')
);
const InventoryList = React.lazy(() => import('./views/dashboard/inventory/InventoryList'));
const InventoryDetail = React.lazy(() => import('./views/dashboard/inventory/InventoryDetail'));
const InventoryImport = React.lazy(() => import('./views/dashboard/inventory/InventoryImport'));
const CategoryList = React.lazy(() => import('./views/dashboard/category/CategoryList'));
const CustomerList = React.lazy(() => import('./views/dashboard/customer/CustomerList'));
const MyProfile = React.lazy(() => import('./views/dashboard/myProfile/MyProfile'));
const SettingList = React.lazy(() => import('./views/dashboard/setting/SettingList'));
const CreditLimitList = React.lazy(() => import('./views/dashboard/creditLimit/CreditLimitList'));
const CustomerImport = React.lazy(() => import('./views/dashboard/customer/CustomerImport'));
//application
const InvoiceList = React.lazy(() => import('./views/dashboard/invoice/InvoiceList'));
const InvoiceChooseTruck = React.lazy(() => import('./views/dashboard/invoice/InvoiceChooseTruck'));
const InvoicePrint = React.lazy(() => import('./views/dashboard/invoice/InvoicePrint'));
const CustomerAdd = React.lazy(() => import('./views/dashboard/customer/CustomerAdd'));
const InvoiceListNavigate = React.lazy(
  () => import('./views/dashboard/invoice/InvoiceListNavigate')
);
const InvoiceInDelivery = React.lazy(
  () => import('./views/dashboard/truckDelivery/InvoiceInDelivery')
);
const CustomerReturnInDelivery = React.lazy(
  () => import('./views/dashboard/truckDelivery/CustomerReturnInDelivery')
);
const WarehouseReturnInDelivery = React.lazy(
  () => import('./views/dashboard/truckDelivery/WarehouseReturnInDelivery')
);

// Dashboard
const Dashboard = React.lazy(() => import('./views/dashboard/AppDashboard'));

// Load in
const LoadInInventories = React.lazy(() => import('./views/dashboard/loadIn/LoadInInventories'));
const LoadInToTruckDetail = React.lazy(
  () => import('./views/dashboard/loadInToTruck/LoadInToTruckDetail')
);
const LoadInPrint = React.lazy(() => import('./views/dashboard/loadIn/Print'));

// Load out
const LoadOutInventories = React.lazy(() => import('./views/dashboard/loadOut/LoadOutInventories'));
const LoadOutChooseTruck = React.lazy(() => import('./views/dashboard/loadOut/LoadOutChooseTruck'));

// Truck delivery
const TruckDeliveryDashboard = React.lazy(
  () => import('./views/dashboard/truckDelivery/DeliveryDashboard')
);
const DeliveryByUser = React.lazy(() => import('./views/dashboard/truckDelivery/DeliveryByUser'));
const DeliveryByMe = React.lazy(() => import('./views/dashboard/truckDelivery/DeliveryByMe'));
const DeliveryDetail = React.lazy(() => import('./views/dashboard/truckDelivery/DeliveryDetail'));
const DeliveryNavigate = React.lazy(
  () => import('./views/dashboard/truckDelivery/DeliveryNavigate')
);

// Truck cart
const ChooseTruckCart = React.lazy(() => import('./views/dashboard/truckCart/ChooseTruckCart'));

// Request order
const RequestOrderCreate = React.lazy(
  () => import('./views/dashboard/requestOrder/RequestOrderCreate')
);
const RequestOrderList = React.lazy(
  () => import('./views/dashboard/requestOrder/RequestOrderList')
);
const RequestOrderDetail = React.lazy(
  () => import('./views/dashboard/requestOrder/RequestOrderCreate')
);
const RequestOrderPrint = React.lazy(() => import('./views/dashboard/requestOrder/Print'));

// Invoices
const InvoiceGenerate = React.lazy(() => import('./views/dashboard/invoice/InvoiceGenerate'));
const InvoiceGenerateDetail = React.lazy(
  () => import('./views/dashboard/invoice/InvoiceGenerateDetail')
);
const InvoiceDraftGenerateDetail = React.lazy(
  () => import('./views/dashboard/invoiceDraft/InvoiceDraftGenerateDetail')
);
const InvoiceSearch = React.lazy(() => import('./views/dashboard/invoice/InvoiceSearch'));

// Log Load In Inventory
const LogLoadInInventoryPrint = React.lazy(
  () => import('./views/dashboard/logLoadInInventory/Print')
);

// Log Load In Group
const LogLoadInGroupList = React.lazy(
  () => import('./views/dashboard/logLoadInGroup/LogLoadInGroupList')
);
const LogLoadInGroupDetail = React.lazy(
  () => import('./views/dashboard/logLoadInGroup/LogLoadInGroupDetail')
);
const LogLoadInGroupInDelivery = React.lazy(
  () => import('./views/dashboard/truckDelivery/LogLoadInGroupInDelivery')
);

// History Count
const HistoryCount = React.lazy(() => import('./views/dashboard/historyCount/HistoryCount'));

// Warehouse Return
const WarehouseReturnList = React.lazy(
  () => import('./views/dashboard/warehouseReturn/WarehouseReturnList')
);
const WarehouseReturnCreate = React.lazy(
  () => import('./views/dashboard/warehouseReturn/WarehouseReturnCreate')
);
const WarehouseReturnDetail = React.lazy(
  () => import('./views/dashboard/warehouseReturn/WarehouseReturnDetail')
);
const WarehouseReturnPrint = React.lazy(
  () => import('./views/dashboard/warehouseReturn/WarehouseReturnPrint')
);

// Customer Return
const CustomerReturnList = React.lazy(
  () => import('./views/dashboard/customerReturn/CustomerReturnList')
);
const CustomerReturnCreate = React.lazy(
  () => import('./views/dashboard/customerReturn/CustomerReturnCreate')
);
const CustomerReturnDetail = React.lazy(
  () => import('./views/dashboard/customerReturn/CustomerReturnDetail')
);
const CustomerReturnPrint = React.lazy(
  () => import('./views/dashboard/customerReturn/CustomerReturnPrint')
);

// Permission
const PermissionList = React.lazy(() => import('./views/dashboard/permission/PermissionList'));

// Backup
const BackupList = React.lazy(() => import('./views/dashboard/backup/List'));

//Invoice drafts
const InvoiceDraftChooseTruck = React.lazy(
  () => import('./views/dashboard/invoiceDraft/InvoiceDraftChooseTruck')
);

// Add ons
const SelectInvoice = React.lazy(() => import('./views/dashboard/addOns/invoice/SelectInvoice'));

//Report
const DailyReport = React.lazy(() => import('./views/dashboard/report/DailyReport'));
const MonthlyReport = React.lazy(() => import('./views/dashboard/reportMonthly/MonthlyReport'));

// Payment
const Payment = React.lazy(() => import('./views/dashboard/addOns/payment/Payment'));
const CheckPayment = React.lazy(() => import('./views/dashboard/addOns/payment/CheckPayment'));

// Print
const Print = React.lazy(() => import('./views/dashboard/addOns/print'));

const routes = [
  // application
  {
    path: '/',
    exact: true,
    name: 'Dashboard',
    element: Dashboard,
  },
  {
    path: '/my-truck-cart',
    exact: true,
    name: 'MyTruckCart',
    element: () => <ChooseTruckCart />,
  },
  // { path: '/inventories/find', exact: true, name: 'InventoryScan', element: InventoryScan },

  {
    path: '/invoices/:invoice_id',
    exact: true,
    name: 'InvoiceDetail',
    element: InvoiceGenerateDetail,
  },
  {
    path: '/invoice-drafts/:invoice_draft_id',
    exact: true,
    name: 'InvoiceDraftGenerateDetail',
    element: InvoiceDraftGenerateDetail,
  },
  { path: 'my-profile', exact: true, name: 'MyProfile', element: MyProfile },
  { path: 'inventories/:id', exact: true, name: 'InventoryDetail', element: InventoryDetail },
  {
    path: 'my-invoices',
    exact: true,
    name: 'InvoiceList',
    element: () => <InvoiceList type="personal" />,
  },
  {
    path: 'invoices/create',
    exact: true,
    name: 'InvoiceCreate',
    element: () => <InvoiceChooseTruck />,
  },
  {
    path: 'invoices/:invoice_id/print',
    exact: true,
    name: 'InvoicePrint',
    element: () => <InvoicePrint />,
  },
  {
    path: 'invoices/search',
    exact: true,
    name: 'InvoiceSearch',
    element: () => <InvoiceSearch />,
  },
  { path: '/dashboard', exact: true, name: 'Dashboard', element: Dashboard },
  {
    path: '/load-in-inventories',
    exact: true,
    name: 'LoadInInventories',
    element: LoadInInventories,
  },
  {
    path: '/load-out-inventories',
    exact: true,
    name: 'LoadOutInventories',
    element: LoadOutInventories,
  },
  {
    path: '/customers/add',
    exact: true,
    name: 'CustomerAdd',
    element: () => <CustomerAdd />,
  },

  {
    path: '/my-truck-deliveries',
    exact: true,
    name: 'DeliveryByMe',
    element: () => <DeliveryByMe />,
  },
  {
    path: 'truck-deliveries',
    exact: true,
    name: 'DeliveryByUser',
    element: () => <DeliveryByUser />,
  },
  {
    path: 'my-truck-deliveries/:truck_delivery_id',
    exact: true,
    name: 'DeliveryDetailByMe',
    element: () => <DeliveryDetail isAdmin />,
  },
  {
    path: 'truck-deliveries/:truck_delivery_id',
    exact: true,
    name: 'DeliveryDetailByUser',
    element: () => <DeliveryDetail isAdmin />,
  },
  {
    path: 'invoice-truck-deliveries/:truck_delivery_id',
    exact: true,
    name: 'InvoiceInDelivery',
    element: () => <InvoiceInDelivery />,
  },
  {
    path: 'customer-return-truck-deliveries/:truck_delivery_id',
    exact: true,
    name: 'CustomerReturnInDelivery',
    element: () => <CustomerReturnInDelivery />,
  },
  {
    path: 'warehouse-return-truck-deliveries/:truck_delivery_id',
    exact: true,
    name: 'WarehouseReturnInDelivery',
    element: () => <WarehouseReturnInDelivery />,
  },
  {
    path: 'log-load-in-group-truck-deliveries/:truck_delivery_id',
    exact: true,
    name: 'LogLoadInGroupInDelivery',
    element: () => <LogLoadInGroupInDelivery />,
  },
  {
    path: 'load-in-to-truck',
    exact: true,
    name: 'LoadInToTruckDetail',
    element: () => <LoadInToTruckDetail />,
  },
  {
    path: 'load-in-print/:truck_id',
    exact: true,
    name: 'LoadInPrint',
    element: () => <LoadInPrint />,
  },
  {
    path: 'navigate-truck-deliveries',
    exact: true,
    name: 'DeliveryNavigate',
    element: () => <DeliveryNavigate />,
  },
  {
    path: 'navigate-invoices',
    exact: true,
    name: 'InvoiceListNavigate',
    element: () => <InvoiceListNavigate />,
  },
  {
    path: 'request-order/create',
    exact: true,
    name: 'RequestOrderCreate',
    element: () => <RequestOrderCreate />,
  },
  {
    path: 'request-order',
    exact: true,
    name: 'RequestOrderList',
    element: () => <RequestOrderList />,
  },
  {
    path: 'request-order/:request_order_id',
    exact: true,
    name: 'RequestOrderDetail',
    element: () => <RequestOrderDetail />,
  },
  {
    path: 'request-order/:request_order_id/print',
    exact: true,
    name: 'RequestOrderPrint',
    element: () => <RequestOrderPrint />,
  },
  {
    path: 'unload-to-truck',
    exact: true,
    name: 'LoadOutChooseTruck',
    element: () => <LoadOutChooseTruck />,
  },
  {
    path: 'invoices',
    exact: true,
    name: 'InvoiceListManager',
    element: () => <InvoiceList type="manager" />,
  },
  {
    path: 'invoices/generate',
    exact: true,
    name: 'InvoiceGenerate',
    element: () => <InvoiceGenerate />,
  },
  {
    path: 'invoices/generate/:invoice_id',
    exact: true,
    name: 'InvoiceGenerateDetail',
    element: () => <InvoiceGenerateDetail />,
  },
  {
    path: 'invoice-drafts/create',
    exact: true,
    name: 'InvoiceDraftChooseTruck',
    element: () => <InvoiceDraftChooseTruck />,
  },
  {
    path: 'log-load-in-group/:log_load_in_group_id/print',
    exact: true,
    name: 'LogLoadInInventoryPrint',
    element: () => <LogLoadInInventoryPrint />,
  },
  {
    path: 'log-load-in-group',
    exact: true,
    name: 'LogLoadInGroupList',
    element: () => <LogLoadInGroupList />,
  },
  {
    path: 'log-load-in-group/:log_load_in_group_id',
    exact: true,
    name: 'LogLoadInGroupDetail',
    element: () => <LogLoadInGroupDetail />,
  },
  {
    path: 'history-count',
    exact: true,
    name: 'HistoryCount',
    element: () => <HistoryCount />,
  },
  {
    path: 'warehouse-returns',
    exact: true,
    name: 'WarehouseReturnList',
    element: () => <WarehouseReturnList />,
  },
  {
    path: 'warehouse-returns/create',
    exact: true,
    name: 'WarehouseReturnCreate',
    element: () => <WarehouseReturnCreate />,
  },
  {
    path: 'warehouse-returns/:warehouse_id',
    exact: true,
    name: 'WarehouseReturnDetail',
    element: () => <WarehouseReturnDetail />,
  },
  {
    path: 'warehouse-returns/:warehouse_id/print',
    exact: true,
    name: 'WarehouseReturnPrint',
    element: () => <WarehouseReturnPrint />,
  },
  {
    path: 'customer-returns',
    exact: true,
    name: 'CustomerReturnList',
    element: () => <CustomerReturnList />,
  },
  {
    path: 'customer-returns/:customer_return_id',
    exact: true,
    name: 'CustomerReturnDetail',
    element: () => <CustomerReturnDetail />,
  },
  {
    path: 'customer-returns/:customer_return_id/print',
    exact: true,
    name: 'CustomerReturnPrint',
    element: () => <CustomerReturnPrint />,
  },
  { path: 'inventories', exact: true, name: 'InventoryList', element: InventoryList },
  {
    path: 'test',
    exact: true,
    name: 'Test',
    element: () => <Test />,
  },
  {
    path: 'select-invoice',
    exact: true,
    name: 'SelectInvoice',
    element: () => <SelectInvoice />,
  },
  {
    path: 'report/daily-report',
    exact: true,
    name: 'DailyReport',
    element: () => <DailyReport />,
  },
  {
    path: 'report/monthly-report',
    exact: true,
    name: 'MonthlyReport',
    element: () => <MonthlyReport />,
  },
  {
    path: 'check-payment',
    exact: true,
    name: 'Payment',
    element: () => <CheckPayment />,
  },
  {
    path: 'print',
    exact: true,
    name: 'Print',
    element: () => <Print />,
  },
];

export const protectRoutes = [
  { path: 'users', exact: true, name: 'UserList', element: UserList },
  { path: 'trucks', exact: true, name: 'TruckList', element: TruckList },
  {
    path: 'trucks/cart/:truck_id',
    exact: true,
    name: 'TruckCartInsideTruckList',
    element: () => <TruckCartInsideTruckList showBreadcrumb name="truckCartInTruck" />,
  },

  { path: 'inventories/:id', exact: true, name: 'InventoryDetail', element: InventoryDetail },
  { path: 'inventories/import', exact: true, name: 'InventoryImport', element: InventoryImport },
  { path: 'categories', exact: true, name: 'CategoryList', element: CategoryList },
  { path: 'customers', exact: true, name: 'CategoryList', element: CustomerList },

  {
    path: 'settings',
    exact: true,
    name: 'SettingList',
    element: () => <SettingList />,
  },
  {
    path: 'credit-limits',
    exact: true,
    name: 'CreditLimitList',
    element: () => <CreditLimitList />,
  },

  {
    path: 'customers/import',
    exact: true,
    name: 'CustomerImport',
    element: () => <CustomerImport />,
  },
  {
    path: 'permissions',
    exact: true,
    name: 'PermissionList',
    element: () => <PermissionList />,
  },
  {
    path: 'backup',
    exact: true,
    name: 'BackupList',
    element: () => <BackupList />,
  },
];

export default routes;
