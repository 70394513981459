import { CButton, CModal, CModalBody, CModalFooter } from '@coreui/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonLoading from '../button/ButtonLoading';
import { CModalBodyProps } from '@coreui/react/dist/components/modal/CModalBody';

type PopconfirmProps = {
  trigger?: React.ReactElement;
  onConfirm?: () => void;
  loading?: boolean;
  visible?: boolean;
  hideIcon?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  message?: React.ReactNode;
  modalBodyProps?: CModalBodyProps;
  okText?: string;
};

const Popconfirm: React.FC<PopconfirmProps> = (props) => {
  const {
    trigger,
    loading,
    visible,
    children,
    message,
    modalBodyProps,
    hideIcon,
    okText,
    onConfirm,
    onClose,
  } = props;
  const [currentVisible, setCurrentVisible] = useState<boolean>(visible ?? false);
  const [currentLoading, setCurrentLoading] = useState<boolean>(loading ?? false);

  const { t } = useTranslation();

  const onOk = () => {
    setCurrentLoading(true);
    onConfirm?.();
    setCurrentVisible(false);
  };

  const onCancel = () => {
    onClose?.();
    setCurrentVisible(false);
  };

  useEffect(() => {
    setCurrentVisible(visible ?? false);
  }, [visible]);

  return (
    <>
      <span className="popconfirm-trigger" onClick={() => setCurrentVisible(true)}>
        {trigger || t('confirm')}{' '}
      </span>

      {currentVisible && (
        <CModal
          className="top-popconfirm"
          alignment="center"
          visible={currentVisible}
          onClose={onCancel}
        >
          <CModalBody className="text-center" {...modalBodyProps}>
            {!hideIcon && (
              <div style={{ marginBottom: 20 }}>
                <i className="icofont-warning display-6"></i>
              </div>
            )}
            <h5> {message || t('confirm-action')} </h5>
            {children}
          </CModalBody>
          <CModalFooter>
            <CButton color="link" onClick={onCancel}>
              {t('close')}
            </CButton>

            <ButtonLoading color="primary" onClick={onOk}>
              <i className="icofont-check me-2"></i>
              {okText || t('submit')}
            </ButtonLoading>
          </CModalFooter>
        </CModal>
      )}
    </>
  );
};

export default React.memo(Popconfirm);
