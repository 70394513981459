/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import {
  createRequestOrderSuccess,
  deleteRequestOrderSuccess,
  getRequestOrderItem,
  getRequestOrderItemSuccess,
  getRequestOrderListSuccess,
  requestOrderError,
  updateRequestOrderSuccess,
} from './actions';
import {
  addToTruckRequestOrderAPI,
  createInvoiceRequestOrderAPI,
  createRequestOrderAPI,
  deleteRequestOrderAPI,
  getRequestOrderItemAPI,
  getRequestOrderManagerListAPI,
  getRequestOrderPersonalListAPI,
  revertInvoiceRequestOrderAPI,
  revertTruckRequestOrderAPI,
  updateRequestOrderAPI,
} from './apis';
import { RequestOrderBody, RequestOrderParams } from './types';
import { sortByField } from 'src/helpers/common.helpers';
import { clearFieldInvoiceItem, getInvoiceItem } from '../invoice/actions';

export function* getRequestOrderManagerListWorker(action: ActionSaga<RequestOrderParams>) {
  try {
    const { data }: SagaReturnType<typeof getRequestOrderManagerListAPI> = yield call(
      getRequestOrderManagerListAPI,
      action.payload || undefined
    );
    yield put(getRequestOrderListSuccess(sortByField(data.data, ['date_time'], 'desc')));
  } catch {
    yield put(requestOrderError());
    showErrorToast();
  }
}

export function* getRequestOrderPersonalListWorker(action: ActionSaga<RequestOrderParams>) {
  try {
    const { data }: SagaReturnType<typeof getRequestOrderPersonalListAPI> = yield call(
      getRequestOrderPersonalListAPI,
      action.payload || undefined
    );
    yield put(getRequestOrderListSuccess(sortByField(data.data, ['date_time'], 'desc')));
  } catch {
    yield put(requestOrderError());
    showErrorToast();
  }
}

export function* getRequestOrderItemWorker(action: ActionSaga<RequestOrderParams>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getRequestOrderItemAPI> = yield call(
        getRequestOrderItemAPI,
        action.payload
      );
      yield put(getRequestOrderItemSuccess(data.data));

      if (action.payload.callback) {
        action.payload.callback(data.data);
      }
    }
  } catch {
    yield put(requestOrderError());
    showErrorToast();
  }
}

export function* updateRequestOrderWorker(
  action: ActionSaga<RequestOrderBody & { reload?: boolean }>
) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateRequestOrderAPI> = yield call(
        updateRequestOrderAPI,
        action.payload
      );
      yield put(updateRequestOrderSuccess(data.data));

      if (action.payload.reload) {
        yield put(getRequestOrderItem({ id: data.data.id }));
      }

      if (action.payload.callback) {
        action.payload.callback(data.data);
      }
      showSuccessToast();
    }
  } catch {
    yield put(requestOrderError());
    showErrorToast();
  }
}

export function* createRequestOrderWorker(action: ActionSaga<RequestOrderBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof createRequestOrderAPI> = yield call(
        createRequestOrderAPI,
        action.payload
      );
      yield put(createRequestOrderSuccess(data.data));
      window.location.replace(`#/request-order/${data.data.id}`);
      showSuccessToast();
    }
  } catch {
    yield put(requestOrderError());
    showErrorToast();
  }
}

export function* deleteRequestOrderWorker(action: ActionSaga<RequestOrderBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof deleteRequestOrderAPI> = yield call(
        deleteRequestOrderAPI,
        action.payload
      );
      yield put(deleteRequestOrderSuccess(data.data));
      showSuccessToast();
      window.location.replace(`#/request-order`);
    }
  } catch {
    yield put(requestOrderError());
    showErrorToast();
  }
}

export function* addToTruckRequestOrderWorker(action: ActionSaga<RequestOrderBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof addToTruckRequestOrderAPI> = yield call(
        addToTruckRequestOrderAPI,
        action.payload
      );
      yield put(updateRequestOrderSuccess(data.data));
      yield put(getRequestOrderItem({ id: data.data.id }));
      showSuccessToast();
    }
  } catch {
    yield put(requestOrderError());
    showErrorToast();
  }
}

export function* revertTruckRequestOrderWorker(action: ActionSaga<RequestOrderBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof revertTruckRequestOrderAPI> = yield call(
        revertTruckRequestOrderAPI,
        action.payload
      );
      yield put(updateRequestOrderSuccess(data.data));
      yield put(getRequestOrderItem({ id: data.data.id }));
      showSuccessToast();
    }
  } catch {
    yield put(requestOrderError());
    showErrorToast();
  }
}

export function* createInvoiceRequestOrderWorker(action: ActionSaga<RequestOrderBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof createInvoiceRequestOrderAPI> = yield call(
        createInvoiceRequestOrderAPI,
        action.payload
      );
      yield put(updateRequestOrderSuccess(data.data));
      yield put(
        getRequestOrderItem({
          id: data.data.id,
        })
      );
      if (data.data.invoice_id) {
        yield put(getInvoiceItem({ id: data.data.invoice_id }));
      }
      showSuccessToast();
    }
  } catch {
    yield put(requestOrderError());
    showErrorToast();
  }
}

export function* revertInvoiceRequestOrderWorker(action: ActionSaga<RequestOrderBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof revertInvoiceRequestOrderAPI> = yield call(
        revertInvoiceRequestOrderAPI,
        action.payload
      );
      yield put(updateRequestOrderSuccess(data.data));
      yield put(getRequestOrderItem({ id: data.data.id }));
      yield put(clearFieldInvoiceItem());

      showSuccessToast();
    }
  } catch {
    yield put(requestOrderError());
    showErrorToast();
  }
}
