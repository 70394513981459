import { CSpinner } from '@coreui/react';
import React from 'react';

type OverlayLoadingProps = {
  children?: React.ReactNode;
  loading?: boolean;
};

const OverlayLoading: React.FC<OverlayLoadingProps> = (props) => {
  const { children, loading } = props;
  return (
    <div className="loading-overlay">
      {loading && (
        <div className="loading-icon">
          <CSpinner color="primary" variant="grow" />
        </div>
      )}
      <div className={'loading-body' + (loading ? ' active' : '')}>{children}</div>
    </div>
  );
};

export default React.memo(OverlayLoading);
