/**
 * For process asynchronous
 * The apis fetch
 */

import { ResponseStructure } from 'src/types/axios.types';
import { WarehouseReturnItem, WarehouseReturnItemBody } from './types';
import { axiosQuery } from 'src/services/axiosQuery';

const rootAPI = 'warehouse-return-items';

export const createWarehouseReturnItemAPI = (payload: WarehouseReturnItemBody) => {
  return axiosQuery.post<ResponseStructure<WarehouseReturnItem>>(rootAPI, payload);
};

export const updateWarehouseReturnItemAPI = (payload: WarehouseReturnItemBody) => {
  return axiosQuery.put<ResponseStructure<WarehouseReturnItem>>(
    rootAPI + '/' + payload.id,
    payload
  );
};

export const deleteWarehouseReturnItemAPI = (payload: WarehouseReturnItemBody) => {
  return axiosQuery.delete<ResponseStructure<WarehouseReturnItem>>(rootAPI + '/' + payload.id);
};
