import { createAction } from '@reduxjs/toolkit';
import { AppUI } from 'src/types/common.types';
import { Setting, SettingAddBody, SettingParams } from './types';

export const getSettingList = createAction<SettingParams | undefined>('setting/get');
export const getSettingListSuccess = createAction<{ settings: Setting[] }>('setting/get/success');
export const getSettingListError = createAction('setting/get/error');

export const getSettingGlobalTax = createAction('setting/global-tax');
export const getSettingGlobalTaxSuccess = createAction<{ global_tax?: Setting }>(
  'setting/global-tax/success'
);
export const getSettingGlobalTaxError = createAction('setting/global-tax/error');

export const getSettingDefaultPhone = createAction('setting/default-phone-sms');
export const getSettingDefaultPhoneSuccess = createAction<{ default_phone_sms?: Setting }>(
  'setting/default-phone-sms/success'
);
export const getSettingDefaultPhoneError = createAction('setting/default-phone-sms/error');

export const updateSetting = createAction<SettingAddBody>('setting/update');
export const updateSettingSuccess = createAction<Setting>('setting/update/success');
export const updateSettingError = createAction('setting/update/error');

export const setSettingUI = createAction<AppUI>('setting/set-ui');
