/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import { getLoadInInventoriesByDeliveryAPI } from '../loadIn/apis';
import { LoadIn } from '../loadIn/types';
import { createLoadOutInventoriesAPI, updateLoadOutInventoriesAPI } from '../loadOut/apis';
import { LoadOut } from '../loadOut/types';
import { deleteTruckCartItemAPI } from '../truckCart/apis';
import {
  getDetailTruckDeliveryAPI,
  sendBackToLoadInByTruckDeliveryAPI,
  updateLoadInTruckDeliveryAPI,
  updateTruckDeliveryAPI,
} from '../truckDeliveries/apis';
import { TruckDeliveryBody } from '../truckDeliveries/types';
import {
  createDeliveryLoadOutAdminSuccess,
  deleteDeliveryTruckCartAdminSuccess,
  getAdminError,
  getDeliveryLoadInAdmin,
  getDeliveryLoadInAdminSuccess,
  getTruckDeliveryAdmin,
  getTruckDeliveryAdminSuccess,
  sendBackToLoadInByTruckDeliverySuccess,
  updateDeliveryAdminSuccess,
  updateDeliveryLoadOutAdminSuccess,
} from './actions';

export function* getDeliveryLoadInAdminWorker(action: ActionSaga<{ truck_delivery_id?: string }>) {
  try {
    const { payload } = action;

    if (payload?.truck_delivery_id === undefined || !payload?.truck_delivery_id) {
      throw new Error('Truck delivery id is undefined');
    }

    if (payload) {
      const { data }: SagaReturnType<typeof getLoadInInventoriesByDeliveryAPI> = yield call(
        getLoadInInventoriesByDeliveryAPI,
        payload
      );
      yield put(getDeliveryLoadInAdminSuccess(data.data));
    }
  } catch (e) {
    console.error(e);
    yield put(getAdminError());
  }
}

export function* getTruckDeliveryAdminWorker(action: ActionSaga<{ id: string }>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof getDetailTruckDeliveryAPI> = yield call(
        getDetailTruckDeliveryAPI,
        payload
      );
      yield put(getTruckDeliveryAdminSuccess(data.data));
      yield put(getDeliveryLoadInAdmin({ truck_delivery_id: data.data.id }));
    }
  } catch (e) {
    console.error(e);
    yield put(getAdminError());
  }
}

export function* updateDeliveryLoadOutAdminWorker(action: ActionSaga<LoadOut>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof updateLoadOutInventoriesAPI> = yield call(
        updateLoadOutInventoriesAPI,
        payload
      );
      yield put(updateDeliveryLoadOutAdminSuccess(data.data));

      if (action.payload?.callback) {
        action.payload.callback();
      }

      showSuccessToast();
    }
  } catch (e) {
    console.error(e);
    showErrorToast(e?.response?.data?.message);
    yield put(getAdminError());
  }
}

export function* createDeliveryLoadOutAdminWorker(action: ActionSaga<LoadOut>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof createLoadOutInventoriesAPI> = yield call(
        createLoadOutInventoriesAPI,
        payload
      );
      yield put(createDeliveryLoadOutAdminSuccess(data.data));

      if (action.payload?.callback) {
        action.payload.callback();
      }
      showSuccessToast();
    }
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(getAdminError());
  }
}

export function* updateDeliveryAdminWorker(action: ActionSaga<TruckDeliveryBody>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof updateTruckDeliveryAPI> = yield call(
        updateTruckDeliveryAPI,
        payload
      );
      if (data?.data?.new_truck_delivery) {
        window.location.replace(
          `#/load-in-to-truck?truck_id=${data?.data?.new_truck_delivery.truck_id}`
        );
      }
      yield put(updateDeliveryAdminSuccess(data.data));
      yield put(updateDeliveryLoadOutAdminSuccess(data.data));
      showSuccessToast();
      if (data.data?.is_complete_unload === 1) {
        yield put(getTruckDeliveryAdmin({ id: data.data.id }));
      }
    }
  } catch (e) {
    console.error(e);
    showErrorToast(e?.response?.data?.message);
    yield put(getAdminError());
  }
}

export function* updateDeliveryLoadInAdminWorker(action: ActionSaga<TruckDeliveryBody>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof updateLoadInTruckDeliveryAPI> = yield call(
        updateLoadInTruckDeliveryAPI,
        payload
      );
      yield put(updateDeliveryAdminSuccess(data.data));
      yield put(updateDeliveryLoadOutAdminSuccess(data.data));
      showSuccessToast();
    }
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(getAdminError());
  }
}

export function* deleteDeliveryTruckCartAdminWorker(action: ActionSaga<LoadIn>) {
  try {
    const { payload } = action;
    if (!payload?.truck_cart?.id) {
      throw new Error('Not found truck cart inside load in');
    }
    if (payload) {
      yield call(deleteTruckCartItemAPI, { id: payload.truck_cart.id });
      yield put(deleteDeliveryTruckCartAdminSuccess(payload));
      showSuccessToast();
    }
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(getAdminError());
  }
}

export function* sendBackToLoadInByTruckDeliveryWorker(
  action: ActionSaga<Required<Pick<TruckDeliveryBody, 'id'>>>
) {
  try {
    if (!action.payload?.id) throw new Error('Payload is required');
    const { id } = action.payload;
    const { data }: SagaReturnType<typeof sendBackToLoadInByTruckDeliveryAPI> = yield call(
      sendBackToLoadInByTruckDeliveryAPI,
      { id }
    );
    yield put(sendBackToLoadInByTruckDeliverySuccess());
    window.location.replace(`#/load-in-to-truck?truck_id=${data.data.truck_id}`);
  } catch (e) {
    showErrorToast();
    yield put(getAdminError());
    console.error(e);
  }
}
