/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { CustomerReturnItemReducer } from './types';
import {
  createCustomerReturnItem,
  createCustomerReturnItemSuccess,
  deleteCustomerReturnItem,
  deleteCustomerReturnItemSuccess,
  updateCustomerReturnItem,
  updateCustomerReturnItemSuccess,
  customerReturnItemError,
} from './actions';

const initialState: CustomerReturnItemReducer = {};

const customerReturnItemReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(customerReturnItemError, (state) => {
      state.ui = {};
    })
    .addMatcher(
      isAnyOf(updateCustomerReturnItem, createCustomerReturnItem, deleteCustomerReturnItem),
      (state) => {
        state.ui = { ...state.ui, loadingBtn: true };
      }
    )
    .addMatcher(
      isAnyOf(
        updateCustomerReturnItemSuccess,
        createCustomerReturnItemSuccess,
        deleteCustomerReturnItemSuccess
      ),
      (state) => {
        state.ui = { ...state.ui, loadingBtn: false, key: (state.ui?.key ?? 0) + 1 };
      }
    )
);

export default customerReturnItemReducer;
