/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import {
  Invoice,
  InvoiceAddBody,
  InvoiceDeleteBody,
  InvoiceItemPayload,
  InvoiceListParams,
  InvoicePrintSystem,
  InvoiceUpdateBody,
} from './types';

const rootAPI = 'invoices';

export const getInvoicePersonalListAPI = (payload?: InvoiceListParams) => {
  return axiosQuery.get<ResponseStructure<Invoice[]>>(rootAPI + '/by-user', {
    params: { ...payload?.pagination, ...payload?.myFilterable },
  });
};

export const getInvoiceManagerListAPI = (payload?: InvoiceListParams) => {
  return axiosQuery.get<ResponseStructure<Invoice[]>>(rootAPI, {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getInvoiceNeedPaymentAPI = (payload?: InvoiceListParams) => {
  return axiosQuery.get<ResponseStructure<Invoice[]>>(rootAPI + '/need-payment', {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getInvoiceTruckDeliveryListAPI = (payload?: InvoiceListParams) => {
  if (!payload?.truckDeliveryId) {
    throw new Error('truck delivery id is required');
  }
  return axiosQuery.get<ResponseStructure<Invoice[]>>(
    rootAPI + '/truck-delivery/' + payload.truckDeliveryId,
    {
      params: { ...payload?.pagination, ...payload?.filterable },
    }
  );
};

export const getInvoiceItemAPI = (payload?: InvoiceItemPayload) => {
  if (payload?.isPublic) {
    return axiosQuery.get<ResponseStructure<Invoice>>(`${rootAPI}/${payload?.id}/public`);
  }
  return axiosQuery.get<ResponseStructure<Invoice>>(`${rootAPI}/${payload?.id}`);
};

export const reportMyTruckInvoiceAPI = () => {
  return axiosQuery.get<ResponseStructure<Invoice[]>>(rootAPI + '/report');
};

export const addInvoiceItemAPI = (payload?: InvoiceAddBody) => {
  return axiosQuery.post<ResponseStructure<Invoice>>(rootAPI, {
    ...payload,
    discount: payload?.discount || undefined,
  });
};

export const updateInvoiceItemAPI = (payload?: InvoiceUpdateBody, files?: FormData) => {
  delete payload?.invoice_items;
  delete payload?.customer;
  // const formData = new FormData();
  // if (payload?.files) {
  //   for (let i in payload.files) {
  //     formData.append(, payload.files[i]);
  //   }
  // }

  return axiosQuery.post<ResponseStructure<Invoice>>(
    `${rootAPI}/${payload?.id}`,
    { _method: 'PATCH', ...payload, files },
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
};

export const deleteInvoiceItemAPI = (payload?: InvoiceDeleteBody) => {
  return axiosQuery.delete<ResponseStructure<Invoice>>(`${rootAPI}/${payload?.id}`);
};

export const cancelledInvoiceItemAPI = (payload: InvoiceDeleteBody) => {
  return axiosQuery.put<ResponseStructure<Invoice>>(`${rootAPI}/${payload?.id}/cancelled`, payload);
};

export const printSystemInvoiceAPI = (payload: InvoicePrintSystem) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  return axiosQuery.post<ResponseStructure<Invoice>>(
    `${rootAPI}/${payload.id}/print-system`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
};
