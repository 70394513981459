/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  ADD_MULTIPLE_TRUCK_ITEM,
  ADD_TRUCK_ITEM,
  DELETE_TRUCK_ITEM,
  GET_TRUCK_ITEM,
  GET_TRUCK_LIST,
  SET_FILTER_TRUCK_LIST,
  UPDATE_TRUCK_ITEM,
} from './actions';
import {
  addMultipleTruckItemWorker,
  addTruckItemWorker,
  deleteTruckItemWorker,
  getTruckItemWorker,
  getTruckListWorker,
  setFilterTruckListWorker,
  updateTruckItemWorker,
} from './workers';

export function* truckSaga() {
  yield takeLatest(GET_TRUCK_LIST, getTruckListWorker);
  yield takeLatest(GET_TRUCK_ITEM, getTruckItemWorker);
  yield takeLatest(SET_FILTER_TRUCK_LIST, setFilterTruckListWorker);
  yield takeLatest(ADD_TRUCK_ITEM, addTruckItemWorker);
  yield takeLatest(ADD_MULTIPLE_TRUCK_ITEM, addMultipleTruckItemWorker);
  yield takeLatest(UPDATE_TRUCK_ITEM, updateTruckItemWorker);
  yield takeLatest(DELETE_TRUCK_ITEM, deleteTruckItemWorker);
}
