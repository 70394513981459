import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import OverlayLoading from 'src/components/common/loading/OverlayLoading';
import { InvoicePayment } from 'src/features/invoicePayment/types';
import { convertToCurrency, showDateTime, sumByField } from 'src/helpers/common.helpers';
import { PAYMENT_TYPE_ENUM } from '../constants/enum.constants';

type PaymentPrintProps = {
  invoice_payments?: InvoicePayment[];
  loading?: boolean;
  invoice_created_by?: string;
};

const PaymentTablePrint: React.FC<PaymentPrintProps> = ({
  invoice_payments,
  loading,
  invoice_created_by,
}) => {
  return (
    <OverlayLoading loading={loading}>
      <CTable className="table-print" bordered responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell className="text-center" scope="col" style={{ width: '10%' }}>
              #
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" style={{ width: '30%' }}>
              Cash Amount
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" style={{ width: '20%' }}>
              Type
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" style={{ width: '20%' }}>
              Date
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" style={{ width: '20%' }}>
              By
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {invoice_payments?.map((item, index) => (
            <CTableRow key={item.id}>
              <CTableHeaderCell className="text-center" scope="row">
                {index + 1}
              </CTableHeaderCell>
              <CTableDataCell className="text-right">
                <span className="two-line">{convertToCurrency(item?.cash_amount)}</span>
              </CTableDataCell>
              <CTableDataCell>{PAYMENT_TYPE_ENUM[item?.type]}</CTableDataCell>
              <CTableDataCell>{showDateTime(item.created_at, 'MM/DD/YY HH:mm')}</CTableDataCell>
              <CTableDataCell>{item?.user?.name || invoice_created_by}</CTableDataCell>
            </CTableRow>
          ))}
          <CTableRow>
            <CTableHeaderCell className="text-center" scope="row">
              Total
            </CTableHeaderCell>
            <CTableDataCell className="text-right">
              <span className="two-line">
                {convertToCurrency(sumByField(invoice_payments ?? [], 'cash_amount'))}
              </span>
            </CTableDataCell>
            <CTableDataCell></CTableDataCell>
            <CTableDataCell></CTableDataCell>
            <CTableDataCell></CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
    </OverlayLoading>
  );
};

export default React.memo(PaymentTablePrint);
