import { Invoice } from 'src/features/invoice/types';
import { TruckDelivery } from 'src/features/truckDeliveries/types';
import { User } from 'src/features/user/types';

export const getPaymentStatusInvoice = (invoice: Invoice): string => {
  const { total, total_paid, customer_return_total } = invoice || {};
  const hasAmount = Boolean(+(total_paid ?? 0) > 0);
  const hasCredit = Boolean(+(customer_return_total ?? 0) > 0);
  const isRemainingPositive = Boolean(+(total_paid ?? 0) - +(total ?? 0) >= 0);

  if (invoice.is_cancelled) {
    return 'Cancelled';
  }

  if ((hasAmount || hasCredit) && isRemainingPositive) {
    return 'Success';
  }

  if ((hasAmount || hasCredit) && !isRemainingPositive) {
    return 'Not Finished';
  }

  return 'Pending';
};

export const getInvoiceStatus = (invoice: Invoice): string => {
  const { is_complete, is_cancelled } = invoice;

  return is_cancelled ? 'Cancelled' : is_complete === 1 ? 'Completed' : 'Pending';
};

export const checkCanPaymentInvoice = (invoice: Invoice, currentUser: User): boolean => {
  const { total, total_paid, is_cancelled } = invoice || {};

  if (is_cancelled) {
    return false;
  }

  return currentUser?.id?.toString() === invoice?.user_id?.toString() &&
    total &&
    +(total_paid ?? 0) < +total
    ? true
    : false;
};
