/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer } from '@reduxjs/toolkit';
import {
  createTruckDelivery,
  createTruckDeliveryAndAddInventory,
  createTruckDeliveryError,
  createTruckDeliverySuccess,
  deleteTruckDelivery,
  deleteTruckDeliveryError,
  deleteTruckDeliverySuccess,
  getCurrentTruckDelivery,
  getCurrentTruckDeliveryError,
  getCurrentTruckDeliverySuccess,
  updateTruckDelivery,
  updateTruckDeliveryError,
  updateTruckDeliverySuccess,
} from './actions';
import { TruckDeliveryReducer } from './types';

const INITIAL_STATE = {};

const truckDeliveryReducer = createReducer<TruckDeliveryReducer>(INITIAL_STATE, (builder) => {
  builder.addCase(getCurrentTruckDelivery, (state) => {
    state.ui = { ...state?.ui, loading: true };
  });
  builder.addCase(getCurrentTruckDeliverySuccess, (state, { payload }) => {
    state.ui = { ...state?.ui, loading: false };
    state.currentTruckDelivery = { ...payload };
  });
  builder.addCase(getCurrentTruckDeliveryError, (state) => {
    state.ui = { ...state?.ui, loading: false };
  });
  builder.addCase(createTruckDelivery, (state) => {
    state.ui = { ...state?.ui, loadingBtn: true };
  });
  builder.addCase(createTruckDeliveryAndAddInventory, (state) => {
    state.ui = { ...state?.ui, loadingBtn: true };
  });
  builder.addCase(createTruckDeliverySuccess, (state, { payload }) => {
    state.ui = { ...state?.ui, loadingBtn: false, key: (state?.ui?.key || 0) + 1 };
    state.currentTruckDelivery = { ...payload };
  });
  builder.addCase(createTruckDeliveryError, (state) => {
    state.ui = { ...state?.ui, loadingBtn: false };
  });
  builder.addCase(updateTruckDelivery, (state) => {
    state.ui = { ...state?.ui, loadingBtn: true };
  });
  builder.addCase(updateTruckDeliverySuccess, (state, { payload }) => {
    state.ui = { ...state?.ui, loadingBtn: false, key: (state?.ui?.key || 0) + 1 };
    state.currentTruckDelivery = payload?.is_complete_unload === 1 ? undefined : { ...payload };
  });
  builder.addCase(updateTruckDeliveryError, (state) => {
    state.ui = { ...state?.ui, loadingBtn: false };
  });
  builder.addCase(deleteTruckDelivery, (state) => {
    state.ui = { ...state?.ui, loadingBtn: true };
  });
  builder.addCase(deleteTruckDeliverySuccess, (state) => {
    state.ui = { ...state?.ui, loadingBtn: false, key: (state?.ui?.key || 0) + 1 };
    state.currentTruckDelivery = undefined;
  });

  builder.addCase(deleteTruckDeliveryError, (state) => {
    state.ui = { ...state?.ui, loadingBtn: false };
  });
});

export default truckDeliveryReducer;
