/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { CustomerReturnReducer } from './types';
import {
  createCustomerReturn,
  createCustomerReturnSuccess,
  deleteCustomerReturn,
  deleteCustomerReturnSuccess,
  getListCustomerReturn,
  getListCustomerReturnSuccess,
  getCustomerReturn,
  customerReturnError,
  getCustomerReturnSuccess,
  updateCustomerReturn,
  updateCustomerReturnSuccess,
} from './actions';

const initialState: CustomerReturnReducer = {};

const customerReturnReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getListCustomerReturnSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
      state.customerReturns = payload;
    })
    .addCase(getCustomerReturnSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
      state.customerReturn = payload;
    })
    .addCase(customerReturnError, (state) => {
      state.ui = {};
    })
    .addMatcher(isAnyOf(getCustomerReturn, getListCustomerReturn), (state) => {
      state.ui = { ...state.ui, loading: true };
    })
    .addMatcher(
      isAnyOf(updateCustomerReturn, createCustomerReturn, deleteCustomerReturn),
      (state) => {
        state.ui = { ...state.ui, loadingBtn: true };
      }
    )
    .addMatcher(
      isAnyOf(
        updateCustomerReturnSuccess,
        createCustomerReturnSuccess,
        deleteCustomerReturnSuccess
      ),
      (state) => {
        state.ui = { ...state.ui, loadingBtn: false };
      }
    )
);

export default customerReturnReducer;
