/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import { updateLoadOutExpand } from '../loadIn/actions';
import {
  createLoadOutInventoriesError,
  createLoadOutInventoriesSuccess,
  getLoadOutInventoriesError,
  getLoadOutInventoriesSuccess,
  updateLoadOutInventoriesError,
  updateLoadOutInventoriesSuccess,
} from './actions';
import {
  createLoadOutInventoriesAPI,
  getLoadOutInventoriesAPI,
  updateLoadOutInventoriesAPI,
} from './apis';
import { LoadOutBody, LoadOutInventoriesParams } from './types';

export function* getLoadOutInventoriesWorker(action: ActionSaga<LoadOutInventoriesParams>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof getLoadOutInventoriesAPI> = yield call(
        getLoadOutInventoriesAPI,
        payload
      );
      yield put(getLoadOutInventoriesSuccess(data.data));
    }
  } catch (e) {
    showErrorToast();
    console.error(e);
    yield put(getLoadOutInventoriesError);
  }
}

export function* createLoadOutInventoriesWorker(action: ActionSaga<LoadOutBody>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof createLoadOutInventoriesAPI> = yield call(
        createLoadOutInventoriesAPI,
        payload
      );
      document.querySelector<HTMLElement>('.rbt-close')?.click();
      yield put(createLoadOutInventoriesSuccess(data.data));
      yield put(updateLoadOutExpand(data.data));
      showSuccessToast();
    }
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(createLoadOutInventoriesError);
  }
}

export function* updateLoadOutInventoriesWorker(action: ActionSaga<LoadOutBody>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof updateLoadOutInventoriesAPI> = yield call(
        updateLoadOutInventoriesAPI,
        payload
      );
      yield put(updateLoadOutInventoriesSuccess(data.data));
      yield put(updateLoadOutExpand(data.data));
      showSuccessToast();
    }
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(updateLoadOutInventoriesError);
  }
}
