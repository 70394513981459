/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import {
  InvoiceDraft,
  InvoiceDraftAddBody,
  InvoiceDraftDeleteBody,
  InvoiceDraftItemPayload,
  InvoiceDraftListParams,
  InvoiceDraftPrintSystem,
  InvoiceDraftUpdateBody,
} from './types';

const rootAPI = 'invoice-drafts';

export const getInvoiceDraftPersonalListAPI = (payload?: InvoiceDraftListParams) => {
  return axiosQuery.get<ResponseStructure<InvoiceDraft[]>>(rootAPI + '/by-user', {
    params: { ...payload?.pagination, ...payload?.myFilterable },
  });
};

export const getInvoiceDraftManagerListAPI = (payload?: InvoiceDraftListParams) => {
  return axiosQuery.get<ResponseStructure<InvoiceDraft[]>>(rootAPI, {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getInvoiceDraftTruckDeliveryListAPI = (payload?: InvoiceDraftListParams) => {
  if (!payload?.truckDeliveryId) {
    throw new Error('truck delivery id is required');
  }
  return axiosQuery.get<ResponseStructure<InvoiceDraft[]>>(
    rootAPI + '/truck-delivery/' + payload.truckDeliveryId,
    {
      params: { ...payload?.pagination, ...payload?.filterable },
    }
  );
};

export const getInvoiceDraftItemAPI = (payload?: InvoiceDraftItemPayload) => {
  if (payload?.isPublic) {
    return axiosQuery.get<ResponseStructure<InvoiceDraft>>(`${rootAPI}/${payload?.id}/public`);
  }
  return axiosQuery.get<ResponseStructure<InvoiceDraft>>(`${rootAPI}/${payload?.id}`);
};

export const reportMyTruckInvoiceDraftAPI = () => {
  return axiosQuery.get<ResponseStructure<InvoiceDraft[]>>(rootAPI + '/report');
};

export const addInvoiceDraftItemAPI = (payload?: InvoiceDraftAddBody) => {
  return axiosQuery.post<ResponseStructure<InvoiceDraft>>(rootAPI, {
    ...payload,
    discount: payload?.discount || undefined,
  });
};

export const updateInvoiceDraftItemAPI = (payload?: InvoiceDraftUpdateBody, files?: FormData) => {
  delete payload?.invoice_draft_items;
  delete payload?.customer;
  // const formData = new FormData();
  // if (payload?.files) {
  //   for (let i in payload.files) {
  //     formData.append(, payload.files[i]);
  //   }
  // }

  return axiosQuery.post<ResponseStructure<InvoiceDraft>>(
    `${rootAPI}/${payload?.id}`,
    { _method: 'PATCH', ...payload, files },
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
};

export const deleteInvoiceDraftItemAPI = (payload?: InvoiceDraftDeleteBody) => {
  return axiosQuery.delete<ResponseStructure<InvoiceDraft>>(`${rootAPI}/${payload?.id}`);
};

export const cancelledInvoiceDraftItemAPI = (payload: InvoiceDraftDeleteBody) => {
  return axiosQuery.delete<ResponseStructure<InvoiceDraft>>(`${rootAPI}/${payload?.id}`);
};

export const printSystemInvoiceDraftAPI = (payload: InvoiceDraftPrintSystem) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  return axiosQuery.post<ResponseStructure<InvoiceDraft>>(
    `${rootAPI}/${payload.id}/print-system`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
};

export const getByTruckAPI = (payload?: InvoiceDraftAddBody) => {
  return axiosQuery.post<ResponseStructure<InvoiceDraft[]>>(rootAPI + '/get-by-truck', {
    ...payload,
  });
};
