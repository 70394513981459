/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import { getDashboard } from './actions';
import { getDashboardWorker } from './workers';

export function* dashboardSaga() {
  yield takeLatest(getDashboard, getDashboardWorker);
}
