/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { Pagination } from 'src/types/axios.types';
import { AppUI } from 'src/types/common.types';
import {
  Customer,
  CustomerAddBody,
  CustomerDeleteBody,
  CustomerFilterable,
  CustomerListParams,
  CustomerPasswordBody,
  CustomerUpdateBody,
} from './types';

// USING REDUX TOOLKIT

// GET LIST
export const getCustomerList = createAction<CustomerListParams>('customer/get-list');
export const getCreditLimitList = createAction<CustomerListParams>('creditLimit/get-list');
export const getCustomerListSuccess = createAction<{
  customers: Customer[];
  pagination?: Pagination;
}>('customer/get-list/success');
export const getCreditLimitListSuccess = createAction<{
  customers: Customer[];
  pagination?: Pagination;
}>('creditLimit/get-list/success');
export const getCustomerListError = createAction('customer/get-list/error');

// CREATE ITEM
export const addCustomerItem = createAction<
  CustomerUpdateBody & { callback?: (customer?: Customer) => void }
>('customer/add');
export const addCustomerItemSuccess = createAction<Customer>('customer/add/success');
export const addCustomerItemError = createAction('customer/add/error');

// UPDATE ITEM
export const updateCustomerItem = createAction<CustomerUpdateBody>('customer/update');
export const updateCreditLimitItem = createAction<CustomerUpdateBody>('creditLimit/update');
export const updateCustomerItemSuccess = createAction<Customer>('customer/update/success');
export const updateCreditLimitItemSuccess = createAction<Customer>('creditLimit/update/success');
export const updateCustomerItemError = createAction('customer/update/error');

// DELETE ITEM
export const deleteCustomerItem = createAction<CustomerDeleteBody>('customer/delete-item');
export const deleteCustomerItemSuccess = createAction<Customer>('customer/delete-item/success');
export const deleteCustomerItemError = createAction('customer/delete-item/error');

// UPDATE PASSWORD
export const updateCustomerPassword = createAction<CustomerPasswordBody>(
  'customer/update-password'
);
export const updateCustomerPasswordSuccess = createAction<Customer>(
  'customer/update-password/success'
);
export const updateCustomerPasswordError = createAction('customer/update-password/error');

// CLEAR LIST
export const clearCustomerList = createAction('customer/clear-list');

// OTHER ACTIONS
export const setFilterCustomerList = createAction<{ filterable?: CustomerFilterable }>(
  'customer/set-filter'
);
export const setCustomerUI = createAction<AppUI>('customer/set-ui');

// IMPORT
export const customerImport = createAction<CustomerAddBody>('customer/import');
export const customerImportSuccess = createAction('customer/import/success');
export const customerError = createAction('customer/error');
