/**
 * The event send to the store
 */

import { ActionSaga } from 'src/types/saga.types';
import {
  InvoiceItemAddBody,
  InvoiceItemDeleteBody,
  InvoiceItemFilterable,
  InvoiceSubItemPayload,
  InvoiceSubItemSuccessPayload,
  InvoiceItemListParams,
  InvoiceItemListSuccessPayload,
  InvoiceItemUI,
  InvoiceItemUpdateBody,
  InvoiceItem,
} from './types';

export const GET_INVOICE_ITEM_LIST = 'GET_INVOICE_ITEM_LIST';
export const GET_INVOICE_ITEM_LIST_SUCCESS = 'GET_INVOICE_ITEM_LIST_SUCCESS';
export const GET_INVOICE_ITEM_LIST_ERROR = 'GET_INVOICE_ITEM_LIST_ERROR';
export const SET_FILTER_INVOICE_ITEM_LIST = 'SET_FILTER_INVOICE_ITEM_LIST';
export const CLEAR_INVOICE_ITEM_LIST = 'CLEAR_INVOICE_ITEM_LIST';

export const GET_INVOICE_ITEM_ITEM = 'GET_INVOICE_ITEM_ITEM';
export const GET_INVOICE_ITEM_ITEM_SUCCESS = 'GET_INVOICE_ITEM_ITEM_SUCCESS';
export const GET_INVOICE_ITEM_ITEM_ERROR = 'GET_INVOICE_ITEM_ITEM_ERROR';

export const ADD_INVOICE_ITEM_ITEM = 'ADD_INVOICE_ITEM_ITEM';
export const ADD_MULTIPLE_INVOICE_ITEM_ITEM = 'ADD_MULTIPLE_INVOICE_ITEM_ITEM';
export const ADD_INVOICE_ITEM_ITEM_SUCCESS = 'ADD_INVOICE_ITEM_ITEM_SUCCESS';
export const ADD_INVOICE_ITEM_ITEM_ERROR = 'ADD_INVOICE_ITEM_ITEM_ERROR';

export const UPDATE_INVOICE_ITEM_ITEM = 'UPDATE_INVOICE_ITEM_ITEM';
export const UPDATE_INVOICE_ITEM_ITEM_SUCCESS = 'UPDATE_INVOICE_ITEM_ITEM_SUCCESS';
export const UPDATE_INVOICE_ITEM_ITEM_ERROR = 'UPDATE_INVOICE_ITEM_ITEM_ERROR';

export const DELETE_INVOICE_ITEM_ITEM = 'DELETE_INVOICE_ITEM_ITEM';
export const DELETE_INVOICE_ITEM_ITEM_SUCCESS = 'DELETE_INVOICE_ITEM_ITEM_SUCCESS';
export const DELETE_INVOICE_ITEM_ITEM_ERROR = 'DELETE_INVOICE_ITEM_ITEM_ERROR';

export const SET_INVOICE_ITEM_VISIBLE = 'SET_INVOICE_ITEM_VISIBLE';
export const PUSH_INVOICE_ITEM_TO_NEW = 'PUSH_INVOICE_ITEM_TO_NEW';

export const getInvoiceItemList = (
  payload?: InvoiceItemListParams
): ActionSaga<InvoiceItemListParams> => ({
  type: GET_INVOICE_ITEM_LIST,
  payload: payload ? { ...payload } : null,
});

export const getInvoiceItemListSuccess = (
  payload: InvoiceItemListSuccessPayload
): ActionSaga<InvoiceItemListSuccessPayload> => ({
  type: GET_INVOICE_ITEM_LIST_SUCCESS,
  payload: { ...payload },
});

export const getInvoiceItemListError = (): ActionSaga => ({
  type: GET_INVOICE_ITEM_LIST_ERROR,
});

export const setFilterInvoiceItemList = (
  payload?: InvoiceItemFilterable
): ActionSaga<InvoiceItemListParams> => ({
  type: SET_FILTER_INVOICE_ITEM_LIST,
  payload: { filterable: { ...payload } },
});

export const clearInvoiceItemList = (): ActionSaga<InvoiceItemListSuccessPayload> => ({
  type: CLEAR_INVOICE_ITEM_LIST,
  payload: { invoiceItems: [] },
});

export const getInvoiceSubItem = (
  payload: InvoiceSubItemPayload
): ActionSaga<InvoiceSubItemPayload> => ({
  type: GET_INVOICE_ITEM_ITEM,
  payload: { ...payload },
});

export const getInvoiceSubItemSuccess = (
  payload: InvoiceSubItemSuccessPayload
): ActionSaga<InvoiceSubItemSuccessPayload> => ({
  type: GET_INVOICE_ITEM_ITEM_SUCCESS,
  payload: { ...payload },
});

export const getInvoiceSubItemError = (): ActionSaga => ({
  type: GET_INVOICE_ITEM_ITEM_ERROR,
});

export const addInvoiceSubItem = (payload: InvoiceItemAddBody): ActionSaga<InvoiceItemAddBody> => ({
  type: ADD_INVOICE_ITEM_ITEM,
  payload: { ...payload },
});

export const addMultipleInvoiceSubItem = (
  payload: InvoiceItemAddBody
): ActionSaga<InvoiceItemAddBody> => ({
  type: ADD_MULTIPLE_INVOICE_ITEM_ITEM,
  payload: { ...payload },
});

export const addInvoiceSubItemSuccess = (
  payload: InvoiceSubItemSuccessPayload
): ActionSaga<InvoiceSubItemSuccessPayload> => ({
  type: ADD_INVOICE_ITEM_ITEM_SUCCESS,
  payload: { ...payload },
});

export const addInvoiceSubItemError = (): ActionSaga => ({
  type: ADD_INVOICE_ITEM_ITEM_ERROR,
});

export const updateInvoiceSubItem = (
  payload: InvoiceItemUpdateBody
): ActionSaga<InvoiceItemUpdateBody> => ({
  type: UPDATE_INVOICE_ITEM_ITEM,
  payload: { ...payload },
});

export const updateInvoiceSubItemSuccess = (
  payload: InvoiceSubItemSuccessPayload
): ActionSaga<InvoiceSubItemSuccessPayload> => ({
  type: UPDATE_INVOICE_ITEM_ITEM_SUCCESS,
  payload: { ...payload },
});

export const updateInvoiceSubItemError = (): ActionSaga => ({
  type: UPDATE_INVOICE_ITEM_ITEM_ERROR,
});

export const deleteInvoiceSubItem = (
  payload: InvoiceItemDeleteBody
): ActionSaga<InvoiceItemDeleteBody> => ({
  type: DELETE_INVOICE_ITEM_ITEM,
  payload: { ...payload },
});

export const deleteInvoiceSubItemSuccess = (
  payload: InvoiceSubItemSuccessPayload
): ActionSaga<InvoiceSubItemSuccessPayload> => ({
  type: DELETE_INVOICE_ITEM_ITEM_SUCCESS,
  payload: { ...payload },
});

export const deleteInvoiceSubItemError = (): ActionSaga => ({
  type: DELETE_INVOICE_ITEM_ITEM_ERROR,
});

export const setInvoiceItemVisible = (
  payload: InvoiceItemUI<{ visible: boolean }>
): ActionSaga<InvoiceItemUI<{ visible: boolean }>> => ({
  type: SET_INVOICE_ITEM_VISIBLE,
  payload: { ...payload },
});

export const pushInvoiceItemToNew = (payload: {
  mutable: InvoiceItem;
}): ActionSaga<{ mutable: InvoiceItem }> => ({
  type: PUSH_INVOICE_ITEM_TO_NEW,
  payload: { ...payload },
});
