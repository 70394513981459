import { ResponseStructure } from 'src/types/axios.types';
import { InvoicePayment, InvoicePaymentBody, InvoicePaymentMultipleBody } from './types';
import { axiosQuery } from 'src/services/axiosQuery';

const rootAPI = 'invoice-payments';

export const createInvoicePaymentAPI = (payload?: InvoicePaymentBody) => {
  return axiosQuery.post<ResponseStructure<InvoicePayment>>(rootAPI, payload);
};

export const createMultipleInvoicePaymentAPI = (payload?: InvoicePaymentMultipleBody) => {
  return axiosQuery.post<ResponseStructure<InvoicePayment>>(rootAPI + '/multiple-store', payload);
};

export const deleteInvoicePaymentAPI = (payload?: InvoicePaymentBody) => {
  if (!payload?.id) throw new Error('id is required');
  return axiosQuery.delete<ResponseStructure<InvoicePayment>>(rootAPI + '/' + payload.id);
};
