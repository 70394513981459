import { createReducer } from '@reduxjs/toolkit';
import { PermissionReducer } from './types';
import {
  getListPermission,
  getListPermissionSuccess,
  getListRole,
  getListRoleSuccess,
  permissionError,
  setPermission,
  setPermissionSuccess,
} from './actions';

const initialState: PermissionReducer = {};

const permissionReducer = createReducer(initialState, (builder) => {
  builder.addCase(getListPermission, (state, { payload }) => {
    state.ui = { ...state.ui, loading: true };
  });
  builder.addCase(getListPermissionSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.permissions = payload;
  });
  builder.addCase(setPermissionSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    if (payload?.role_changed?.id) {
      state.roles = state.roles?.map((e) =>
        e.id === payload.role_changed?.id ? { ...e, ...payload.role_changed } : e
      );
    }
  });
  builder.addCase(getListRole, (state, { payload }) => {
    state.ui = { ...state.ui, loading: true };
  });
  builder.addCase(getListRoleSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.roles = payload;
  });
  builder.addCase(permissionError, (state) => {
    state.ui = {};
  });
});

export default permissionReducer;
