/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer } from '@reduxjs/toolkit';
import {
  getLoadInInventories,
  getLoadInInventoriesError,
  getLoadInInventoriesSuccess,
  updateLoadOutExpand,
} from './actions';
import { LoadInReducer } from './types';

const initialState = {};

export const loadInReducer = createReducer<LoadInReducer>(initialState, (builder) =>
  builder
    .addCase(getLoadInInventories, (state, { payload }) => {
      state.ui = { ...state.ui, loading: true };
    })
    .addCase(getLoadInInventoriesSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
      state.truckCarts = [...payload];
    })
    .addCase(getLoadInInventoriesError, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
    })
    .addCase(updateLoadOutExpand, (state, { payload }) => {
      state.truckCarts = state.truckCarts?.map((e) =>
        e.id === payload?.load_in_inventory_id
          ? { ...e, load_out_inventory: { ...payload } }
          : { ...e }
      );
    })

    .addDefaultCase((state, action) => {})
);
