import { CCol, CRow } from '@coreui/react';
import dayjs from 'dayjs';
import React from 'react';
import TemplatePrint from 'src/components/app/print/TemplatePrint';
import { Invoice } from 'src/features/invoice/types';
import TablePrint2 from './components/TablePrint2';

type InvoicePrint2Props = {
  invoice?: Invoice;
  loading?: boolean;
};

const FONT_SIZE = 22;

const InvoicePrint2: React.FC<InvoicePrint2Props> = ({ invoice, loading }) => {
  const invoiceNo = +(invoice?.id ?? 0) + 5000;
  return (
    <div className="for-print mt-5">
      <TemplatePrint isHiddenInfo>
        <CRow className="mt-2" xs={{ gutter: 3 }}>
          <CCol md={6} className="col-print-6">
            <table className="w-100">
              <tbody>
                <tr style={{ lineHeight: 1.5, fontSize: FONT_SIZE, fontWeight: 600 }}>
                  <td>Invoice# {invoiceNo}</td>
                </tr>
                <tr style={{ lineHeight: 1.5, fontSize: FONT_SIZE, fontWeight: 600 }}>
                  <td>{invoice?.date_time ? dayjs(invoice.date_time).format('MM/DD/YYYY') : ''}</td>
                </tr>
              </tbody>
            </table>
          </CCol>
          <CCol md={6} className="col-print-6 text-right">
            <table className="w-100">
              <tbody>
                <tr style={{ lineHeight: 1.5, fontSize: FONT_SIZE, fontWeight: 600 }}>
                  <td>{invoice?.customer?.name}</td>
                </tr>
                <tr style={{ lineHeight: 1.5, fontSize: FONT_SIZE, fontWeight: 600 }}>
                  <td>
                    {invoice?.customer?.address}
                    {invoice?.customer?.city ? `, ${invoice.customer.city}` : ''}
                    {invoice?.customer?.state ? `, ${invoice.customer.state}` : ''}
                    {invoice?.customer?.zipcode ? `, ${invoice.customer.zipcode}` : ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </CCol>

          <CCol md={12}>
            <TablePrint2 invoice={invoice} loading={loading} />
          </CCol>
          {!!invoice?.note && (
            <CCol style={{ fontSize: '1rem' }} md={12}>
              {invoice?.note}
            </CCol>
          )}
        </CRow>
      </TemplatePrint>
    </div>
  );
};

export default React.memo(InvoicePrint2);
