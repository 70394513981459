import { ActionSaga } from 'src/types/saga.types';
import { RequestOrderItemBody } from './types';
import { SagaReturnType, call, put } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import {
  createRequestOrderItemSuccess,
  deleteRequestOrderItemSuccess,
  requestOrderItemError,
  updateRequestOrderItemSuccess,
} from './actions';
import {
  createOrUpdateRequestOrderItemAPI,
  createRequestOrderItemAPI,
  deleteRequestOrderItemAPI,
  updateRequestOrderItemAPI,
} from './apis';
import { getRequestOrderItem, updateRequestOrderItemInRequestOrder } from '../requestOrder/actions';
import { increaseKeyCurrentUser } from '../currentUser/actions';

export function* createRequestOrderItemWorker(action: ActionSaga<RequestOrderItemBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof createRequestOrderItemAPI> = yield call(
        createRequestOrderItemAPI,
        action.payload
      );
      yield put(createRequestOrderItemSuccess(data.data));
      // yield put(updateRequestOrderItemInRequestOrder(data.data));
      yield put(getRequestOrderItem({ id: action.payload.request_order_id }));
      document.querySelector<HTMLElement>('.rbt-close')?.click();
      showSuccessToast();
    }
  } catch {
    yield put(requestOrderItemError());
    showErrorToast();
  }
}

export function* createOrUpdateRequestOrderItemWorker(action: ActionSaga<RequestOrderItemBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof createOrUpdateRequestOrderItemAPI> = yield call(
        createOrUpdateRequestOrderItemAPI,
        action.payload
      );
      document.querySelector<HTMLElement>('.rbt-close')?.click();
      yield put(increaseKeyCurrentUser());
      yield put(createRequestOrderItemSuccess(data.data));
      yield put(getRequestOrderItem({ id: action.payload.request_order_id }));
      if (action.payload.callback) {
        action.payload.callback();
      }
      showSuccessToast();
    }
  } catch {
    yield put(requestOrderItemError());
    showErrorToast();
  }
}

export function* updateRequestOrderItemWorker(
  action: ActionSaga<RequestOrderItemBody & { callback?: (item?: RequestOrderItemBody) => void }>
) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateRequestOrderItemAPI> = yield call(
        updateRequestOrderItemAPI,
        action.payload
      );
      yield put(updateRequestOrderItemSuccess(data.data));
      yield put(getRequestOrderItem({ id: action.payload.request_order_id }));
      if (action.payload.callback) {
        action.payload.callback();
      }
      showSuccessToast();
    }
  } catch {
    yield put(requestOrderItemError());
    showErrorToast();
  }
}

export function* deleteRequestOrderItemWorker(action: ActionSaga<RequestOrderItemBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof deleteRequestOrderItemAPI> = yield call(
        deleteRequestOrderItemAPI,
        action.payload
      );
      yield put(deleteRequestOrderItemSuccess(data.data));
      yield put(getRequestOrderItem({ id: action.payload.request_order_id }));
      showSuccessToast();
    }
  } catch {
    yield put(requestOrderItemError());
    showErrorToast();
  }
}
