/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import {
  InvoiceItem,
  InvoiceItemAddBody,
  InvoiceItemDeleteBody,
  InvoiceItemImportBody,
  InvoiceSubItemPayload,
  InvoiceItemListParams,
  InvoiceItemUpdateBody,
} from './types';

const rootAPI = 'invoice-items';

export const getInvoiceItemListAPI = (payload?: InvoiceItemListParams) => {
  return axiosQuery.get<ResponseStructure<InvoiceItem[]>>(rootAPI, {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getInvoiceSubItemAPI = (payload?: InvoiceSubItemPayload) => {
  return axiosQuery.get<ResponseStructure<InvoiceItem>>(`${rootAPI}/${payload?.id}`);
};

export const addInvoiceSubItemAPI = (payload?: InvoiceItemAddBody) => {
  return axiosQuery.post<ResponseStructure<InvoiceItem>>(rootAPI, payload, {});
};

export const updateInvoiceSubItemAPI = (payload?: InvoiceItemUpdateBody) => {
  if (payload?.request_order_item_id) {
    return axiosQuery.post<ResponseStructure<InvoiceItem>>(
      `${rootAPI}/${payload?.id}/update-from-request-order`,
      {
        ...payload,
      }
    );
  }
  return axiosQuery.post<ResponseStructure<InvoiceItem>>(
    `${rootAPI}/${payload?.id}`,
    {
      _method: 'PUT',
      ...payload,
    },
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
};

export const deleteInvoiceSubItemAPI = (payload?: InvoiceItemDeleteBody) => {
  if (payload?.request_order_item_id) {
    return axiosQuery.delete<ResponseStructure<InvoiceItem>>(
      `${rootAPI}/${payload?.id}/destroy-from-request-order`
    );
  }
  return axiosQuery.delete<ResponseStructure<InvoiceItem>>(`${rootAPI}/${payload?.id}`);
};

export const importInvoiceItemFileAPI = (payload?: InvoiceItemImportBody) => {
  return axiosQuery.post<ResponseStructure<InvoiceItem>>(`${rootAPI}/import`, payload?.file);
};
