import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableFoot,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import OverlayLoading from 'src/components/common/loading/OverlayLoading';
import { Invoice } from 'src/features/invoice/types';
import { convertToCurrency, sumByField } from 'src/helpers/common.helpers';

type TablePrintProps = {
  invoice?: Invoice;
  loading?: boolean;
  isTemplate2?: boolean;
};

const TablePrint: React.FC<TablePrintProps> = ({ invoice, loading, isTemplate2 }) => {
  return (
    <>
      {!isTemplate2 && <strong>Invoice</strong>}
      <OverlayLoading loading={loading}>
        <CTable className="table-print" bordered responsive>
          <CTableHead>
            <CTableRow>
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '5%' }}>
                #
              </CTableHeaderCell>
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '55%' }}>
                Item Name
              </CTableHeaderCell>
              {!isTemplate2 && (
                <>
                  <CTableHeaderCell className="text-center" scope="col" style={{ width: '10%' }}>
                    List Price
                  </CTableHeaderCell>
                  <CTableHeaderCell className="text-center" scope="col" style={{ width: '5%' }}>
                    Dis
                  </CTableHeaderCell>
                </>
              )}
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '10%' }}>
                Sale Price
              </CTableHeaderCell>
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '5%' }}>
                Qty
              </CTableHeaderCell>
              <CTableHeaderCell className="text-center" scope="col" style={{ width: '10%' }}>
                Total
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {invoice?.invoice_items?.map((item, index) => (
              <CTableRow key={item.id}>
                <CTableHeaderCell className="text-center" scope="row">
                  {index + 1}
                </CTableHeaderCell>
                <CTableDataCell>
                  <span className="two-line">{item?.item_name}</span>
                </CTableDataCell>
                {!isTemplate2 && (
                  <>
                    <CTableDataCell className="text-right">
                      {convertToCurrency(item.original_price)}
                    </CTableDataCell>
                    <CTableDataCell className="text-right">
                      {item.type_discount === 'currency'
                        ? convertToCurrency(item.discount)
                        : item.discount + '%'}
                    </CTableDataCell>
                  </>
                )}
                <CTableDataCell className="text-right">
                  {convertToCurrency(item.item_price)}
                </CTableDataCell>
                <CTableDataCell className="text-right">{item.item_quantity}</CTableDataCell>
                <CTableDataCell className="text-right">
                  {convertToCurrency(item.total_price || 0)}
                </CTableDataCell>
              </CTableRow>
            ))}
            <CTableRow>
              <CTableHeaderCell className="text-center" scope="row">
                Total
              </CTableHeaderCell>
              <CTableDataCell></CTableDataCell>

              {!isTemplate2 && (
                <>
                  <CTableDataCell className="text-right">
                    {!isTemplate2 &&
                      convertToCurrency(sumByField(invoice?.invoice_items ?? [], 'original_price'))}
                  </CTableDataCell>
                  <CTableDataCell className="text-right">
                    {/* {convertToCurrency(sumByField(invoice?.invoice_items ?? [], 'discount'))} */}
                  </CTableDataCell>
                </>
              )}
              <CTableDataCell className="text-right">
                {!isTemplate2 &&
                  convertToCurrency(sumByField(invoice?.invoice_items ?? [], 'item_price'))}
              </CTableDataCell>
              <CTableDataCell className="text-right">
                {sumByField(invoice?.invoice_items ?? [], 'item_quantity')}
              </CTableDataCell>
              <CTableDataCell className="text-right">
                {convertToCurrency(sumByField(invoice?.invoice_items ?? [], 'total_price'))}
              </CTableDataCell>
            </CTableRow>
          </CTableBody>
          {!isTemplate2 && (
            <CTableFoot>
              <CTableRow>
                <CTableDataCell colSpan={!isTemplate2 ? 9 : 7}>
                  <div
                    className="d-flex align-items-center"
                    style={{ justifyContent: isTemplate2 ? 'end' : 'space-between' }}
                  >
                    {invoice?.customer_returns && invoice.customer_returns.length > 0 ? (
                      <p className="me-2 ms-2">
                        <strong>Credit: </strong>{' '}
                        {invoice?.customer_return_total
                          ? convertToCurrency(+invoice.customer_return_total)
                          : convertToCurrency(0)}
                        , <strong>Return </strong> #{invoice?.customer_returns?.[0]?.id}
                      </p>
                    ) : null}
                    {!isTemplate2 && (
                      <>
                        <p className="me-2 ms-2">
                          <strong>Saving Total: </strong>
                          {invoice?.discount ? convertToCurrency(+invoice.discount) : 0}
                        </p>
                        <p className="me-2 ms-2">
                          <strong>Discount Inv: </strong>
                          {invoice?.discount_invoice ? +invoice.discount_invoice : 0}{' '}
                          {invoice?.discount_unit}
                        </p>
                      </>
                    )}
                    <p className="me-2 ms-2">
                      <strong>Total: </strong>
                      {invoice?.total ? convertToCurrency(+invoice.total) : 0}
                    </p>
                  </div>
                </CTableDataCell>
              </CTableRow>
            </CTableFoot>
          )}
        </CTable>
        {!isTemplate2 && <i> * All applicable Nicotine and tobacco taxes paid! </i>}
      </OverlayLoading>
    </>
  );
};

export default React.memo(TablePrint);
