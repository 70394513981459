/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';

import { sortByField } from 'src/helpers/common.helpers';
import {
  createWarehouseReturnAPI,
  deleteWarehouseReturnAPI,
  getListWarehouseReturnAPI,
  getWarehouseReturnAPI,
  updateWarehouseReturnAPI,
} from './apis';
import {
  createWarehouseReturnSuccess,
  deleteWarehouseReturnSuccess,
  getListWarehouseReturnSuccess,
  getWarehouseReturnSuccess,
  updateWarehouseReturnSuccess,
  warehouseReturnError,
} from './actions';
import { WarehouseReturnBody } from './types';

export function* getListWarehouseReturnWORKER({ payload }: ActionSaga<WarehouseReturnBody>) {
  try {
    const { data }: SagaReturnType<typeof getListWarehouseReturnAPI> = yield call(
      getListWarehouseReturnAPI,
      payload
    );
    yield put(getListWarehouseReturnSuccess(data.data));
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(warehouseReturnError());
  }
}

export function* getWarehouseReturnWORKER({ payload }: ActionSaga<WarehouseReturnBody>) {
  try {
    if (!payload?.id) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof getWarehouseReturnAPI> = yield call(
      getWarehouseReturnAPI,
      payload
    );
    yield put(getWarehouseReturnSuccess(data.data));
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(warehouseReturnError());
  }
}

export function* createWarehouseReturnWORKER({ payload }: ActionSaga<WarehouseReturnBody>) {
  try {
    if (!payload) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof createWarehouseReturnAPI> = yield call(
      createWarehouseReturnAPI,
      payload
    );
    yield put(createWarehouseReturnSuccess(data.data));
    showSuccessToast('Create Warehouse Return');
    window.location.replace(`#/warehouse-returns/${data.data?.id}`);
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(warehouseReturnError());
  }
}

export function* updateWarehouseReturnWORKER({ payload }: ActionSaga<WarehouseReturnBody>) {
  try {
    if (!payload?.id) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof updateWarehouseReturnAPI> = yield call(
      updateWarehouseReturnAPI,
      payload
    );
    yield put(updateWarehouseReturnSuccess(data.data));
    showSuccessToast('Update Warehouse Return');
    if (payload?.callback) {
      payload.callback(data.data);
    }
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(warehouseReturnError());
  }
}

export function* deleteWarehouseReturnWORKER({ payload }: ActionSaga<WarehouseReturnBody>) {
  try {
    if (!payload?.id) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof deleteWarehouseReturnAPI> = yield call(
      deleteWarehouseReturnAPI,
      payload
    );
    yield put(deleteWarehouseReturnSuccess());
    showSuccessToast('Delete Warehouse Return');
    window.location.replace('#/warehouse-returns');
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(warehouseReturnError());
  }
}
