/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  addInvoiceDraftItem,
  cancelledInvoiceDraftItem,
  deleteInvoiceDraftItem,
  getInvoiceDraftItem,
  getInvoiceDraftList,
  getMyInvoiceDraftList,
  setFilterInvoiceDraftList,
  updateInvoiceDraftItem,
} from './actions';
import {
  addInvoiceDraftItemWorker,
  deleteInvoiceDraftItemWorker,
  getInvoiceDraftItemWorker,
  getInvoiceDraftListWorker,
  setFilterInvoiceDraftListWorker,
  updateInvoiceDraftItemWorker,
  getMyInvoiceDraftListWorker,
  cancelledInvoiceDraftItemWorker,
} from './workers';

export function* invoiceDraftSaga() {
  yield takeLatest(getInvoiceDraftList, getInvoiceDraftListWorker);
  yield takeLatest(getMyInvoiceDraftList, getMyInvoiceDraftListWorker);
  yield takeLatest(getInvoiceDraftItem, getInvoiceDraftItemWorker);
  yield takeLatest(setFilterInvoiceDraftList, setFilterInvoiceDraftListWorker);
  yield takeLatest(addInvoiceDraftItem, addInvoiceDraftItemWorker);
  yield takeLatest(updateInvoiceDraftItem, updateInvoiceDraftItemWorker);
  yield takeLatest(deleteInvoiceDraftItem, deleteInvoiceDraftItemWorker);
  yield takeLatest(cancelledInvoiceDraftItem, cancelledInvoiceDraftItemWorker);
}
