/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import { Auth, UserAuth } from 'src/types/user.types';
import { User, UserUpdatePasswordBody, UserUpdateProfileBody } from '../user/types';

export const loginApi = (auth: UserAuth) => {
  return axiosQuery.post<Auth>('auth/login', auth);
};

export const getCurrentUserApi = () => {
  return axiosQuery.get<User>('user');
};

export const updatePasswordApi = (item: UserUpdatePasswordBody) => {
  return axiosQuery.post<User>('user/update-password', {
    _method: 'PATCH',
    ...item,
  });
};

export const updateProfileApi = (item: UserUpdateProfileBody) => {
  return axiosQuery.post<ResponseStructure<User>>(
    'user',
    {
      _method: 'PATCH',
      ...item,
    },
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
};
