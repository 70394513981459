/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  createWarehouseReturn,
  deleteWarehouseReturn,
  getListWarehouseReturn,
  getWarehouseReturn,
  updateWarehouseReturn,
} from './actions';
import {
  createWarehouseReturnWORKER,
  deleteWarehouseReturnWORKER,
  getListWarehouseReturnWORKER,
  getWarehouseReturnWORKER,
  updateWarehouseReturnWORKER,
} from './workers';

export function* warehouseReturnSaga() {
  yield takeLatest(getListWarehouseReturn, getListWarehouseReturnWORKER);
  yield takeLatest(getWarehouseReturn, getWarehouseReturnWORKER);
  yield takeLatest(createWarehouseReturn, createWarehouseReturnWORKER);
  yield takeLatest(updateWarehouseReturn, updateWarehouseReturnWORKER);
  yield takeLatest(deleteWarehouseReturn, deleteWarehouseReturnWORKER);
}
