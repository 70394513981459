/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ReducerSaga } from 'src/types/saga.types';
import {
  ADD_TRUCK_ITEM,
  ADD_TRUCK_ITEM_ERROR,
  ADD_TRUCK_ITEM_SUCCESS,
  CLEAR_TRUCK_LIST,
  DELETE_TRUCK_ITEM,
  DELETE_TRUCK_ITEM_ERROR,
  DELETE_TRUCK_ITEM_SUCCESS,
  GET_TRUCK_ITEM,
  GET_TRUCK_ITEM_ERROR,
  GET_TRUCK_ITEM_SUCCESS,
  GET_TRUCK_LIST,
  GET_TRUCK_LIST_ERROR,
  GET_TRUCK_LIST_SUCCESS,
  SET_FILTER_TRUCK_LIST,
  UPDATE_TRUCK_ITEM,
  UPDATE_TRUCK_ITEM_ERROR,
  UPDATE_TRUCK_ITEM_SUCCESS,
} from './actions';
import { TruckReducer } from './types';

const initialState: TruckReducer = {
  trucks: undefined,
};
const truckReducer: ReducerSaga<TruckReducer> = (state = initialState, payload): TruckReducer => {
  const { type, ...rest } = payload;
  switch (type) {
    case GET_TRUCK_LIST:
    case GET_TRUCK_ITEM:
    case ADD_TRUCK_ITEM:
    case UPDATE_TRUCK_ITEM:
    case DELETE_TRUCK_ITEM:
      return { ...state, ...rest.payload, loading: true };

    case GET_TRUCK_LIST_ERROR:
    case GET_TRUCK_ITEM_ERROR:
    case ADD_TRUCK_ITEM_ERROR:
    case UPDATE_TRUCK_ITEM_ERROR:
    case DELETE_TRUCK_ITEM_ERROR:
      showErrorToast();
      return { ...state, loading: false };

    case GET_TRUCK_LIST_SUCCESS:
      const { isPagination } = rest.payload?.pagination || {};
      return {
        ...state,
        ...rest.payload,
        trucks: isPagination
          ? (state.trucks || [])?.concat(rest.payload?.trucks || [])
          : rest.payload?.trucks,
        loading: false,
      };
    case GET_TRUCK_ITEM_SUCCESS:
      return { ...state, ...rest.payload, loading: false };
    case ADD_TRUCK_ITEM_SUCCESS:
      showSuccessToast();
      return {
        ...state,
        ...rest.payload,
        trucks: (rest.payload?.truck ? [rest.payload?.truck] : []).concat(state.trucks || []),
        loading: false,
      };
    case UPDATE_TRUCK_ITEM_SUCCESS:
      showSuccessToast();
      return {
        ...state,
        ...rest.payload,
        trucks: state.trucks?.map((e) =>
          e.id === rest.payload?.truck?.id ? { ...e, ...rest.payload.truck } : e
        ),
        loading: false,
      };
    case DELETE_TRUCK_ITEM_SUCCESS:
      showSuccessToast();
      return {
        ...state,
        ...rest.payload,
        trucks: state.trucks?.filter((e) => e.id !== rest.payload?.truck?.id),
        loading: false,
      };

    case SET_FILTER_TRUCK_LIST:
      return { ...state, loading: true, filterable: rest.payload?.filterable };
    case CLEAR_TRUCK_LIST:
      return { ...state, trucks: [], loading: true };

    default:
      return { ...state };
  }
};

export default truckReducer;
