import { useCallback, useState } from 'react';

export const useModal = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setVisible(false);
  }, []);

  return { showModal, closeModal, visible };
};
