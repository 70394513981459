import { axiosQuery } from 'src/services/axiosQuery';
import { RequestOrderItem, RequestOrderItemBody } from './types';
import { ResponseStructure } from 'src/types/axios.types';

const rootAPI = 'request-order-items';

export const createRequestOrderItemAPI = (payload?: RequestOrderItemBody) => {
  return axiosQuery.post<ResponseStructure<RequestOrderItem>>(rootAPI, payload);
};

export const createOrUpdateRequestOrderItemAPI = (payload?: RequestOrderItemBody) => {
  return axiosQuery.post<ResponseStructure<RequestOrderItem>>(
    rootAPI + '/create-or-update',
    payload
  );
};

export const updateRequestOrderItemAPI = (payload?: RequestOrderItemBody) => {
  return axiosQuery.put<ResponseStructure<RequestOrderItem>>(rootAPI + '/' + payload?.id, payload);
};

export const deleteRequestOrderItemAPI = (payload?: RequestOrderItemBody) => {
  if (!payload?.id) throw new Error('Err');
  return axiosQuery.delete<ResponseStructure<RequestOrderItem>>(rootAPI + '/' + payload.id);
};
