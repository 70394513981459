/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { ReducerSaga } from 'src/types/saga.types';
import {
  GET_CURRENT_USER,
  GET_CURRENT_USER_ERROR,
  GET_CURRENT_USER_SUCCESS,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  SET_AUTH_UI_VISIBLE,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_SUCCESS,
} from './actions';
import { AuthenticationReducer } from './types';
import _ from 'lodash';

const initialState: AuthenticationReducer = {
  name: '',
  email: '',
  password: '',
  loading: false,
  loadingFirst: false,
};

const authenticationReducer: ReducerSaga<AuthenticationReducer> = (
  state = initialState,
  actions
): AuthenticationReducer => {
  const { type, ...rest } = actions;
  switch (type) {
    case LOGIN:
      return { ...state, ...rest.payload, loading: true };
    case LOGIN_SUCCESS:
      return { ...state, ...rest.payload, loading: false };
    case LOGIN_ERROR:
      return { ...state, loading: false };
    case GET_CURRENT_USER:
      return { ...state, ...rest.payload, loading: true };
    case GET_CURRENT_USER_SUCCESS:
      return { ...state, ...rest.payload, loading: false, loadingFirst: true };
    case GET_CURRENT_USER_ERROR:
      return { ...state, loading: false };
    case LOGOUT:
      return { ...state, loading: false, token: null };
    case SET_AUTH_UI_VISIBLE:
      return { ...state, ...rest.payload };
    case UPDATE_PASSWORD:
      return { ...state, ...rest.payload, ui: { loadingBtn: true, visible: true } };
    case UPDATE_PASSWORD_SUCCESS:
      return { ...state, ...rest.payload, ui: { loadingBtn: true, visible: false } };
    case UPDATE_PASSWORD_ERROR:
      return { ...state, ui: { loadingBtn: false, visible: true } };
    case UPDATE_PROFILE:
      return { ...state, ...rest.payload, ui: { loadingBtn: true, visible: true } };
    case UPDATE_PROFILE_SUCCESS:
      const newUser = _.merge(state?.user, rest?.payload?.user);
      return {
        ...state,
        user: newUser ? { ...newUser } : undefined,
        ui: { loadingBtn: true, visible: false },
      };
    case UPDATE_PROFILE_ERROR:
      return { ...state, ui: { loadingBtn: false, visible: true } };
    default:
      return state;
  }
};

export default authenticationReducer;
