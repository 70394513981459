/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import { TruckDelivery, TruckDeliveryBody, TruckDeliveryParams } from './types';

const rootAPI = 'truck-deliveries';

export const getCurrentTruckDeliveryAPI = (payload: TruckDeliveryParams) => {
  return axiosQuery.get<ResponseStructure<TruckDelivery>>(rootAPI + '/truck/' + payload.truck_id, {
    params: { ...payload },
  });
};

export const getTruckDeliveryAPI = (payload: Required<Pick<TruckDeliveryParams, 'user_id'>>) => {
  return axiosQuery.get<ResponseStructure<TruckDelivery[]>>(rootAPI + '/user/' + payload.user_id);
};

export const getTruckDeliveryByTruckAPI = (
  payload: Required<Pick<TruckDeliveryParams, 'truck_id'>>
) => {
  return axiosQuery.get<ResponseStructure<TruckDelivery[]>>(
    rootAPI + '/trucks/' + payload.truck_id
  );
};

export const getListTruckDeliveryAPI = (payload: Pick<TruckDeliveryParams, 'truck_id'>) => {
  return axiosQuery.get<ResponseStructure<TruckDelivery[]>>(rootAPI, { params: payload });
};

export const getDetailTruckDeliveryAPI = (payload: { id: string }) => {
  return axiosQuery.get<ResponseStructure<TruckDelivery>>(rootAPI + '/' + payload.id);
};

export const createTruckDeliveryAPI = (payload: TruckDeliveryBody) => {
  return axiosQuery.post<ResponseStructure<TruckDelivery>>(rootAPI, payload);
};

export const sendBackToLoadInByTruckDeliveryAPI = (
  payload: Required<Pick<TruckDeliveryBody, 'id'>>
) => {
  return axiosQuery.post<ResponseStructure<TruckDelivery>>(
    rootAPI + '/send-back-to-load-in/' + payload.id
  );
};

export const updateTruckDeliveryAPI = (payload: TruckDeliveryBody) => {
  return axiosQuery.post<ResponseStructure<TruckDelivery>>(rootAPI + '/' + payload.id, {
    ...payload,
    _method: 'PATCH',
  });
};

export const updateLoadInTruckDeliveryAPI = (payload: TruckDeliveryBody) => {
  return axiosQuery.post<ResponseStructure<TruckDelivery>>(
    rootAPI + '/update-load-in/' + payload.id,
    {
      _method: 'PUT',
    }
  );
};

export const deleteTruckDeliveryAPI = (payload: { id: string }) => {
  return axiosQuery.delete<ResponseStructure<{ id: string }>>(rootAPI + '/' + payload.id);
};

export const getCanRevertTruckDeliveryAPI = (payload: { id: string }) => {
  return axiosQuery.get<ResponseStructure<boolean>>(rootAPI + '/can-revert/' + payload.id);
};
