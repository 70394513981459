/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer } from '@reduxjs/toolkit';
import { getDashboard, getDashboardError, getDashboardSuccess } from './actions';
import { DashboardReducer } from './types';

const initialState = {};

export const dashboardReducer = createReducer<DashboardReducer>(initialState, (builder) =>
  builder
    .addCase(getDashboard, (state, { payload }) => {
      state.ui = { ...state.ui, loading: true };
    })
    .addCase(getDashboardSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
      state.dashboard = payload;
    })
    .addCase(getDashboardError, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
    })
    .addDefaultCase((state, action) => {})
);
