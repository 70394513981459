import { CBadge } from '@coreui/react';
import React from 'react';
import { Invoice } from 'src/features/invoice/types';
import useCurrentTruckDelivery from '../../truckDelivery/hooks/useCurrentTruckDelivery';
import { isString } from 'lodash';

type InvoiceStatusProps = {
  invoice: Invoice;
  isConvertString?: boolean;
};

const InvoiceStatus: React.FC<InvoiceStatusProps> = ({ invoice, isConvertString }) => {
  const { is_complete, is_cancelled } = invoice;

  if (isConvertString) {
    return <>{is_cancelled ? 'Cancelled' : is_complete === 1 ? 'Completed' : 'Pending'}</>;
  }

  if (is_cancelled) {
    return <CBadge color="danger">Cancelled</CBadge>;
  }

  if (is_complete === 1) {
    return <CBadge color="success">Completed</CBadge>;
  }

  return <CBadge color="primary">Pending</CBadge>;
};

export default React.memo(InvoiceStatus);
