/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */
import _ from 'lodash';
import { ReducerSaga } from 'src/types/saga.types';
import {
  ADD_INVOICE_ITEM_ITEM,
  ADD_INVOICE_ITEM_ITEM_ERROR,
  ADD_INVOICE_ITEM_ITEM_SUCCESS,
  CLEAR_INVOICE_ITEM_LIST,
  DELETE_INVOICE_ITEM_ITEM,
  DELETE_INVOICE_ITEM_ITEM_ERROR,
  DELETE_INVOICE_ITEM_ITEM_SUCCESS,
  GET_INVOICE_ITEM_ITEM,
  GET_INVOICE_ITEM_ITEM_ERROR,
  GET_INVOICE_ITEM_ITEM_SUCCESS,
  GET_INVOICE_ITEM_LIST,
  GET_INVOICE_ITEM_LIST_ERROR,
  GET_INVOICE_ITEM_LIST_SUCCESS,
  PUSH_INVOICE_ITEM_TO_NEW,
  SET_FILTER_INVOICE_ITEM_LIST,
  SET_INVOICE_ITEM_VISIBLE,
  UPDATE_INVOICE_ITEM_ITEM,
  UPDATE_INVOICE_ITEM_ITEM_ERROR,
  UPDATE_INVOICE_ITEM_ITEM_SUCCESS,
} from './actions';
import { InvoiceItemReducer } from './types';

const initialState: InvoiceItemReducer = {
  invoiceItems: undefined,
};

const invoiceItemReducer: ReducerSaga<InvoiceItemReducer> = (
  state = initialState,
  payload
): InvoiceItemReducer => {
  const { type, ...rest } = payload;
  switch (type) {
    case GET_INVOICE_ITEM_LIST:
    case GET_INVOICE_ITEM_ITEM:
      return { ...state, ...rest.payload, ui: { loading: true } };

    case ADD_INVOICE_ITEM_ITEM:
    case UPDATE_INVOICE_ITEM_ITEM:
    case DELETE_INVOICE_ITEM_ITEM:
      return { ...state, ...rest.payload, ui: { loading: true, loadingBtn: true, visible: false } };

    case GET_INVOICE_ITEM_LIST_ERROR:
    case GET_INVOICE_ITEM_ITEM_ERROR:
      return {
        ...state,
        ui: { loading: false, loadingBtn: false, loadingPage: false },
      };

    case ADD_INVOICE_ITEM_ITEM_ERROR:
    case UPDATE_INVOICE_ITEM_ITEM_ERROR:
    case DELETE_INVOICE_ITEM_ITEM_ERROR:
      return {
        ...state,
        ui: { loading: false, loadingBtn: false, loadingPage: false, visible: true },
      };

    case GET_INVOICE_ITEM_LIST_SUCCESS:
      const { isPagination } = rest.payload?.pagination || {};
      return {
        ...state,
        ...rest.payload,
        invoiceItems: isPagination
          ? (state.invoiceItems || [])?.concat(rest.payload?.invoiceItems || [])
          : rest.payload?.invoiceItems,
        ui: { loading: false, loadingBtn: false },
      };
    case GET_INVOICE_ITEM_ITEM_SUCCESS:
      return { ...state, ...rest.payload, ui: { loading: false } };
    case ADD_INVOICE_ITEM_ITEM_SUCCESS:
      return {
        ...state,
        ...rest.payload,
        invoiceItems: (rest.payload?.invoiceItem ? [rest.payload?.invoiceItem] : []).concat(
          state.invoiceItems || []
        ),
        ui: {
          loading: false,
          loadingBtn: false,
          visible: false,
          key: (state?.ui?.key || 0) + 1,
        },
      };
    case UPDATE_INVOICE_ITEM_ITEM_SUCCESS:
      return {
        ...state,
        ...rest.payload,
        invoiceItems: state.invoiceItems?.map((e) =>
          e.id === rest.payload?.invoiceItem?.id ? { ...e, ...rest.payload.invoiceItem } : e
        ),
        ui: {
          loading: false,
          loadingBtn: false,
          visible: true,
        },
      };
    case DELETE_INVOICE_ITEM_ITEM_SUCCESS:
      return {
        ...state,
        ...rest.payload,
        invoiceItems: state.invoiceItems?.filter((e) => e.id !== rest.payload?.invoiceItem?.id),
        ui: { loading: false, loadingBtn: false, visible: false, key: (state?.ui?.key || 0) + 1 },
      };

    case SET_FILTER_INVOICE_ITEM_LIST:
      return { ...state, ui: { loading: true }, filterable: rest.payload?.filterable };
    case CLEAR_INVOICE_ITEM_LIST:
      return { ...state, invoiceItems: [], ui: { loading: true } };
    case SET_INVOICE_ITEM_VISIBLE:
      const newUI = _.merge(state?.ui, rest.payload?.ui);
      return { ...state, ui: { ...newUI } };
    case PUSH_INVOICE_ITEM_TO_NEW:
      const newInvoiceItems = _.concat(
        rest.payload?.mutable ? [rest.payload?.mutable] : [],
        state.invoiceItems || []
      );
      return { ...state, invoiceItems: [...newInvoiceItems] };

    default:
      return { ...state };
  }
};

export default invoiceItemReducer;
