/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import { LoadIn, LoadInFilterable, LoadInHistoryCountBody, LoadInInventoriesParams } from './types';

const name = 'load-in-inventories';

export const getLoadInInventoriesAPI = (action: LoadInInventoriesParams) => {
  return axiosQuery.get<ResponseStructure<LoadIn[]>>(name + '/' + action.userId, {
    params: action,
  });
};

export const updateLoadInInventoriesAPI = (action: LoadIn) => {
  return axiosQuery.put<ResponseStructure<LoadIn>>(name, action);
};

export const getLoadInInventoriesByDeliveryAPI = (action: {
  truck_delivery_id?: string;
  filterable?: LoadInFilterable;
}) => {
  if (!action.truck_delivery_id) {
    throw new Error('truck_delivery_id not undefined');
  }
  return axiosQuery.get<ResponseStructure<LoadIn[]>>(
    name + '/truck-delivery/' + action.truck_delivery_id,
    { params: action.filterable }
  );
};

export const getLoadInInventoriesByHistoryCountAPI = (action: LoadInHistoryCountBody) => {
  return axiosQuery.get<ResponseStructure<LoadIn[]>>(name + '/history-count/report', {
    params: action,
  });
};
