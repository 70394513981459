/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { InvoiceItem } from '../invoiceItem/types';
import {
  CreateInvoice,
  Invoice,
  InvoiceDeleteBody,
  InvoiceItemPayload,
  InvoiceItemSuccessPayload,
  InvoiceListParams,
  InvoiceListSuccessPayload,
  InvoiceMyListSuccessPayload,
  InvoiceUpdateBody,
  InvoiceVisiblePayload,
} from './types';
import { InvoicePayment } from '../invoicePayment/types';

// LIST
export const getInvoiceList = createAction<InvoiceListParams | undefined>('invoice/getInvoiceList');
export const getInvoiceListSuccess = createAction<InvoiceListSuccessPayload>(
  'invoice/getInvoiceList/success'
);
export const getInvoiceListError = createAction('invoice/getInvoiceList/error');

export const getMyInvoiceList = createAction<InvoiceListParams | undefined>(
  'invoice/getMyInvoiceList'
);
export const getMyInvoiceListSuccess = createAction<InvoiceMyListSuccessPayload>(
  'invoice/getMyInvoiceList/success'
);
export const getMyInvoiceListError = createAction('invoice/getMyInvoiceList/error');

export const setFilterInvoiceList = createAction<InvoiceListParams>('invoice/setFilterInvoiceList');
export const clearInvoiceList = createAction<{ isMyInvoice?: boolean } | undefined>(
  'invoice/clearInvoiceList'
);

// ITEM
export const getInvoiceItem = createAction<InvoiceItemPayload>('invoice/getInvoiceItem');
export const getInvoiceItemSuccess = createAction<InvoiceItemSuccessPayload>(
  'invoice/getInvoiceItem/success'
);
export const getInvoiceItemError = createAction('invoice/getInvoiceItem/error');

// ADD
export const addInvoiceItem = createAction<CreateInvoice>('invoice/addInvoiceItem');
export const addInvoiceItemSuccess = createAction<InvoiceItemSuccessPayload>(
  'invoice/addInvoiceItem/success'
);
export const addInvoiceItemError = createAction('invoice/addInvoiceItem/error');

// UPDATE
export const updateInvoiceItem = createAction<InvoiceUpdateBody>('invoice/updateInvoiceItem');
export const updateInvoiceItemSuccess = createAction<InvoiceItemSuccessPayload>(
  'invoice/updateInvoiceItem/success'
);
export const updateInvoiceItemError = createAction('invoice/updateInvoiceItem/error');

// DELETE
export const deleteInvoiceItem = createAction<InvoiceDeleteBody>('invoice/deleteInvoiceItem');
export const deleteInvoiceItemSuccess = createAction<InvoiceItemSuccessPayload>(
  'invoice/deleteInvoiceItem/success'
);
export const deleteInvoiceItemError = createAction('invoice/deleteInvoiceItem/error');

// Cancelled
export const cancelledInvoiceItem = createAction<InvoiceDeleteBody>('invoice/cancelledInvoiceItem');
export const cancelledInvoiceItemSuccess = createAction<InvoiceItemSuccessPayload>(
  'invoice/cancelledInvoiceItem/success'
);
export const cancelledInvoiceItemError = createAction('invoice/cancelledInvoiceItem/error');

// UI
export const setInvoiceVisible = createAction<InvoiceVisiblePayload>('invoice/setInvoiceVisible');

// OTHER
export const pushInvoiceItemToInvoice = createAction<InvoiceItem>(
  'invoice/pushInvoiceItemToInvoice'
);
export const removeInvoiceItemToInvoice = createAction<Pick<InvoiceItem, 'id'>>(
  'invoice/removeInvoiceItemToInvoice'
);
export const updateCreateInvoice = createAction<CreateInvoice>('invoice/updateCreateInvoice');
export const updateTemporarySelectPayment = createAction<string>(
  'invoice/updateTemporarySelectPayment'
);
export const updateInvoiceUI = createAction<Invoice>('invoice/updateInvoiceUI');
export const addPaymentItemFromPayment = createAction<InvoicePayment>(
  'invoice/addPaymentItemFromPayment'
);
export const removePaymentItemFromPayment = createAction<InvoicePayment>(
  'invoice/removePaymentItemFromPayment'
);
export const clearFieldInvoiceItem = createAction('invoice/clearFieldInvoiceItem');

export const mapInvoiceToList = createAction<Invoice & { isMyInvoice?: boolean }>(
  'invoice/mapInvoiceToList'
);
