/**
 * The event send to the store
 */

import { InitialState } from 'src/define/layout.interface';

export const SET_TOGGLE_LAYOUT = 'SET_TOGGLE_LAYOUT';

export function setToggleLayout(payload: InitialState) {
  return {
    type: SET_TOGGLE_LAYOUT,
    ...payload,
  };
}
