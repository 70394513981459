/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { InvoiceDraftItem } from '../invoiceDraftItem/types';
import {
  CreateInvoiceDraft,
  InvoiceDraft,
  InvoiceDraftDeleteBody,
  InvoiceDraftItemPayload,
  InvoiceDraftItemSuccessPayload,
  InvoiceDraftListParams,
  InvoiceDraftListSuccessPayload,
  InvoiceDraftMyListSuccessPayload,
  InvoiceDraftUpdateBody,
  InvoiceDraftVisiblePayload,
} from './types';
import { InvoicePayment } from '../invoicePayment/types';

// LIST
export const getInvoiceDraftList = createAction<InvoiceDraftListParams | undefined>(
  'invoiceDraft/getInvoiceDraftList'
);
export const getInvoiceDraftListSuccess = createAction<InvoiceDraftListSuccessPayload>(
  'invoiceDraft/getInvoiceDraftList/success'
);
export const getInvoiceDraftListError = createAction('invoiceDraft/getInvoiceDraftList/error');

export const getMyInvoiceDraftList = createAction<InvoiceDraftListParams | undefined>(
  'invoiceDraft/getMyInvoiceDraftList'
);
export const getMyInvoiceDraftListSuccess = createAction<InvoiceDraftMyListSuccessPayload>(
  'invoiceDraft/getMyInvoiceDraftList/success'
);
export const getMyInvoiceDraftListError = createAction('invoiceDraft/getMyInvoiceDraftList/error');

export const setFilterInvoiceDraftList = createAction<InvoiceDraftListParams>(
  'invoiceDraft/setFilterInvoiceDraftList'
);
export const clearInvoiceDraftList = createAction<{ isMyInvoiceDraft?: boolean } | undefined>(
  'invoiceDraft/clearInvoiceDraftList'
);

// ITEM
export const getInvoiceDraftItem = createAction<InvoiceDraftItemPayload>(
  'invoiceDraft/getInvoiceDraftItem'
);
export const getInvoiceDraftItemSuccess = createAction<InvoiceDraftItemSuccessPayload>(
  'invoiceDraft/getInvoiceDraftItem/success'
);
export const getInvoiceDraftItemError = createAction('invoiceDraft/getInvoiceDraftItem/error');

// ADD
export const addInvoiceDraftItem = createAction<CreateInvoiceDraft>(
  'invoiceDraft/addInvoiceDraftItem'
);
export const addInvoiceDraftItemSuccess = createAction<InvoiceDraftItemSuccessPayload>(
  'invoiceDraft/addInvoiceDraftItem/success'
);
export const addInvoiceDraftItemError = createAction('invoiceDraft/addInvoiceDraftItem/error');

// UPDATE
export const updateInvoiceDraftItem = createAction<InvoiceDraftUpdateBody>(
  'invoiceDraft/updateInvoiceDraftItem'
);
export const updateInvoiceDraftItemSuccess = createAction<InvoiceDraftItemSuccessPayload>(
  'invoiceDraft/updateInvoiceDraftItem/success'
);
export const updateInvoiceDraftItemError = createAction(
  'invoiceDraft/updateInvoiceDraftItem/error'
);

// DELETE
export const deleteInvoiceDraftItem = createAction<InvoiceDraftDeleteBody>(
  'invoiceDraft/deleteInvoiceDraftItem'
);
export const deleteInvoiceDraftItemSuccess = createAction<InvoiceDraftItemSuccessPayload>(
  'invoiceDraft/deleteInvoiceDraftItem/success'
);
export const deleteInvoiceDraftItemError = createAction(
  'invoiceDraft/deleteInvoiceDraftItem/error'
);

// Cancelled
export const cancelledInvoiceDraftItem = createAction<InvoiceDraftDeleteBody>(
  'invoiceDraft/cancelledInvoiceDraftItem'
);
export const cancelledInvoiceDraftItemSuccess = createAction<InvoiceDraftItemSuccessPayload>(
  'invoiceDraft/cancelledInvoiceDraftItem/success'
);
export const cancelledInvoiceDraftItemError = createAction(
  'invoiceDraft/cancelledInvoiceDraftItem/error'
);

// UI
export const setInvoiceDraftVisible = createAction<InvoiceDraftVisiblePayload>(
  'invoiceDraft/setInvoiceDraftVisible'
);

// OTHER
export const pushInvoiceDraftItemToInvoiceDraft = createAction<InvoiceDraftItem>(
  'invoiceDraft/pushInvoiceDraftItemToInvoiceDraft'
);
export const removeInvoiceDraftItemToInvoiceDraft = createAction<Pick<InvoiceDraftItem, 'id'>>(
  'invoiceDraft/removeInvoiceDraftItemToInvoiceDraft'
);
export const updateCreateInvoiceDraft = createAction<CreateInvoiceDraft>(
  'invoiceDraft/updateCreateInvoiceDraft'
);
export const updateTemporarySelectPayment = createAction<string>(
  'invoiceDraft/updateTemporarySelectPayment'
);
export const updateInvoiceDraftUI = createAction<InvoiceDraft>('invoiceDraft/updateInvoiceDraftUI');
export const addPaymentItemFromPayment = createAction<InvoicePayment>(
  'invoiceDraft/addPaymentItemFromPayment'
);
export const removePaymentItemFromPayment = createAction<InvoicePayment>(
  'invoiceDraft/removePaymentItemFromPayment'
);
export const clearFieldInvoiceDraftItem = createAction('invoiceDraft/clearFieldInvoiceDraftItem');

export const mapInvoiceDraftToList = createAction<InvoiceDraft & { isMyInvoiceDraft?: boolean }>(
  'invoiceDraft/mapInvoiceDraftToList'
);
