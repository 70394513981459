/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { ReducerSaga } from 'src/types/saga.types';
import {
  ADD_USER_ITEM,
  ADD_USER_ITEM_ERROR,
  ADD_USER_ITEM_SUCCESS,
  CLEAR_USER_LIST,
  DELETE_USER_ITEM,
  DELETE_USER_ITEM_ERROR,
  DELETE_USER_ITEM_SUCCESS,
  GET_USER_ITEM,
  GET_USER_ITEM_ERROR,
  GET_USER_ITEM_SUCCESS,
  GET_USER_LIST,
  GET_USER_LIST_ERROR,
  GET_USER_LIST_SUCCESS,
  SET_FILTER_USER_LIST,
  SET_USER_VISIBLE,
  UPDATE_USER_ITEM,
  UPDATE_USER_ITEM_ERROR,
  UPDATE_USER_ITEM_SUCCESS,
} from './actions';
import { UserReducer } from './types';

const initialState: UserReducer = {
  user: undefined,
};

const userReducer: ReducerSaga<UserReducer> = (state = initialState, payload): UserReducer => {
  const { type, ...rest } = payload;
  switch (type) {
    case GET_USER_LIST:
    case GET_USER_ITEM:
      return { ...state, ...rest.payload, ui: { loading: true } };
    case GET_USER_LIST_ERROR:
    case GET_USER_ITEM_ERROR:
      return { ...state, ui: { loading: false, loadingBtn: false } };
    case GET_USER_LIST_SUCCESS:
      const { isPagination } = rest.payload?.pagination || {};
      return {
        ...state,
        ...rest.payload,
        users: isPagination
          ? (state.users || [])?.concat(rest.payload?.users || [])
          : rest.payload?.users,
        ui: { loading: false, visible: false },
      };
    case GET_USER_ITEM_SUCCESS:
      return { ...state, ...rest.payload, ui: { loading: false } };

    case ADD_USER_ITEM:
    case UPDATE_USER_ITEM:
      return { ...state, ...rest.payload, ui: { loadingBtn: true, visible: true } };
    case ADD_USER_ITEM_ERROR:
    case UPDATE_USER_ITEM_ERROR:
      return { ...state, ui: { loading: false, loadingBtn: false, visible: true } };
    case ADD_USER_ITEM_SUCCESS:
      return {
        ...state,
        ...rest.payload,
        users: (rest.payload?.user ? [rest.payload?.user] : []).concat(state.users || []),
        ui: { loadingBtn: false, visible: false },
      };
    case UPDATE_USER_ITEM_SUCCESS:
      return {
        ...state,
        ...rest.payload,
        users: state.users?.map((e) =>
          e.id === rest.payload?.user?.id ? { ...e, ...rest.payload.user } : e
        ),
        ui: { loadingBtn: false, visible: false },
      };

    case DELETE_USER_ITEM:
      return { ...state, ...rest.payload, ui: { loading: true } };
    case DELETE_USER_ITEM_ERROR:
      return { ...state, ui: { loading: false, loadingBtn: false } };
    case DELETE_USER_ITEM_SUCCESS:
      return {
        ...state,
        ...rest.payload,
        users: state.users?.filter((e) => e.id !== rest.payload?.user?.id),
        ui: { loading: false },
      };

    case SET_FILTER_USER_LIST:
      return { ...state, ui: { loading: true }, filterable: rest.payload?.filterable };
    case CLEAR_USER_LIST:
      return { ...state, users: [], ui: { loading: true } };
    case SET_USER_VISIBLE:
      return { ...state, ui: { ...rest.payload?.ui } };

    default:
      return { ...state };
  }
};

export default userReducer;
