/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { RequestOrder, RequestOrderBody, RequestOrderParams } from './types';
import { RequestOrderItem, RequestOrderItemBody } from '../requestOrderItem/types';

export const getRequestOrderManagerList = createAction<RequestOrderParams | undefined>(
  'requestOrder/getRequestOrderManagerList'
);
export const getRequestOrderPersonalList = createAction<RequestOrderParams | undefined>(
  'requestOrder/getRequestOrderPersonalList'
);
export const getRequestOrderListSuccess = createAction<RequestOrder[]>(
  'requestOrder/getRequestOrderList/success'
);

export const getRequestOrderItem = createAction<RequestOrderParams | undefined>(
  'requestOrder/getRequestOrderItem'
);
export const getRequestOrderItemSuccess = createAction<RequestOrder>(
  'requestOrder/getRequestOrderItem/success'
);
export const restoreRequestOrder = createAction('requestOrder/restoreRequestOrder');

export const updateRequestOrder = createAction<RequestOrderBody & { reload?: boolean }>(
  'requestOrder/updateRequestOrder'
);
export const addToTruckRequestOrder = createAction<RequestOrderBody>(
  'requestOrder/addToTruckRequestOrder'
);
export const revertTruckRequestOrder = createAction<RequestOrderBody>(
  'requestOrder/revertTruckRequestOrder'
);
export const createInvoiceRequestOrder = createAction<RequestOrderBody>(
  'requestOrder/createInvoiceRequestOrder'
);
export const revertInvoiceRequestOrder = createAction<RequestOrderBody>(
  'requestOrder/revertInvoiceRequestOrder'
);
export const updateRequestOrderSuccess = createAction<RequestOrder>(
  'requestOrder/updateRequestOrder/success'
);

export const createRequestOrder = createAction<RequestOrderBody>('requestOrder/createRequestOrder');
export const createRequestOrderSuccess = createAction<RequestOrder>(
  'requestOrder/createRequestOrder/success'
);

export const deleteRequestOrder = createAction<Pick<RequestOrderBody, 'id'>>(
  'requestOrder/deleteRequestOrder'
);
export const deleteRequestOrderSuccess = createAction<RequestOrder>(
  'requestOrder/deleteRequestOrder/success'
);

export const updateRequestOrderItemInRequestOrder = createAction<RequestOrderItemBody>(
  'requestOrder/updateRequestOrderItemInRequestOrder'
);

export const requestOrderError = createAction('requestOrder/requestOrderError');

// OTHER
export const resetRequestOrder = createAction('requestOrder/resetRequestOrder');
