import { CCol, CContainer, CRow } from '@coreui/react';
import React from 'react';
import logoPrint from '../../../assets/images/logo-print.jpg';

type TemplatePrintProps = {
  children?: React.ReactNode;
  headline?: React.ReactNode;
  isHiddenInfo?: boolean;
};

const TemplatePrint: React.FC<TemplatePrintProps> = ({ children, headline, isHiddenInfo }) => {
  return (
    <>
      <CContainer className="print-element" md>
        <CRow>
          {!isHiddenInfo && (
            <CCol xs={12} style={{ borderBottom: '1px solid #000' }}>
              <CRow>
                <CCol xs={3}>
                  <img src={logoPrint} alt="logo-print" style={{ maxWidth: 175 }} />
                </CCol>
                <CCol xs={9}>
                  <h1 className="text-center text-uppercase">NIMBUS WHOLESALE</h1>
                  <p className="text-center">4825 Broadway, Denver, CO 80216</p>
                  <p className="text-center">
                    <span>(855)-857-3599</span> | <span>nimbuswholesale@yahoo.com</span> |{' '}
                    <span>www.NimbusImports.com</span>
                  </p>
                  <p className="text-center"></p>
                  <h3 className="text-center">{headline}</h3>
                </CCol>
              </CRow>
            </CCol>
          )}
          <CCol xs={12}>{children}</CCol>
        </CRow>
      </CContainer>
    </>
  );
};

export default React.memo(TemplatePrint);
