/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import {
  Inventory,
  InventoryAddBody,
  InventoryDeleteBody,
  InventoryImportBody,
  InventoryItemPayload,
  InventoryListParams,
  InventoryUpdateBody,
} from './types';

const rootAPI = 'inventories';

export const getInventoryListAPI = (payload?: InventoryListParams) => {
  return axiosQuery.get<ResponseStructure<Inventory[]>>(rootAPI, {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getInventoryItemAPI = (payload?: InventoryItemPayload) => {
  return axiosQuery.get<ResponseStructure<Inventory>>(`${rootAPI}/${payload?.id}`);
};

export const addInventoryItemAPI = (payload?: InventoryAddBody) => {
  return axiosQuery.post<ResponseStructure<Inventory>>(rootAPI, payload, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const updateInventoryItemAPI = (payload?: InventoryUpdateBody) => {
  return axiosQuery.post<ResponseStructure<Inventory>>(
    `${rootAPI}/${payload?.id}`,
    {
      _method: 'PUT',
      ...payload,
    },
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
};

export const deleteInventoryItemAPI = (payload?: InventoryDeleteBody) => {
  return axiosQuery.delete<ResponseStructure<Inventory>>(`${rootAPI}/${payload?.id}`);
};

export const importInventoryFileAPI = (payload?: InventoryImportBody) => {
  return axiosQuery.post<ResponseStructure<Inventory>>(`${rootAPI}/import`, payload?.file);
};

export const rollBackInventoryAPI = () => {
  return axiosQuery.post<ResponseStructure<Inventory>>(`${rootAPI}/rollback`);
};
