/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import { getInvoiceItem, removeInvoiceItemToInvoice } from '../invoice/actions';
import {
  addInvoiceSubItemError,
  addInvoiceSubItemSuccess,
  clearInvoiceItemList,
  deleteInvoiceSubItemError,
  deleteInvoiceSubItemSuccess,
  getInvoiceSubItemError,
  getInvoiceSubItemSuccess,
  getInvoiceItemListError,
  getInvoiceItemListSuccess,
  updateInvoiceSubItemError,
  updateInvoiceSubItemSuccess,
} from './actions';
import {
  addInvoiceSubItemAPI,
  deleteInvoiceSubItemAPI,
  getInvoiceSubItemAPI,
  getInvoiceItemListAPI,
  updateInvoiceSubItemAPI,
} from './apis';
import {
  InvoiceItemAddBody,
  InvoiceSubItemPayload,
  InvoiceItemListParams,
  InvoiceItemUpdateBody,
  InvoiceItemDeleteBody,
} from './types';
import { increaseKeyCurrentUser } from '../currentUser/actions';

export function* getInvoiceItemListWorker(action: ActionSaga<InvoiceItemListParams>) {
  try {
    const { data }: SagaReturnType<typeof getInvoiceItemListAPI> = yield call(
      getInvoiceItemListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getInvoiceItemListSuccess({
        invoiceItems: data.data,
        pagination: newPagination,
      })
    );
  } catch {
    yield put(getInvoiceItemListError());
  }
}

export function* setFilterInvoiceItemListWorker(action: ActionSaga<InvoiceItemListParams>) {
  yield put(clearInvoiceItemList());
  yield call(getInvoiceItemListWorker, action);
}

export function* getInvoiceSubItemWorker(action: ActionSaga<InvoiceSubItemPayload>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getInvoiceSubItemAPI> = yield call(
        getInvoiceSubItemAPI,
        action.payload
      );
      yield put(getInvoiceSubItemSuccess({ invoiceItem: data.data }));
    }
  } catch {
    yield put(getInvoiceSubItemError());
    showErrorToast();
  }
}

export function* addInvoiceSubItemWorker(action: ActionSaga<InvoiceItemAddBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof addInvoiceSubItemAPI> = yield call(
        addInvoiceSubItemAPI,
        action.payload
      );
      yield put(addInvoiceSubItemSuccess({ invoiceItem: data.data }));
      yield put(increaseKeyCurrentUser());

      if (action.payload.invoice_id) {
        yield put(getInvoiceItem({ id: action.payload.invoice_id }));
      }

      if (action.payload.callback) {
        action.payload.callback(data.data);
      }

      showSuccessToast();
    }
  } catch (e) {
    const message = e.response?.data?.message ?? '';
    yield put(addInvoiceSubItemError());
    showErrorToast(message);
  }
}

export function* updateInvoiceSubItemWorker(action: ActionSaga<InvoiceItemUpdateBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateInvoiceSubItemAPI> = yield call(
        updateInvoiceSubItemAPI,
        action.payload
      );
      yield put(updateInvoiceSubItemSuccess({ invoiceItem: data.data }));
      if (action.payload?.callback) {
        action.payload.callback(data.data);
      }
      showSuccessToast();
    }
  } catch (e) {
    const message = e.response?.data?.message ?? '';
    yield put(updateInvoiceSubItemError());
    showErrorToast(message);
  }
}

export function* deleteInvoiceSubItemWorker(action: ActionSaga<InvoiceItemDeleteBody>) {
  try {
    if (action.payload) {
      const { invoice_id, id } = action.payload;
      const { data }: SagaReturnType<typeof deleteInvoiceSubItemAPI> = yield call(
        deleteInvoiceSubItemAPI,
        action.payload
      );
      yield put(deleteInvoiceSubItemSuccess({ invoiceItem: { ...data.data, id } }));
      yield put(removeInvoiceItemToInvoice({ id }));

      if (invoice_id) {
        yield put(getInvoiceItem({ id: invoice_id }));
      }

      if (action.payload?.callback) {
        action.payload.callback();
      }
      showSuccessToast();
    }
  } catch {
    yield put(deleteInvoiceSubItemError());
    showErrorToast();
  }
}
