/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  ADD_MULTIPLE_INVOICE_DRAFT_ITEM_ITEM,
  ADD_INVOICE_DRAFT_ITEM_ITEM,
  DELETE_INVOICE_DRAFT_ITEM_ITEM,
  GET_INVOICE_DRAFT_ITEM_ITEM,
  GET_INVOICE_DRAFT_ITEM_LIST,
  SET_FILTER_INVOICE_DRAFT_ITEM_LIST,
  UPDATE_INVOICE_DRAFT_ITEM_ITEM,
} from './actions';
import {
  addInvoiceDraftSubItemWorker,
  deleteInvoiceDraftSubItemWorker,
  getInvoiceDraftSubItemWorker,
  getInvoiceDraftItemListWorker,
  setFilterInvoiceDraftItemListWorker,
  updateInvoiceDraftSubItemWorker,
} from './workers';

export function* invoiceDraftItemSaga() {
  yield takeLatest(GET_INVOICE_DRAFT_ITEM_LIST, getInvoiceDraftItemListWorker);
  yield takeLatest(GET_INVOICE_DRAFT_ITEM_ITEM, getInvoiceDraftSubItemWorker);
  yield takeLatest(SET_FILTER_INVOICE_DRAFT_ITEM_LIST, setFilterInvoiceDraftItemListWorker);
  yield takeLatest(ADD_INVOICE_DRAFT_ITEM_ITEM, addInvoiceDraftSubItemWorker);
  yield takeLatest(UPDATE_INVOICE_DRAFT_ITEM_ITEM, updateInvoiceDraftSubItemWorker);
  yield takeLatest(DELETE_INVOICE_DRAFT_ITEM_ITEM, deleteInvoiceDraftSubItemWorker);
}
