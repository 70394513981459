/**
 * The event send to the store
 */

import { ActionSaga } from 'src/types/saga.types';
import { Auth, LoginAuth, UserAuth } from 'src/types/user.types';
import { User, UserUpdatePasswordBody, UserUpdateProfileBody } from '../user/types';
import { AuthenticationType, AuthUI, SetAuthUiVisiblePayload } from './types';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR';

export const LOGOUT = 'LOGOUT';

export const SET_AUTH_UI_VISIBLE = 'SET_AUTH_UI_VISIBLE';

// function to help quickly call in dispatch
export const loginAction = (payload: LoginAuth): ActionSaga<LoginAuth> => {
  return {
    type: LOGIN,
    payload: { ...payload },
  };
};

export const loginSuccessAction = (payload: Auth): ActionSaga<Auth> => {
  return {
    type: LOGIN_SUCCESS,
    payload: { ...payload },
  };
};

export const loginErrorAction = (): ActionSaga => {
  return {
    type: LOGIN_ERROR,
  };
};

export const logoutAction = (): ActionSaga => {
  return {
    type: LOGOUT,
  };
};

export const getCurrentUser = (): ActionSaga => {
  return {
    type: GET_CURRENT_USER,
  };
};

export const getCurrentUserSuccess = (payload: { user: User }): ActionSaga<{ user: User }> => {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    payload: { ...payload },
  };
};

export const getCurrentUserError = (): ActionSaga => {
  return {
    type: GET_CURRENT_USER_ERROR,
  };
};

export const setAuthUiVisible = (
  payload: SetAuthUiVisiblePayload
): ActionSaga<AuthUI<SetAuthUiVisiblePayload>> => {
  return {
    type: SET_AUTH_UI_VISIBLE,
    payload: { ui: { ...payload } },
  };
};

export const updatePasswordAction = (
  payload: UserUpdatePasswordBody
): ActionSaga<UserUpdatePasswordBody> => {
  return {
    type: UPDATE_PASSWORD,
    payload: { ...payload },
  };
};

export const updatePasswordSuccessAction = (payload: User): ActionSaga<User> => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: { ...payload },
  };
};

export const updatePasswordErrorAction = (): ActionSaga => {
  return {
    type: UPDATE_PASSWORD_ERROR,
  };
};

export const updateProfileAction = (
  payload: UserUpdateProfileBody
): ActionSaga<UserUpdateProfileBody> => {
  return {
    type: UPDATE_PROFILE,
    payload: { ...payload },
  };
};

export const updateProfileSuccessAction = (payload: { user: User }): ActionSaga<{ user: User }> => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: { ...payload },
  };
};

export const updateProfileErrorAction = (): ActionSaga => {
  return {
    type: UPDATE_PROFILE_ERROR,
  };
};
