import { useMemo } from 'react';
import { useAppSelector } from 'src/define/store-hooks.interface';
import { checkHasPermission } from 'src/helpers/common.helpers';

export default function useUserPermissions() {
  const { user } = useAppSelector((s) => s.authentication);

  const result = useMemo(() => {
    return {
      canViewTruckCart: checkHasPermission(
        ['view truck cart', 'view truck cart for everyone'],
        user?.permissions
      ),
      canLoadMore: checkHasPermission(['load in', 'load in for everyone'], user?.permissions),
      canViewLoadIn: checkHasPermission(
        ['view load in', 'view load in for everyone'],
        user?.permissions
      ),
      canViewLogLoadIn: checkHasPermission(
        ['view history load in', 'view history load in for everyone'],
        user?.permissions
      ),
      canRevertLoadIn: checkHasPermission(
        ['revert load in', 'revert load in for everyone'],
        user?.permissions
      ),
      canViewHistoryByUser: checkHasPermission(['view history load in'], user?.permissions),
      canViewHistoryByEveryone: checkHasPermission(
        ['view history load in for everyone'],
        user?.permissions
      ),
      canViewUnload: checkHasPermission(
        ['view unload', 'view unload for everyone'],
        user?.permissions
      ),
      canEditUnload: checkHasPermission(['unload', 'everyone'], user?.permissions),
      canRevertUnload: checkHasPermission(
        ['revert unload', 'revert unload for everyone'],
        user?.permissions
      ),
      canSendBackLoadIn: checkHasPermission(['send back to load in'], user?.permissions),
      canCreateSaleOrder: checkHasPermission(
        ['create sale order', 'create sale order for everyone'],
        user?.permissions
      ),
      canViewInvoice: checkHasPermission(
        ['view invoice', 'view invoice for everyone'],
        user?.permissions
      ),
      isInvoiceManager: checkHasPermission(['view invoice for everyone'], user?.permissions),
      canUpdateInvoice: checkHasPermission(
        ['update invoice', 'update invoice for everyone'],
        user?.permissions
      ),
      canCreateRequestOrder: checkHasPermission(
        ['create request order', 'create request order for everyone'],
        user?.permissions
      ),
      canUpdateRequestOrder: checkHasPermission(
        ['update request order', 'update request order for everyone'],
        user?.permissions
      ),
      canViewRequestOrderForEveryone: checkHasPermission(
        ['view request order for everyone'],
        user?.permissions
      ),
      canViewRequestOrderForMe: checkHasPermission(['view request order'], user?.permissions),
      canCreateWarehouse: checkHasPermission(
        ['create warehouse', 'create warehouse for everyone'],
        user?.permissions
      ),
      canDeleteWarehouse: checkHasPermission(
        ['delete warehouse', 'delete warehouse for everyone'],
        user?.permissions
      ),
      canCreateCustomerReturn: checkHasPermission(
        ['create customer return', 'create customer return for everyone'],
        user?.permissions
      ),
      canDeleteCustomerReturn: checkHasPermission(
        ['delete customer return', 'delete customer return for everyone'],
        user?.permissions
      ),
      canCreateInventory: checkHasPermission(['create inventory'], user?.permissions),
      canUpdateInventory: checkHasPermission(['update inventory'], user?.permissions),
      canViewInventory: checkHasPermission(['view inventory'], user?.permissions),
      canUpdateConfig: checkHasPermission(['update config'], user?.permissions),
      canCreateConfig: checkHasPermission(['create config'], user?.permissions),
      canImportConfig: checkHasPermission(['import config'], user?.permissions),
      canViewBalanceSheet: checkHasPermission(['view balance sheet'], user?.permissions),
      canViewBalanceSheetEveryone: checkHasPermission(
        ['view balance sheet for everyone'],
        user?.permissions
      ),
      canUpdateCustomer: checkHasPermission(['update customer'], user?.permissions),
      canCreateCustomer: checkHasPermission(['create customer'], user?.permissions),
      canViewCustomer: checkHasPermission(['view customer'], user?.permissions),
      canCompleteInvoice: checkHasPermission(['complete invoice'], user?.permissions),
      canRevertCompleteInvoice: checkHasPermission(['revert complete invoice'], user?.permissions),
      canChangePaymentInvoice: checkHasPermission(['change payment invoice'], user?.permissions),
      canExport: checkHasPermission(['export table'], user?.permissions),
      canViewReport: checkHasPermission(['view report'], user?.permissions),
      canPrintTemplate: checkHasPermission(['print template'], user?.permissions),
      canViewUsers: checkHasPermission(['view users'], user?.permissions),
      canUpdateUsers: checkHasPermission(['update users'], user?.permissions),
      canAddUsers: checkHasPermission(['add users'], user?.permissions),
      canViewTrucks: checkHasPermission(['view trucks'], user?.permissions),
      canUpdateTrucks: checkHasPermission(['update trucks'], user?.permissions),
      canAddTrucks: checkHasPermission(['add trucks'], user?.permissions),
      canViewCategories: checkHasPermission(['view categories'], user?.permissions),
      canUpdateCategories: checkHasPermission(['update categories'], user?.permissions),
      canAddCategories: checkHasPermission(['add categories'], user?.permissions),
      canViewCreditLimit: checkHasPermission(['view credit limit'], user?.permissions),
      canUpdateCreditLimit: checkHasPermission(['update credit limit'], user?.permissions),
      canViewSettings: checkHasPermission(['view settings'], user?.permissions),
      canUpdateSettings: checkHasPermission(['update settings'], user?.permissions),
      canViewPreInvoices: checkHasPermission(['view pre-invoices'], user?.permissions),
      canUpdatePreInvoices: checkHasPermission(['update pre-invoices'], user?.permissions),
      canAddPreInvoices: checkHasPermission(['add pre-invoices'], user?.permissions),
      canUpdatePermissions: checkHasPermission(['update permissions'], user?.permissions),
      canCheckPayment: checkHasPermission(['check payments'], user?.permissions),
      canSendSMSInvoice: checkHasPermission(['send sms invoice'], user?.permissions),
    };
  }, [user?.permissions]);

  return {
    ...result,
    canCompleteLoadIn: result.canLoadMore,
    canCompleteUnload: result.canEditUnload,
    canUpdateWarehouse: result.canCreateWarehouse,
    canUpdateCustomerReturn: result.canCreateCustomerReturn,
  };
}
