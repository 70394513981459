/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';

import { sortByField } from 'src/helpers/common.helpers';
import {
  createCustomerReturnAPI,
  deleteCustomerReturnAPI,
  getListCustomerReturnAPI,
  getCustomerReturnAPI,
  updateCustomerReturnAPI,
} from './apis';
import {
  createCustomerReturnSuccess,
  deleteCustomerReturnSuccess,
  getListCustomerReturnSuccess,
  getCustomerReturnSuccess,
  updateCustomerReturnSuccess,
  customerReturnError,
} from './actions';
import { CustomerReturnBody } from './types';

const module = 'Customer Return';
const root = 'customer-returns';

export function* getListCustomerReturnWORKER({ payload }: ActionSaga<CustomerReturnBody>) {
  try {
    const { data }: SagaReturnType<typeof getListCustomerReturnAPI> = yield call(
      getListCustomerReturnAPI,
      payload
    );
    yield put(getListCustomerReturnSuccess(data.data));
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(customerReturnError());
  }
}

export function* getCustomerReturnWORKER({ payload }: ActionSaga<CustomerReturnBody>) {
  try {
    if (!payload?.id) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof getCustomerReturnAPI> = yield call(
      getCustomerReturnAPI,
      payload
    );
    yield put(getCustomerReturnSuccess(data.data));
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(customerReturnError());
  }
}

export function* createCustomerReturnWORKER({ payload }: ActionSaga<CustomerReturnBody>) {
  try {
    if (!payload) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof createCustomerReturnAPI> = yield call(
      createCustomerReturnAPI,
      payload
    );
    yield put(createCustomerReturnSuccess(data.data));
    showSuccessToast(`Create ${module} successfully`);
    window.location.replace(`#/${root}/${data.data?.id}`);
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(customerReturnError());
  }
}

export function* updateCustomerReturnWORKER({ payload }: ActionSaga<CustomerReturnBody>) {
  try {
    if (!payload?.id) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof updateCustomerReturnAPI> = yield call(
      updateCustomerReturnAPI,
      payload
    );
    yield put(updateCustomerReturnSuccess(data.data));
    showSuccessToast(`Update ${module} successfully`);
    if (payload?.callback) {
      payload.callback(data.data);
    }
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(customerReturnError());
  }
}

export function* deleteCustomerReturnWORKER({ payload }: ActionSaga<CustomerReturnBody>) {
  try {
    if (!payload?.id) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof deleteCustomerReturnAPI> = yield call(
      deleteCustomerReturnAPI,
      payload
    );
    yield put(deleteCustomerReturnSuccess());
    showSuccessToast(`Delete ${module} successfully`);
    window.location.replace(`#/${root}`);
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(customerReturnError());
  }
}
