import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import { Setting, SettingAddBody, SettingParams } from './types';

const rootAPI = 'settings';

export const getSettingListAPI = (params?: SettingParams) => {
  return axiosQuery.get<ResponseStructure<Setting[]>>(rootAPI, {
    params: {
      ...params?.filterable,
      ...params?.pagination,
    },
  });
};

export const updateSettingAPI = (item: SettingAddBody) => {
  return axiosQuery.post<ResponseStructure<Setting>>(rootAPI + '/' + item.id, {
    _method: 'PATCH',
    ...item,
  });
};
