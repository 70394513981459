/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { Dashboard } from './types';

export const getDashboard = createAction('dashboard/getDashboard');
export const getDashboardSuccess = createAction<Dashboard>('dashboard/getDashboard/success');
export const getDashboardError = createAction('dashboard/getDashboard/error');
