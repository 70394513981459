import { takeLatest } from 'redux-saga/effects';
import {
  getSettingDefaultPhone,
  getSettingGlobalTax,
  getSettingList,
  updateSetting,
} from './actions';
import {
  getDefaultPhoneWorker,
  getGlobalTaxItemWorker,
  getSettingListWorker,
  updateSettingWorker,
} from './workers';

function* settingSaga() {
  yield takeLatest(getSettingList, getSettingListWorker);
  yield takeLatest(getSettingGlobalTax, getGlobalTaxItemWorker);
  yield takeLatest(getSettingDefaultPhone, getDefaultPhoneWorker);
  yield takeLatest(updateSetting, updateSettingWorker);
}

export default settingSaga;
