import { SagaReturnType, call, put } from 'redux-saga/effects';
import { showErrorToast } from 'src/helpers/toast.helpers';
import { getListPermissionSuccess, getListRoleSuccess, permissionError } from './actions';
import { getListPermissionAPI, getListRoleAPI } from './apis';

export function* getListPermissionWorker() {
  try {
    const { data: resultPermission }: SagaReturnType<typeof getListPermissionAPI> = yield call(
      getListPermissionAPI
    );
    const { data: resultRole }: SagaReturnType<typeof getListRoleAPI> = yield call(getListRoleAPI);
    yield put(getListPermissionSuccess(resultPermission.data));
    yield put(getListRoleSuccess(resultRole.data));
  } catch (e) {
    showErrorToast();
    console.error(e);
    yield put(permissionError);
  }
}
