/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import { getInvoiceDraftItem, removeInvoiceDraftItemToInvoiceDraft } from '../invoiceDraft/actions';
import {
  addInvoiceDraftSubItemError,
  addInvoiceDraftSubItemSuccess,
  clearInvoiceDraftItemList,
  deleteInvoiceDraftSubItemError,
  deleteInvoiceDraftSubItemSuccess,
  getInvoiceDraftSubItemError,
  getInvoiceDraftSubItemSuccess,
  getInvoiceDraftItemListError,
  getInvoiceDraftItemListSuccess,
  updateInvoiceDraftSubItemError,
  updateInvoiceDraftSubItemSuccess,
} from './actions';
import {
  addInvoiceDraftSubItemAPI,
  deleteInvoiceDraftSubItemAPI,
  getInvoiceDraftSubItemAPI,
  getInvoiceDraftItemListAPI,
  updateInvoiceDraftSubItemAPI,
} from './apis';
import {
  InvoiceDraftItemAddBody,
  InvoiceDraftSubItemPayload,
  InvoiceDraftItemListParams,
  InvoiceDraftItemUpdateBody,
  InvoiceDraftItemDeleteBody,
} from './types';
import { increaseKeyCurrentUser } from '../currentUser/actions';

export function* getInvoiceDraftItemListWorker(action: ActionSaga<InvoiceDraftItemListParams>) {
  try {
    const { data }: SagaReturnType<typeof getInvoiceDraftItemListAPI> = yield call(
      getInvoiceDraftItemListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getInvoiceDraftItemListSuccess({
        invoiceDraftItems: data.data,
        pagination: newPagination,
      })
    );
  } catch {
    yield put(getInvoiceDraftItemListError());
  }
}

export function* setFilterInvoiceDraftItemListWorker(
  action: ActionSaga<InvoiceDraftItemListParams>
) {
  yield put(clearInvoiceDraftItemList());
  yield call(getInvoiceDraftItemListWorker, action);
}

export function* getInvoiceDraftSubItemWorker(action: ActionSaga<InvoiceDraftSubItemPayload>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getInvoiceDraftSubItemAPI> = yield call(
        getInvoiceDraftSubItemAPI,
        action.payload
      );
      yield put(getInvoiceDraftSubItemSuccess({ invoiceDraftItem: data.data }));
    }
  } catch {
    yield put(getInvoiceDraftSubItemError());
    showErrorToast();
  }
}

export function* addInvoiceDraftSubItemWorker(action: ActionSaga<InvoiceDraftItemAddBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof addInvoiceDraftSubItemAPI> = yield call(
        addInvoiceDraftSubItemAPI,
        action.payload
      );
      yield put(addInvoiceDraftSubItemSuccess({ invoiceDraftItem: data.data }));
      yield put(increaseKeyCurrentUser());

      if (action.payload.invoice_draft_id) {
        yield put(getInvoiceDraftItem({ id: action.payload.invoice_draft_id }));
      }

      if (action.payload.callback) {
        action.payload.callback(data.data);
      }

      showSuccessToast();
    }
  } catch (e) {
    yield put(addInvoiceDraftSubItemError());
    showErrorToast();
  }
}

export function* updateInvoiceDraftSubItemWorker(action: ActionSaga<InvoiceDraftItemUpdateBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateInvoiceDraftSubItemAPI> = yield call(
        updateInvoiceDraftSubItemAPI,
        action.payload
      );
      yield put(updateInvoiceDraftSubItemSuccess({ invoiceDraftItem: data.data }));
      if (action.payload?.callback) {
        action.payload.callback(data.data);
      }
      showSuccessToast();
    }
  } catch {
    yield put(updateInvoiceDraftSubItemError());
    showErrorToast();
  }
}

export function* deleteInvoiceDraftSubItemWorker(action: ActionSaga<InvoiceDraftItemDeleteBody>) {
  try {
    if (action.payload) {
      const { invoice_draft_id, id } = action.payload;
      const { data }: SagaReturnType<typeof deleteInvoiceDraftSubItemAPI> = yield call(
        deleteInvoiceDraftSubItemAPI,
        action.payload
      );
      yield put(deleteInvoiceDraftSubItemSuccess({ invoiceDraftItem: { ...data.data, id } }));
      yield put(removeInvoiceDraftItemToInvoiceDraft({ id }));

      if (invoice_draft_id) {
        yield put(getInvoiceDraftItem({ id: invoice_draft_id }));
      }

      if (action.payload?.callback) {
        action.payload.callback();
      }
      showSuccessToast();
    }
  } catch {
    yield put(deleteInvoiceDraftSubItemError());
    showErrorToast();
  }
}
