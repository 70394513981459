import { takeLatest } from 'redux-saga/effects';
import { getCurrentUserTruckCart } from './actions';
import { getCurrentUserTruckCartWorker } from './workers';

/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */
export function* currentUserSaga() {
  yield takeLatest(getCurrentUserTruckCart, getCurrentUserTruckCartWorker);
}
