import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  addToTruckRequestOrder,
  createInvoiceRequestOrder,
  createRequestOrder,
  deleteRequestOrder,
  getRequestOrderItem,
  getRequestOrderManagerList,
  getRequestOrderPersonalList,
  revertInvoiceRequestOrder,
  revertTruckRequestOrder,
  updateRequestOrder,
} from './actions';
import {
  addToTruckRequestOrderWorker,
  createInvoiceRequestOrderWorker,
  createRequestOrderWorker,
  deleteRequestOrderWorker,
  getRequestOrderItemWorker,
  getRequestOrderManagerListWorker,
  getRequestOrderPersonalListWorker,
  revertInvoiceRequestOrderWorker,
  revertTruckRequestOrderWorker,
  updateRequestOrderWorker,
} from './workers';

/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */
export function* requestOrderSaga() {
  yield takeLatest(getRequestOrderManagerList, getRequestOrderManagerListWorker);
  yield takeLatest(getRequestOrderPersonalList, getRequestOrderPersonalListWorker);
  yield takeEvery(getRequestOrderItem, getRequestOrderItemWorker);
  yield takeLatest(updateRequestOrder, updateRequestOrderWorker);
  yield takeLatest(createRequestOrder, createRequestOrderWorker);
  yield takeLatest(deleteRequestOrder, deleteRequestOrderWorker);
  yield takeLatest(addToTruckRequestOrder, addToTruckRequestOrderWorker);
  yield takeLatest(revertTruckRequestOrder, revertTruckRequestOrderWorker);
  yield takeLatest(createInvoiceRequestOrder, createInvoiceRequestOrderWorker);
  yield takeLatest(revertInvoiceRequestOrder, revertInvoiceRequestOrderWorker);
}
