// THIS IS A VALIDATION OF SOFTWARE

import { isStrOnlyNumber } from 'src/helpers/string.helpers';

/**
 * Validate the zip code
 */
export function validateZipCode(zipCode?: string | null): boolean {
  if (!zipCode) return true;
  return zipCode?.length === 5 || zipCode === undefined;
}

/**
 * Validate the state
 */
export function validateState(state?: string | null): boolean {
  if (!state) return true;
  return state?.length === 2 || state === undefined;
}

/**
 * Validate the phone number
 */
export function validatePhoneNumber(phoneNumber?: string | null): boolean {
  if (!phoneNumber) return true;
  return (phoneNumber && isStrOnlyNumber(phoneNumber) && phoneNumber.length === 10) ||
    phoneNumber === undefined
    ? true
    : false;
}

/**
 * Validate greater than or equal to 0
 */
export function validateGreaterOrEqualThanZero(value?: number | null | string): boolean {
  if (!value) return true;
  return (value !== undefined && ((value !== null && +value >= 0) || value === null)) ||
    value === undefined
    ? true
    : false;
}

/**
 * Validate barcode format EAN13
 */
export function validateBarcodeFormatEAN13(ean: string): boolean {
  function testChecksum(ean) {
    const digits = ean.slice(0, -1);
    const checkDigit = ean.slice(-1) | 0;
    let sum = 0;
    for (let i = digits.length - 1; i >= 0; i--) {
      sum += (digits.charAt(i) * (1 + 2 * (i % 2))) | 0;
    }
    sum = (10 - (sum % 10)) % 10;
    return sum === checkDigit;
  }
  ean = String(ean);
  const isValidLength = ean.length === 13;

  return isValidLength && /^\d+$/.test(ean) && testChecksum(ean);
}

/**
 * Validate barcode format UPC
 */
export function validateBarcodeFormatUPC(code: string): boolean {
  if (!code) return true;
  return code.length === 12 && isStrOnlyNumber(code) && code[0] === '0';
}
