import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const { REACT_APP_HOST, REACT_APP_STORED } = process.env;

const host = window.location.host;
const currentHost =
  host === 'tl-frontend-2.wrewardspos.com'
    ? 'https://tl-backend-2.wrewardspos.com'
    : REACT_APP_HOST;

const config: AxiosRequestConfig = {
  baseURL: currentHost + '/api',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${REACT_APP_STORED ? localStorage.getItem(REACT_APP_STORED) : ''}`,
  },
};

export const axiosQuery = axios.create(config);

axiosQuery.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

axiosQuery.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('truck-retailer');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);
