import React, { useMemo } from 'react';
import { useAppSelector } from 'src/define/store-hooks.interface';
import { checkHasPermission } from './services';

type CheckPermissionProps = {
  children: React.ReactElement;
  name: string;
};

const CheckPermission: React.FC<CheckPermissionProps> = ({ children, name }) => {
  const { user } = useAppSelector((s) => s.authentication);
  const { permissions } = user ?? {};
  const hasPermission = useMemo(
    () => (permissions ? checkHasPermission(name, permissions) : false),
    [permissions, name]
  );

  if (!user?.id) {
    return <>{children}</>;
  }

  if (!hasPermission) {
    window.location.replace('#/401');
    return null;
  }

  return <>{children}</>;
};

export default React.memo(CheckPermission);
