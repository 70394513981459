import { takeLatest } from 'redux-saga/effects';
import {
  createWarehouseReturnItem,
  deleteWarehouseReturnItem,
  updateWarehouseReturnItem,
} from './actions';
import {
  createWarehouseReturnItemWORKER,
  deleteWarehouseReturnItemWORKER,
  updateWarehouseReturnItemWORKER,
} from './workers';

/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */
export function* warehouseReturnItemSaga() {
  yield takeLatest(createWarehouseReturnItem, createWarehouseReturnItemWORKER);
  yield takeLatest(updateWarehouseReturnItem, updateWarehouseReturnItemWORKER);
  yield takeLatest(deleteWarehouseReturnItem, deleteWarehouseReturnItemWORKER);
}
