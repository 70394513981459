/**
 * The action send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { TruckCartAddBody } from '../truckCart/types';
import { TruckDelivery, TruckDeliveryBody, TruckDeliveryParams } from './types';

export const getCurrentTruckDelivery = createAction<TruckDeliveryParams>(
  'truckDeliveries/getCurrentTruckDelivery'
);
export const getCurrentTruckDeliverySuccess = createAction<TruckDelivery>(
  'truckDeliveries/getCurrentTruckDelivery/success'
);
export const getCurrentTruckDeliveryError = createAction(
  'truckDeliveries/getCurrentTruckDelivery/error'
);

// CREATE
export const createTruckDelivery = createAction<TruckDeliveryBody>(
  'truckDeliveries/createTruckDelivery'
);
export const createTruckDeliveryAndAddInventory = createAction<
  TruckDeliveryBody & { truckCart: TruckCartAddBody }
>('truckDeliveries/createTruckDeliveryAndAddInventory');
export const createTruckDeliverySuccess = createAction<TruckDelivery>(
  'truckDeliveries/createTruckDelivery/success'
);
export const createTruckDeliveryError = createAction('truckDeliveries/createTruckDelivery/error');

// UPDATE
export const updateTruckDelivery = createAction<TruckDeliveryBody & { id: string }>(
  'truckDeliveries/updateTruckDelivery'
);
export const updateTruckDeliverySuccess = createAction<TruckDelivery>(
  'truckDeliveries/updateTruckDelivery/success'
);
export const updateTruckDeliveryError = createAction('truckDeliveries/updateTruckDelivery/error');

// DELETE
export const deleteTruckDelivery = createAction<{ id: string }>(
  'truckDeliveries/deleteTruckDelivery'
);
export const deleteTruckDeliverySuccess = createAction(
  'truckDeliveries/deleteTruckDelivery/success'
);

// OTHER

export const deleteTruckDeliveryError = createAction('truckDeliveries/deleteTruckDelivery/error');
