/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { getDashboardError, getDashboardSuccess } from './actions';
import { getDashboardAPI } from './apis';

export function* getDashboardWorker() {
  try {
    const { data }: SagaReturnType<typeof getDashboardAPI> = yield call(getDashboardAPI);
    yield put(getDashboardSuccess(data.data));
  } catch (e) {
    console.error(e);
    yield put(getDashboardError);
  }
}
