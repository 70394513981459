import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { InvoicePaymentReducer } from './types';
import {
  createInvoicePayment,
  createInvoicePaymentSuccess,
  deleteInvoicePayment,
  deleteInvoicePaymentSuccess,
  invoicePaymentError,
} from './actions';

const initialState: InvoicePaymentReducer = {};

const invoicePaymentReducer = createReducer(initialState, (builder) => {
  builder.addCase(invoicePaymentError, (state) => {
    state.ui = {};
  });
  builder.addMatcher(isAnyOf(createInvoicePayment, deleteInvoicePayment), (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addMatcher(isAnyOf(createInvoicePaymentSuccess, deleteInvoicePaymentSuccess), (state) => {
    state.ui = { ...state.ui, loadingBtn: false };
  });
});

export default invoicePaymentReducer;
