/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import { LoadOut, LoadOutBody, LoadOutInventoriesParams } from './types';

const name = 'load-out-inventories';

export const getLoadOutInventoriesAPI = (action: LoadOutInventoriesParams) => {
  return axiosQuery.get<ResponseStructure<LoadOut[]>>(name + '/' + action.userId, {
    params: action,
  });
};

export const createLoadOutInventoriesAPI = (payload: LoadOutBody) => {
  return axiosQuery.post<ResponseStructure<LoadOut>>(name, payload);
};

export const updateLoadOutInventoriesAPI = (payload: LoadOutBody) => {
  return axiosQuery.post<ResponseStructure<LoadOut>>(name + '/' + payload?.id, {
    ...payload,
    _method: 'PATCH',
  });
};
