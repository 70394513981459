import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/define/store-hooks.interface';

export const useCanAccessConfig = () => {
  const [value, setValue] = useState<boolean | undefined>(undefined);
  const { user, loading } = useAppSelector((s) => s.authentication);

  useEffect(() => {
    if (!loading) {
      setValue(
        user &&
          user.permissions &&
          user.permissions.findIndex((permission) => permission.name === 'view config') >= 0
          ? true
          : false
      );
    }
  }, [user, loading]);

  return { canConfig: value, loading };
};
