import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import { CButtonProps } from '@coreui/react/dist/components/button/CButton';
import { CModalProps } from '@coreui/react/dist/components/modal/CModal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonLoading from '../button/ButtonLoading';

type SubmitModalProps = CModalProps & {
  headerTitle?: string | React.ReactNode;
  content?: React.ReactNode;
  warning?: React.ReactNode;
  okText?: string;
  okProps?: CButtonProps & { loading?: boolean };
  hideForm?: boolean;
  onSubmit?: () => void;
};

const SubmitModal: React.FC<SubmitModalProps> = (props) => {
  const {
    visible,
    headerTitle,
    content,
    okText,
    okProps,
    hideForm,
    warning,
    onClose,
    onSubmit,
    ...rest
  } = props;
  const { t } = useTranslation();

  return (
    <CModal visible={visible} backdrop="static" onClose={onClose} {...rest}>
      <CModalHeader>
        <CModalTitle>{headerTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {!hideForm && content}
        {hideForm && warning}
      </CModalBody>

      <CModalFooter>
        <CButton color="link" onClick={onClose}>
          {t('close')}
        </CButton>
        {!hideForm && (
          <ButtonLoading color="primary" type="submit" {...okProps} onClick={onSubmit}>
            <i className="icofont-verification-check" style={{ marginRight: 5 }}></i>
            {okText || t('save')}
          </ButtonLoading>
        )}
      </CModalFooter>
    </CModal>
  );
};

export default React.memo(SubmitModal);
