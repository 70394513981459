/**
 * The pure functions to get current state, work the action and return new a state
 */

import { InitialState } from 'src/define/layout.interface';
import { ActionSaga } from 'src/types/saga.types';
import { SET_TOGGLE_LAYOUT } from './actions';

const initialState: InitialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
};

const layoutFromLocalStorage = JSON.parse(localStorage.getItem('layout') as string);

const layoutReducer = (
  state: InitialState = layoutFromLocalStorage || initialState,
  actions: ActionSaga<InitialState>
) => {
  const { type, ...payload } = actions;
  switch (type) {
    case SET_TOGGLE_LAYOUT:
      localStorage.setItem('layout', JSON.stringify({ ...state, ...payload }));
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default layoutReducer;
