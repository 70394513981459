/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { TruckCart } from '../truckCart/types';

export const getCurrentUserTruckCart = createAction<{ truck_delivery_id: string }>(
  'currentUser/getCurrentUserTruckCart'
);
export const getCurrentUserTruckCartSuccess = createAction<TruckCart[]>(
  'currentUser/getCurrentUserTruckCart/success'
);
export const increaseKeyCurrentUser = createAction('currentUser/increaseKeyCurrentUser');

export const currentUserError = createAction('currentUser/currentUserError');
