/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { ActionSaga } from 'src/types/saga.types';
import {
  addTruckItemError,
  addTruckItemSuccess,
  clearTruckList,
  deleteTruckItemError,
  deleteTruckItemSuccess,
  getTruckItemError,
  getTruckItemSuccess,
  getTruckListError,
  getTruckListSuccess,
  updateTruckItemError,
  updateTruckItemSuccess,
} from './actions';
import {
  addTruckItemAPI,
  deleteTruckItemAPI,
  getTruckItemAPI,
  getTruckListAPI,
  updateTruckItemAPI,
} from './apis';
import {
  TruckAddBody,
  TruckDeleteBody,
  TruckItemPayload,
  TruckListParams,
  TruckUpdateBody,
} from './types';

export function* getTruckListWorker(action: ActionSaga<TruckListParams>) {
  try {
    const { data }: SagaReturnType<typeof getTruckListAPI> = yield call(
      getTruckListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getTruckListSuccess({
        trucks: data.data,
        pagination: newPagination,
      })
    );
  } catch {
    yield put(getTruckListError());
  }
}

export function* setFilterTruckListWorker(action: ActionSaga<TruckListParams>) {
  yield put(clearTruckList());
  yield call(getTruckListWorker, action);
}

export function* getTruckItemWorker(action: ActionSaga<TruckItemPayload>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getTruckItemAPI> = yield call(
        getTruckItemAPI,
        action.payload
      );
      yield put(getTruckItemSuccess({ truck: data.data }));
    }
  } catch {
    yield put(getTruckItemError());
  }
}

export function* addTruckItemWorker(action: ActionSaga<TruckAddBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof addTruckItemAPI> = yield call(
        addTruckItemAPI,
        action.payload
      );
      yield put(addTruckItemSuccess({ truck: data.data }));
    }
  } catch (e) {
    yield put(addTruckItemError());
  }
}

export function* addMultipleTruckItemWorker(action: ActionSaga<TruckAddBody>) {
  for (let i = 10; i <= 500; i++) {
    try {
      if (action.payload) {
        const { data }: SagaReturnType<typeof addTruckItemAPI> = yield call(addTruckItemAPI, {
          name: action.payload.name + '_' + i,
        });
        yield put(addTruckItemSuccess({ truck: data.data }));
      }
    } catch {
      yield put(addTruckItemError());
    }
  }
}

export function* updateTruckItemWorker(action: ActionSaga<TruckUpdateBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateTruckItemAPI> = yield call(
        updateTruckItemAPI,
        action.payload
      );
      yield put(updateTruckItemSuccess({ truck: data.data }));
    }
  } catch {
    yield put(updateTruckItemError());
  }
}

export function* deleteTruckItemWorker(action: ActionSaga<TruckDeleteBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof deleteTruckItemAPI> = yield call(
        deleteTruckItemAPI,
        action.payload
      );
      yield put(deleteTruckItemSuccess({ truck: { ...data.data, id: action.payload.id } }));
    }
  } catch {
    yield put(deleteTruckItemError());
  }
}

// logic CRUD flow
// link tham khảo: https://redux-saga.js.org/docs/advanced/NonBlockingCalls
