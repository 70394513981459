/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { AppUI } from 'src/types/common.types';
import { LoadOut, LoadOutBody, LoadOutInventoriesParams } from './types';

// list
export const getLoadOutInventories = createAction<LoadOutInventoriesParams>(
  'load-out/getLoadOutInventories'
);
export const getLoadOutInventoriesSuccess = createAction<LoadOut[]>(
  'load-out/getLoadOutInventories/success'
);
export const getLoadOutInventoriesError = createAction('load-out/getLoadOutInventories/error');

// create load out inventories
export const createLoadOutInventories = createAction<LoadOutBody>(
  'load-out/createLoadOutInventories'
);
export const createLoadOutInventoriesSuccess = createAction<LoadOut>(
  'load-out/createLoadOutInventories/success'
);
export const createLoadOutInventoriesError = createAction(
  'load-out/createLoadOutInventories/error'
);

// update load out inventories
export const updateLoadOutInventories = createAction<LoadOutBody>(
  'load-out/updateLoadOutInventories'
);
export const updateLoadOutInventoriesSuccess = createAction<LoadOut>(
  'load-out/updateLoadOutInventories/success'
);
export const updateLoadOutInventoriesError = createAction(
  'load-out/updateLoadOutInventories/error'
);

// Load ou UI
export const setLoadOutUI = createAction<AppUI>('load-out/set-ui');
