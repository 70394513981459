import dayjs from 'dayjs';
import { orderBy } from 'lodash';
import { Customer } from 'src/features/customer/types';
import { Permission } from 'src/features/permission/types';
import { getPaymentStatusInvoice } from 'src/views/dashboard/invoice/helpers';

export const convertToCurrency = (value?: number | null, type: string = 'USD') => {
  return value && +value
    ? (+value).toLocaleString('en-US', {
        style: 'currency',
        currency: type,
      })
    : '$0';
};

export const stringToSlug = (str) => {
  // remove accents
  var from = 'àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ',
    to = 'aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy';
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(RegExp(from[i], 'gi'), to[i]);
  }

  str = str
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\-]/g, '-')
    .replace(/-+/g, '-');

  return str;
};

export const numberToRound = (num?: number, roundTo: number = 2) => {
  // const decimal = Math.pow(10, roundTo);
  // return num && num !== undefined ? Math.round((num + Number.EPSILON) * decimal) / decimal : null;
  return num && num !== undefined ? +num.toFixed(roundTo) : null;
};

export const showDateTime = (str?: string | null, format: string = 'MM/DD/YY') => {
  return str ? dayjs(str).format(format) : '';
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const convertNumberToString = (n?: number | string) => {
  return n ? n.toLocaleString('fullwide', { useGrouping: false }) : '';
};

export const checkIsFormSubmit = (event: React.FormEvent<HTMLFormElement>): boolean => {
  event.preventDefault();
  event.stopPropagation();
  return event.currentTarget.checkValidity();
};

export function formatPhoneNumber(value?: string | null) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  if (!phoneNumber) return undefined;

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

export const revertPhoneNumber = (phoneNumber?: string | null) => {
  const newPhoneNumber = phoneNumber ? phoneNumber.replace(/[^\d]/g, '') : '';
  return newPhoneNumber.slice(0, 10);
};

export function formatPriceNumber(value?: number | null) {
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const newValue = value ? USDollar.format(value) : undefined;
  return newValue;
}

export const revertPriceNumber = (price?: string | null) => {
  return Number(price?.replace(/[^0-9.-]+/g, ''));
};

export const excludeFieldTwoArray = (
  mainArray: Array<any>,
  arrayCompare: Array<any>,
  field: string
) => {
  return mainArray.filter(function (val1) {
    return arrayCompare.filter((val2) => val2?.[field] === val1?.[field])?.length === 0;
  });
};

export const sortByField = <T = any>(
  array: T[],
  field: string[],
  direction: 'asc' | 'desc' = 'desc'
): T[] => {
  return array && Array.isArray(array) ? orderBy(array, field, [direction]) : [];
};

export const filterByField = <T = any>(array: T[], fields: string[], value?: string): T[] => {
  return array && Array.isArray(array)
    ? array?.filter((e) => {
        let isTruth = false;
        for (let i in fields) {
          const field = fields[i];
          isTruth = e?.[field]?.toLowerCase()?.indexOf(value?.toLowerCase()) >= 0;

          if (isTruth) {
            break;
          }
        }

        return isTruth;
      })
    : [];
};

export const getRowTotal = <T = any>(array: T[], totalFields: string[]): T[] => {
  if (array.length > 0) {
    const totalRow = { id: 'total' };

    for (let i in array) {
      const item = array[i];
      for (let j in totalFields) {
        const field = totalFields[j];
        if (!totalRow[field]) {
          totalRow[field] = 0;
        }
        totalRow[field] += +item?.[field] ?? 0;
      }
    }
    return [totalRow as T, ...array];
  }

  return [];
};

export const getTotalRowStyle = (row) => {
  if (row?.id === 'total') {
    return { backgroundColor: '#ffffe0' };
  }

  return {};
};

export const getTotalRowInvColorStyle = (row) => {
  if (row?.id === 'total') {
    return { backgroundColor: '#ffffe0' };
  }

  const paymentStatus = getPaymentStatusInvoice(row);

  if (paymentStatus === 'Success') {
    return { backgroundColor: '#93C47D' };
  }

  if (paymentStatus === 'Cancelled') {
    return { backgroundColor: '#F77777' };
  }

  return { backgroundColor: '#F7E771' };
};

export const getTotalRowWarehouseColorStyle = (row) => {
  if (row?.id === 'total') {
    return { backgroundColor: '#ffffe0' };
  }

  if (row?.status === 'completed') {
    return { backgroundColor: '#93C47D' };
  }

  return { backgroundColor: '#F7E771' };
};

export const arrayEquals = (a, b) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export const generateCodeWithDate = () => {
  const date = new Date();
  return date.getTime().toString(36);
};

export const checkHasPermission = (permissionName: string[], permissions?: Permission[]) => {
  return permissions && permissions.findIndex((e) => permissionName?.indexOf(e.name) >= 0) >= 0
    ? true
    : false;
};

export const checkTotalPriceInvalid = (total_price?: number | null | string): boolean => {
  return (
    typeof total_price === 'object' ||
    typeof total_price === 'undefined' ||
    (typeof total_price === 'number' && total_price < 0) ||
    (typeof total_price === 'string' && +total_price < 0)
  );
};

export const checkQuantityInvalid = (quantity?: number | null | string): boolean => {
  return (
    (typeof quantity === 'number' && quantity <= 0) ||
    (typeof quantity === 'string' && +quantity <= 0) ||
    typeof quantity === 'object' ||
    typeof quantity === 'undefined'
  );
};

export const sumByField = (array: any[], field: string): number => {
  return array ? array.reduce((a, b) => a + (+b?.[field] || 0), 0) : 0;
};

export const parseToJson = (str: string) => {
  let result;
  try {
    result = JSON.parse(str);
  } catch (e) {
    return undefined;
  }
  return result;
};

export const setFilterStorage = (filter: any, property: string): void => {
  const name = 'filter_truck_retailer';
  const getCurrentStr = localStorage.getItem(name);
  const current = parseToJson(getCurrentStr ?? '') || {};
  current[property] = filter;
  localStorage.setItem(name, JSON.stringify(current));
};

export const getFilterStorage = (property: string): object => {
  const name = 'filter_truck_retailer';
  const getCurrentStr = localStorage.getItem(name);
  const current = parseToJson(getCurrentStr ?? '') || {};
  return current?.[property] || {};
};

export const setCachedDataStorage = (response: any, property: string): void => {
  const name = 'cached_data_truck_retailer';
  const getCurrentStr = sessionStorage.getItem(name);
  const current = parseToJson(getCurrentStr ?? '') || {};
  current[property] = response;
  sessionStorage.setItem(name, JSON.stringify(current));
};

export const getCachedDataStorage = (property: string): any => {
  const name = 'cached_data_truck_retailer';
  const getCurrentStr = sessionStorage.getItem(name);
  const current = parseToJson(getCurrentStr ?? '') || {};
  return current?.[property] || {};
};

export const subtractNumber = (a: number, b: number): number => {
  return a - b;
};

export const addPrefixTruckCodeAndId = (code?: string, id?: number | string): string => {
  if (!id) {
    return '';
  }
  return (code ? code + '_' : '') + (id ? id?.toString() : '');
};

export const getCustomerAddress = (customer: Customer) => {
  const addressList: string[] = [];
  const { address, city, state, zipcode } = customer;
  if (address) {
    addressList.push(address);
  }
  if (city) {
    addressList.push(city);
  }
  if (state) {
    addressList.push(state);
  }
  if (zipcode) {
    addressList.push(zipcode);
  }
  return addressList.join(', ');
};

export const checkEmptyObject = (obj: any): boolean => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const getTheFirstCharacter = (text: string) => {
  const split = text.split(' ');
  let result = '';
  split.forEach((t) => {
    result += t.substring(0, 1);
  });

  return result;
};

export const deepClone = (value?: any) => {
  return JSON.parse(JSON.stringify(value));
};

export const chartValueFormatter = (value: number) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(value);
};

export const openNewTab = (href: string) => {
  window.open(href, '_blank', 'rel=noopener noreferrer');
};
