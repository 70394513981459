/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ReducerSaga } from 'src/types/saga.types';
import {
  ADD_TRUCK_CART_ITEM,
  ADD_TRUCK_CART_ITEM_ERROR,
  ADD_TRUCK_CART_ITEM_SUCCESS,
  CLEAR_TRUCK_CART_LIST,
  DELETE_TRUCK_CART_ITEM,
  DELETE_TRUCK_CART_ITEM_ERROR,
  DELETE_TRUCK_CART_ITEM_INSIDE_TRUCK_SUCCESS,
  DELETE_TRUCK_CART_ITEM_SUCCESS,
  GET_MY_TRUCK_CART_LIST_SUCCESS,
  GET_TRUCK_CART_BY_DELIVERY_LIST,
  GET_TRUCK_CART_BY_DELIVERY_LIST_ERROR,
  GET_TRUCK_CART_BY_DELIVERY_LIST_SUCCESS,
  GET_TRUCK_CART_INSIDE_TRUCK_LIST,
  GET_TRUCK_CART_INSIDE_TRUCK_LIST_SUCCESS,
  GET_TRUCK_CART_ITEM,
  GET_TRUCK_CART_ITEM_ERROR,
  GET_TRUCK_CART_ITEM_SUCCESS,
  GET_TRUCK_CART_LIST,
  GET_TRUCK_CART_LIST_ERROR,
  SET_FILTER_TRUCK_CART_INSIDE_TRUCK_LIST,
  SET_FILTER_TRUCK_CART_LIST,
  SET_TRUCK_CART_VISIBLE,
  UPDATE_TRUCK_CART_ITEM,
  UPDATE_TRUCK_CART_ITEM_ERROR,
  UPDATE_TRUCK_CART_ITEM_SUCCESS,
} from './actions';
import { TruckCartReducer } from './types';

const initialState: TruckCartReducer = {
  truckCarts: undefined,
  myTruckCarts: undefined,
};

const increaseKey = (key: number = 0) => {
  return key + 1;
};

const truckCartReducer: ReducerSaga<TruckCartReducer> = (
  state = initialState,
  payload
): TruckCartReducer => {
  const { type, ...rest } = payload;
  switch (type) {
    case GET_TRUCK_CART_LIST:
    case GET_TRUCK_CART_BY_DELIVERY_LIST:
    case GET_TRUCK_CART_INSIDE_TRUCK_LIST:
    case GET_TRUCK_CART_ITEM:
    case UPDATE_TRUCK_CART_ITEM:
    case DELETE_TRUCK_CART_ITEM:
      return { ...state, ...rest.payload, ui: { loading: true } };

    case ADD_TRUCK_CART_ITEM:
      return { ...state, ...rest.payload, ui: { loadingBtn: true, visible: true } };

    case GET_TRUCK_CART_LIST_ERROR:
    case GET_TRUCK_CART_BY_DELIVERY_LIST_ERROR:
    case GET_TRUCK_CART_ITEM_ERROR:
    case ADD_TRUCK_CART_ITEM_ERROR:
    case UPDATE_TRUCK_CART_ITEM_ERROR:
    case DELETE_TRUCK_CART_ITEM_ERROR:
      showErrorToast();
      return { ...state, ui: { loading: false, loadingPage: false, visible: true } };

    // case GET_TRUCK_CART_LIST_SUCCESS:
    //   const { isPagination } = rest.payload?.pagination || {};
    //   return {
    //     ...state,
    //     ...rest.payload,
    //     truckCarts: isPagination
    //       ? (state.truckCarts || [])?.concat(rest.payload?.truckCarts || [])
    //       : rest.payload?.truckCarts,
    //     loading: false,
    //     loadingBtn: false,
    //   };
    case GET_TRUCK_CART_INSIDE_TRUCK_LIST_SUCCESS:
      const { isPagination } = rest.payload?.pagination || {};
      return {
        ...state,
        ...rest.payload,
        insideTrucks: isPagination
          ? (state.insideTrucks || [])?.concat(rest.payload?.insideTrucks || [])
          : rest.payload?.insideTrucks,
        ui: {
          loading: false,
          loadingBtn: false,
        },
      };
    case GET_TRUCK_CART_BY_DELIVERY_LIST_SUCCESS:
      return {
        ...state,
        ...rest.payload,
        deliveryTruckCarts: rest.payload?.deliveryTruckCarts
          ? [...rest.payload.deliveryTruckCarts]
          : [],
        ui: {
          loading: false,
          loadingBtn: false,
        },
      };
    case GET_MY_TRUCK_CART_LIST_SUCCESS:
      return {
        ...state,
        ...rest.payload,
        myTruckCarts: rest.payload?.myTruckCarts ? [...rest.payload.myTruckCarts] : [],
        ui: {
          loading: false,
          loadingBtn: false,
        },
      };
    case GET_TRUCK_CART_ITEM_SUCCESS:
      return { ...state, ...rest.payload, ui: { loading: false } };
    case ADD_TRUCK_CART_ITEM_SUCCESS:
      showSuccessToast('Add To Truck Cart Success');
      return {
        ...state,
        ...rest.payload,
        myTruckCarts: (rest.payload?.truckCart ? [rest.payload?.truckCart] : []).concat(
          state.myTruckCarts || []
        ),
        deliveryTruckCarts: (rest.payload?.truckCart ? [rest.payload?.truckCart] : []).concat(
          state.deliveryTruckCarts || []
        ),
        ui: {
          loading: false,
          loadingBtn: false,
          visible: false,
          key: increaseKey(state.ui?.key),
        },
      };

    case UPDATE_TRUCK_CART_ITEM_SUCCESS:
      showSuccessToast();
      return {
        ...state,
        ...rest.payload,
        truckCarts: state.truckCarts?.map((e) =>
          e.id === rest.payload?.truckCart?.id ? { ...e, ...rest.payload.truckCart } : e
        ),
        ui: {
          loading: false,
          loadingBtn: false,
        },
      };
    case DELETE_TRUCK_CART_ITEM_SUCCESS:
      showSuccessToast('Delete Truck Cart Success');
      return {
        ...state,
        ...rest.payload,
        myTruckCarts: state.myTruckCarts?.filter((e) => e.id !== rest.payload?.truckCart?.id),
        deliveryTruckCarts: state.deliveryTruckCarts?.filter(
          (e) => e.id !== rest.payload?.truckCart?.id
        ),
        ui: { loading: false, loadingBtn: false },
      };

    case SET_FILTER_TRUCK_CART_LIST:
      return {
        ...state,
        ui: { ...(state?.ui || {}), loading: true },
        filterable: rest.payload?.filterable,
      };
    case SET_FILTER_TRUCK_CART_INSIDE_TRUCK_LIST:
      return {
        ...state,
        ui: { ...(state?.ui || {}), loading: true },
        filterable: rest.payload?.filterable,
      };
    case CLEAR_TRUCK_CART_LIST:
      return { ...state, truckCarts: [] };
    case SET_TRUCK_CART_VISIBLE:
      return { ...state, ui: { ...(state?.ui || {}), ...rest.payload } };
    case DELETE_TRUCK_CART_ITEM_INSIDE_TRUCK_SUCCESS:
      return {
        ...state,
        insideTrucks: state?.insideTrucks?.filter((e) => e.id !== rest?.payload?.mutable?.id),
        ui: {
          ...(state?.ui || {}),
          loading: false,
          loadingBtn: false,
        },
      };

    default:
      return { ...state };
  }
};

export default truckCartReducer;
