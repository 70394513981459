/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  updateCustomerPassword,
  getCustomerList,
  updateCustomerItem,
  addCustomerItem,
  deleteCustomerItem,
  setFilterCustomerList,
  customerImport,
  getCreditLimitList,
  updateCreditLimitItem,
} from './actions';
import {
  addCustomerItemWorker,
  customerImportWorker,
  deleteCustomerItemWorker,
  getCreditLimitListWorker,
  getCustomerListWorker,
  setFilterCustomerListWorker,
  updateCreditLimitItemWorker,
  updateCustomerItemWorker,
  updateCustomerPasswordWorker,
} from './workers';

export function* customerSaga() {
  yield takeLatest(getCustomerList, getCustomerListWorker);
  yield takeLatest(getCreditLimitList, getCreditLimitListWorker);
  yield takeLatest(setFilterCustomerList, setFilterCustomerListWorker);
  yield takeLatest(addCustomerItem, addCustomerItemWorker);
  yield takeLatest(updateCustomerItem, updateCustomerItemWorker);
  yield takeLatest(updateCreditLimitItem, updateCreditLimitItemWorker);
  yield takeLatest(deleteCustomerItem, deleteCustomerItemWorker);
  yield takeLatest(updateCustomerPassword, updateCustomerPasswordWorker);
  yield takeLatest(customerImport, customerImportWorker);
}
