import { combineReducers } from 'redux';
import adminReducer from './features/admin/reducers';
import authenticationReducer from './features/authentication/reducers';
import categoryReducer from './features/category/reducers';
import currentUserReducer from './features/currentUser/reducers';
import customerReducer from './features/customer/reducers';
import { dashboardReducer } from './features/dashboard/reducers';
import giftCardReducer from './features/giftCard/reducers';
import inventoryReducer from './features/inventory/reducers';
import invoiceReducer from './features/invoice/reducers';
import invoiceItemReducer from './features/invoiceItem/reducers';
import layoutReducer from './features/layout/reducers';
import { loadInReducer } from './features/loadIn/reducers';
import { loadOutReducer } from './features/loadOut/reducers';
import requestOrderReducer from './features/requestOrder/reducers';
import settingReducer from './features/setting/reducers';
import truckReducer from './features/truck/reducers';
import truckCartReducer from './features/truckCart/reducers';
import truckDeliveryReducer from './features/truckDeliveries/reducers';
import userReducer from './features/user/reducers';
import requestOrderItemReducer from './features/requestOrderItem/reducers';
import invoicePaymentReducer from './features/invoicePayment/reducers';
import warehouseReturnReducer from './features/warehouseReturn/reducers';
import warehouseReturnItemReducer from './features/warehouseReturnItem/reducers';
import customerReturnReducer from './features/customerReturn/reducers';
import customerReturnItemReducer from './features/customerReturnItem/reducers';
import permissionReducer from './features/permission/reducer';
import invoiceDraftReducer from './features/invoiceDraft/reducers';
import invoiceDraftItemReducer from './features/invoiceDraftItem/reducers';

export const sagaReducers = combineReducers({
  layout: layoutReducer,
  authentication: authenticationReducer,
  truck: truckReducer,
  user: userReducer,
  inventory: inventoryReducer,
  category: categoryReducer,
  customer: customerReducer,
  truckCart: truckCartReducer,
  invoice: invoiceReducer,
  invoiceItem: invoiceItemReducer,
  setting: settingReducer,
  dashboard: dashboardReducer,
  loadIn: loadInReducer,
  loadOut: loadOutReducer,
  giftCard: giftCardReducer,
  truckDelivery: truckDeliveryReducer,
  admin: adminReducer,
  currentUser: currentUserReducer,
  requestOrder: requestOrderReducer,
  requestOrderItem: requestOrderItemReducer,
  invoicePayment: invoicePaymentReducer,
  warehouseReturn: warehouseReturnReducer,
  warehouseReturnItem: warehouseReturnItemReducer,
  customerReturn: customerReturnReducer,
  customerReturnItem: customerReturnItemReducer,
  permission: permissionReducer,
  invoiceDraft: invoiceDraftReducer,
  invoiceDraftItem: invoiceDraftItemReducer,
});
