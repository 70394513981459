import { createReducer } from '@reduxjs/toolkit';
import {
  createDeliveryLoadOutAdmin,
  createDeliveryLoadOutAdminSuccess,
  deleteDeliveryTruckCartAdmin,
  deleteDeliveryTruckCartAdminSuccess,
  getAdminError,
  getDeliveryLoadInAdmin,
  getDeliveryLoadInAdminSuccess,
  getTruckDeliveryAdmin,
  getTruckDeliveryAdminSuccess,
  sendBackToLoadInByTruckDelivery,
  sendBackToLoadInByTruckDeliverySuccess,
  setAdminUI,
  updateDeliveryAdmin,
  updateDeliveryAdminSuccess,
  updateDeliveryLoadInAdmin,
  updateDeliveryLoadOutAdmin,
  updateDeliveryLoadOutAdminSuccess,
} from './actions';
import { AdminReducer } from './types';

/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */
const INITIAL_STATE = {};

const adminReducer = createReducer<AdminReducer>(INITIAL_STATE, (builder) => {
  builder.addCase(getDeliveryLoadInAdmin, (state) => {
    state.ui = { ...state.ui, loading: true };
  });
  builder.addCase(getDeliveryLoadInAdminSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.deliveryLoadIns = payload;
  });
  builder.addCase(getTruckDeliveryAdmin, (state) => {
    state.ui = { ...state.ui, loading: true };
    state.deliveryLoadIns = undefined;
    state.truckDelivery = undefined;
  });
  builder.addCase(getTruckDeliveryAdminSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.truckDelivery = payload;
  });
  builder.addCase(updateDeliveryAdmin, (state) => {
    state.ui = { ...state.ui, loading: true, loadingBtn: true };
  });
  builder.addCase(updateDeliveryLoadInAdmin, (state) => {
    state.ui = { ...state.ui, loading: true, loadingBtn: true };
  });
  builder.addCase(updateDeliveryAdminSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false, loadingBtn: false, key: (state?.ui?.key || 0) + 1 };
    state.truckDelivery = payload;
  });
  builder.addCase(updateDeliveryLoadOutAdmin, (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addCase(updateDeliveryLoadOutAdminSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: false, visible: false };
    state.deliveryLoadIns = state.deliveryLoadIns?.map((e) =>
      e.id === payload.load_in_inventory_id
        ? { ...e, load_out_inventory: { ...payload } }
        : { ...e }
    );
  });
  builder.addCase(createDeliveryLoadOutAdmin, (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addCase(createDeliveryLoadOutAdminSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: false, visible: false };
    state.deliveryLoadIns = state.deliveryLoadIns?.map((e) =>
      e.id === payload.load_in_inventory_id
        ? { ...e, load_out_inventory: { ...payload } }
        : { ...e }
    );
  });
  builder.addCase(deleteDeliveryTruckCartAdmin, (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addCase(deleteDeliveryTruckCartAdminSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: true };
    state.deliveryLoadIns = state?.deliveryLoadIns?.filter((e) => e.id !== payload.id);
  });
  builder.addCase(setAdminUI, (state, { payload }) => {
    state.ui = { ...state.ui, ...payload };
  });
  builder.addCase(sendBackToLoadInByTruckDelivery, (state) => {
    state.ui = { ...state?.ui, loadingBtn: true };
  });
  builder.addCase(sendBackToLoadInByTruckDeliverySuccess, (state) => {
    state.ui = { ...state?.ui, loadingBtn: false, key: (state?.ui?.key || 0) + 1 };
  });
  builder.addCase(getAdminError, (state) => {
    state.ui = {};
  });
});

export default adminReducer;
