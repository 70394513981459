/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ADD_MULTIPLE_TRUCK_CART_ITEM,
  ADD_TRUCK_CART_ITEM,
  DELETE_TRUCK_CART_ITEM,
  GET_TRUCK_CART_BY_DELIVERY_LIST,
  GET_TRUCK_CART_INSIDE_TRUCK_LIST,
  GET_TRUCK_CART_ITEM,
  GET_TRUCK_CART_LIST,
  SET_FILTER_TRUCK_CART_INSIDE_TRUCK_LIST,
  SET_FILTER_TRUCK_CART_LIST,
  UPDATE_TRUCK_CART_ITEM,
} from './actions';
import {
  addMultipleTruckCartItemWorker,
  addTruckCartItemWorker,
  deleteTruckCartItemWorker,
  getTruckCartItemWorker,
  getTruckCartListByDeliveryWorker,
  getTruckCartListInsideTruckWorker,
  getTruckCartListWorker,
  setFilterTruckCartInsideTruckListWorker,
  setFilterTruckCartListWorker,
  updateTruckCartItemWorker,
} from './workers';

export function* truckCartSaga() {
  yield takeLatest(GET_TRUCK_CART_LIST, getTruckCartListWorker);
  yield takeEvery(GET_TRUCK_CART_INSIDE_TRUCK_LIST, getTruckCartListInsideTruckWorker);
  yield takeLatest(GET_TRUCK_CART_ITEM, getTruckCartItemWorker);
  yield takeLatest(SET_FILTER_TRUCK_CART_LIST, setFilterTruckCartListWorker);
  yield takeLatest(
    SET_FILTER_TRUCK_CART_INSIDE_TRUCK_LIST,
    setFilterTruckCartInsideTruckListWorker
  );
  yield takeLatest(ADD_TRUCK_CART_ITEM, addTruckCartItemWorker);
  yield takeLatest(ADD_MULTIPLE_TRUCK_CART_ITEM, addMultipleTruckCartItemWorker);
  yield takeLatest(UPDATE_TRUCK_CART_ITEM, updateTruckCartItemWorker);
  yield takeLatest(DELETE_TRUCK_CART_ITEM, deleteTruckCartItemWorker);
  yield takeLatest(GET_TRUCK_CART_BY_DELIVERY_LIST, getTruckCartListByDeliveryWorker);
}
