/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  ADD_MULTIPLE_INVENTORY_ITEM,
  ADD_INVENTORY_ITEM,
  DELETE_INVENTORY_ITEM,
  GET_INVENTORY_ITEM,
  GET_INVENTORY_LIST,
  SET_FILTER_INVENTORY_LIST,
  UPDATE_INVENTORY_ITEM,
  IMPORT_INVENTORY_FILE,
  GET_INVENTORY_OPTIONS_LIST,
  ROLL_BACK_INVENTORY,
} from './actions';
import {
  addMultipleInventoryItemWorker,
  addInventoryItemWorker,
  deleteInventoryItemWorker,
  getInventoryItemWorker,
  getInventoryListWorker,
  setFilterInventoryListWorker,
  updateInventoryItemWorker,
  importInventoryItemWorker,
  getInventoryOptionsListWorker,
  rollbackInventoryWorker,
} from './workers';

export function* inventorySaga() {
  yield takeLatest(GET_INVENTORY_LIST, getInventoryListWorker);
  yield takeLatest(GET_INVENTORY_OPTIONS_LIST, getInventoryOptionsListWorker);
  yield takeLatest(GET_INVENTORY_ITEM, getInventoryItemWorker);
  yield takeLatest(SET_FILTER_INVENTORY_LIST, setFilterInventoryListWorker);
  yield takeLatest(ADD_INVENTORY_ITEM, addInventoryItemWorker);
  yield takeLatest(ADD_MULTIPLE_INVENTORY_ITEM, addMultipleInventoryItemWorker);
  yield takeLatest(UPDATE_INVENTORY_ITEM, updateInventoryItemWorker);
  yield takeLatest(DELETE_INVENTORY_ITEM, deleteInventoryItemWorker);
  yield takeLatest(IMPORT_INVENTORY_FILE, importInventoryItemWorker);
  yield takeLatest(ROLL_BACK_INVENTORY, rollbackInventoryWorker);
}
