/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  addInvoiceDraftItem,
  addInvoiceDraftItemError,
  addInvoiceDraftItemSuccess,
  addPaymentItemFromPayment,
  cancelledInvoiceDraftItem,
  cancelledInvoiceDraftItemError,
  cancelledInvoiceDraftItemSuccess,
  clearFieldInvoiceDraftItem,
  clearInvoiceDraftList,
  deleteInvoiceDraftItem,
  deleteInvoiceDraftItemError,
  deleteInvoiceDraftItemSuccess,
  getInvoiceDraftItem,
  getInvoiceDraftItemError,
  getInvoiceDraftItemSuccess,
  getInvoiceDraftList,
  getInvoiceDraftListError,
  getInvoiceDraftListSuccess,
  getMyInvoiceDraftList,
  getMyInvoiceDraftListError,
  getMyInvoiceDraftListSuccess,
  mapInvoiceDraftToList,
  pushInvoiceDraftItemToInvoiceDraft,
  removeInvoiceDraftItemToInvoiceDraft,
  removePaymentItemFromPayment,
  setFilterInvoiceDraftList,
  setInvoiceDraftVisible,
  updateCreateInvoiceDraft,
  updateInvoiceDraftItem,
  updateInvoiceDraftItemError,
  updateInvoiceDraftItemSuccess,
  updateInvoiceDraftUI,
  updateTemporarySelectPayment,
} from './actions';
import { InvoiceDraftReducer } from './types';

const initialState: InvoiceDraftReducer = {
  invoiceDrafts: undefined,
  myInvoiceDrafts: undefined,
};

const invoiceDraftReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getInvoiceDraftListSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
      state.invoiceDrafts = payload.invoiceDrafts;
    })
    .addCase(getMyInvoiceDraftListSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
      state.myInvoiceDrafts = payload.myInvoiceDrafts;
    })
    .addCase(getInvoiceDraftItemSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
      state.invoiceDraft = payload.invoiceDraft;
    })
    .addCase(addInvoiceDraftItemSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, visible: false, loadingBtn: false };
      state.invoiceDrafts = (payload?.invoiceDraft ? [payload?.invoiceDraft] : []).concat(
        state.invoiceDrafts || []
      );
      state.createInvoiceDraft = undefined;
    })
    .addCase(updateInvoiceDraftItemSuccess, (state, { payload }) => {
      state.ui = {
        ...state.ui,
        visible: false,
        loadingBtn: false,
        loading: false,
        key: state.ui?.key && +state.ui.key >= 0 ? state.ui.key + 1 : 1,
      };
      state.invoiceDraft = state.invoiceDraft
        ? { ...state.invoiceDraft, ...payload.invoiceDraft }
        : { ...payload.invoiceDraft };
    })
    .addCase(deleteInvoiceDraftItemSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, visible: false, loadingBtn: false, loading: false };
      state.invoiceDrafts = state.invoiceDrafts?.filter((e) => e.id !== payload?.invoiceDraft?.id);
    })
    .addCase(setFilterInvoiceDraftList, (state, { payload }) => {
      state.ui = { ...state.ui, loading: true };
      if (payload.isMyInvoiceDraft) {
        state.myFilterable = { ...state.myFilterable, ...payload.myFilterable };
      } else {
        state.filterable = { ...state.filterable, ...payload.filterable };
      }
    })
    .addCase(clearInvoiceDraftList, (state, { payload }) => {
      state.ui = { ...state.ui, loading: true };
      if (payload?.isMyInvoiceDraft) {
        state.myInvoiceDrafts = [];
      } else {
        state.invoiceDrafts = [];
      }
    })
    .addCase(setInvoiceDraftVisible, (state, { payload }) => {
      state.ui = { ...state.ui, ...payload };
    })
    .addCase(pushInvoiceDraftItemToInvoiceDraft, (state, { payload }) => {
      const newInvoiceDraftItems = (payload ? [payload] : []).concat(
        state?.invoiceDraft?.invoice_draft_items || []
      );

      state.invoiceDraft = state?.invoiceDraft
        ? { ...state.invoiceDraft, invoice_draft_items: [...newInvoiceDraftItems] }
        : undefined;
    })
    .addCase(removeInvoiceDraftItemToInvoiceDraft, (state, { payload }) => {
      state.invoiceDraft = state?.invoiceDraft
        ? {
            ...state.invoiceDraft,
            invoice_draft_items: state?.invoiceDraft?.invoice_draft_items?.filter(
              (e) => e.id !== payload?.id
            ),
          }
        : undefined;
    })
    .addCase(updateCreateInvoiceDraft, (state, { payload }) => {
      state.createInvoiceDraft = { ...state.createInvoiceDraft, ...payload };
    })
    .addCase(updateTemporarySelectPayment, (state, { payload }) => {
      state.temporarySelectPayment = payload;
    })
    .addCase(updateInvoiceDraftUI, (state, { payload }) => {
      state.invoiceDraft = { ...state.invoiceDraft, ...payload };
    })
    .addCase(cancelledInvoiceDraftItemError, (state, { payload }) => {
      state.ui = { ...state.ui, loadingBtn: false };
    })
    .addCase(cancelledInvoiceDraftItemSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loadingBtn: false };
    })
    .addCase(addPaymentItemFromPayment, (state, { payload }) => {
      if (state.invoiceDraft) {
        const newPayments = state.invoiceDraft?.invoice_draft_payments
          ? [payload, ...state.invoiceDraft.invoice_draft_payments]
          : [];

        state.invoiceDraft = {
          ...state.invoiceDraft,
          total_paid: newPayments?.reduce((a, b) => a + b.cash_amount, 0),
          invoice_draft_payments: [...newPayments],
        };
      }
    })
    .addCase(removePaymentItemFromPayment, (state, { payload }) => {
      if (state.invoiceDraft) {
        const newPayments =
          state.invoiceDraft?.invoice_draft_payments?.filter((e) => e.id !== payload.id) ?? [];
        state.invoiceDraft = {
          ...state.invoiceDraft,
          invoice_draft_payments: [...newPayments],
          total_paid: newPayments?.reduce((a, b) => a + b.cash_amount, 0),
        };
      }
    })
    .addCase(clearFieldInvoiceDraftItem, (state) => {
      state.invoiceDraft = undefined;
    })
    .addCase(mapInvoiceDraftToList, (state, { payload }) => {
      if (payload.isMyInvoiceDraft) {
        state.myInvoiceDrafts = state.myInvoiceDrafts?.map((e) =>
          e.id === payload.id ? { ...e, ...payload } : e
        );
      } else {
        state.invoiceDrafts = state.invoiceDrafts?.map((e) =>
          e.id === payload.id ? { ...e, ...payload } : e
        );
      }
    })
    .addMatcher(
      isAnyOf(getInvoiceDraftList, getMyInvoiceDraftList, getInvoiceDraftItem),
      (state) => {
        state.ui = { ...state.ui, loading: true };
      }
    )
    .addMatcher(
      isAnyOf(
        addInvoiceDraftItem,
        updateInvoiceDraftItem,
        deleteInvoiceDraftItem,
        cancelledInvoiceDraftItem
      ),
      (state) => {
        state.ui = { ...state.ui, loadingBtn: true };
      }
    )
    .addMatcher(
      isAnyOf(
        getInvoiceDraftListError,
        getMyInvoiceDraftListError,
        getInvoiceDraftItemError,
        addInvoiceDraftItemError,
        updateInvoiceDraftItemError,
        deleteInvoiceDraftItemError
      ),
      (state) => {
        state.ui = { loading: false, loadingPage: false };
      }
    )
);

export default invoiceDraftReducer;
