/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import {
  Truck,
  TruckAddBody,
  TruckDeleteBody,
  TruckItemPayload,
  TruckListParams,
  TruckUpdateBody,
} from './types';

const rootAPI = 'trucks';

export const getTruckListAPI = (payload?: TruckListParams) => {
  return axiosQuery.get<ResponseStructure<Truck[]>>(rootAPI, {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getTruckListWithDeliveryAPI = (payload?: TruckListParams) => {
  return axiosQuery.get<ResponseStructure<Truck[]>>(rootAPI + '/get-with-delivery/list', {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getTruckItemAPI = (payload?: TruckItemPayload) => {
  return axiosQuery.get<ResponseStructure<Truck>>(`${rootAPI}/${payload?.id}`);
};

export const addTruckItemAPI = (payload?: TruckAddBody) => {
  return axiosQuery.post<ResponseStructure<Truck>>(rootAPI, payload);
};

export const updateTruckItemAPI = (payload?: TruckUpdateBody) => {
  return axiosQuery.post<ResponseStructure<Truck>>(`${rootAPI}/${payload?.id}`, {
    _method: 'PUT',
    ...payload,
  });
};

export const deleteTruckItemAPI = (payload?: TruckDeleteBody) => {
  return axiosQuery.delete<ResponseStructure<Truck>>(`${rootAPI}/${payload?.id}`);
};
