import { cilCart } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CTooltip } from '@coreui/react';
import React from 'react';

const TrialIcon = () => {
  return (
    <CTooltip content="This site is version for testing!" placement="left">
      <CButton size="lg" color="warning" className="trial-icon">
        <CIcon icon={cilCart} />
      </CButton>
    </CTooltip>
  );
};

export default React.memo(TrialIcon);
