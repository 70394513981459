/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer } from '@reduxjs/toolkit';
import {
  createLoadOutInventories,
  createLoadOutInventoriesError,
  createLoadOutInventoriesSuccess,
  getLoadOutInventories,
  getLoadOutInventoriesError,
  getLoadOutInventoriesSuccess,
  setLoadOutUI,
  updateLoadOutInventories,
  updateLoadOutInventoriesError,
  updateLoadOutInventoriesSuccess,
} from './actions';
import { LoadOutReducer } from './types';

const initialState = {};

export const loadOutReducer = createReducer<LoadOutReducer>(initialState, (builder) =>
  builder
    .addCase(getLoadOutInventories, (state, { payload }) => {
      state.ui = { ...state.ui, loading: true };
    })
    .addCase(createLoadOutInventories, (state, { payload }) => {
      state.ui = { ...state.ui, loadingBtn: true };
    })
    .addCase(updateLoadOutInventories, (state, { payload }) => {
      state.ui = { ...state.ui, loadingBtn: true };
    })
    .addCase(getLoadOutInventoriesSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
      state.truckCarts = [...payload];
    })
    .addCase(createLoadOutInventoriesSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loadingBtn: false, visible: false };
      state.truckCarts = state.truckCarts ? [payload, ...state.truckCarts] : [payload];
    })
    .addCase(updateLoadOutInventoriesSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loadingBtn: false, visible: false };
      state.truckCarts = state.truckCarts?.map((e) =>
        e.id === payload.id ? { ...e, ...payload } : { ...e }
      );
    })
    .addCase(getLoadOutInventoriesError, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
    })
    .addCase(createLoadOutInventoriesError, (state, { payload }) => {
      state.ui = { ...state.ui, loadingBtn: false };
    })
    .addCase(updateLoadOutInventoriesError, (state, { payload }) => {
      state.ui = { ...state.ui, loadingBtn: false };
    })
    .addCase(setLoadOutUI, (state, { payload }) => {
      state.ui = { ...state.ui, ...payload };
    })

    .addDefaultCase((state, action) => {})
);
