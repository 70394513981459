import React, { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import routes, { protectRoutes } from './routes';
import './scss/style.scss';
import './custom.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import PageLoading from './components/common/loading/PageLoading';
import { useCanAccessConfig } from './hooks/useCanAccessConfig';
import ScrollToTop from './components/common/button/ScrollToTop';
import CheckPermission from './routes/CheckPermission';
import TrialIcon from './components/common/trial/TrialIcon';
import InvoicePrint from './views/dashboard/invoice/InvoicePrint';

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Page401 = React.lazy(() => import('./views/pages/page401/Page401'));

const App = () => {
  const { canConfig, loading } = useCanAccessConfig();
  const host = window.location.host;

  return (
    <>
      <ScrollToTop />

      {host === 'tl-frontend-2.wrewardspos.com' && <TrialIcon />}

      <HashRouter>
        <Suspense fallback={<PageLoading loading={true} />}>
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/invoices/:invoice_id/print/public" element={<InvoicePrint isPublic />} />

            <Route path="/" element={<DefaultLayout />}>
              {routes.map((route) => {
                return (
                  route.element && (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        <CheckPermission name={route.name}>
                          <route.element />
                        </CheckPermission>
                      }
                    />
                  )
                );
              })}
            </Route>

            {canConfig && (
              <Route path="/dashboard" element={<DefaultLayout />}>
                {protectRoutes.map((route, idx) => {
                  return (
                    route.element && (
                      <Route key={route.path} path={route.path} element={<route.element />} />
                    )
                  );
                })}
              </Route>
            )}

            <Route path="/register" element={<Register />} />
            <Route path="/404" element={<Page404 />} />
            <Route path="/401" element={<Page401 />} />
            <Route path="/500" element={<Page500 />} />
            <Route path="*" element={loading ? <PageLoading loading={true} /> : <Page404 />} />
            {/* <Route path="/" element={<Navigate to="/dashboard" replace />} /> */}
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  );
};

export default App;
