/**
 * The event send to the store
 */

import { ActionSaga } from 'src/types/saga.types';
import {
  TruckCart,
  TruckCartAddBody,
  TruckCartDeleteBody,
  TruckCartFilterable,
  TruckCartItemPayload,
  TruckCartItemSuccessPayload,
  TruckCartListInsideTruckParams,
  TruckCartListInsideTruckSuccessPayload,
  TruckCartListParams,
  TruckCartListSuccessPayload,
  TruckCartUI,
  TruckCartUpdateBody,
  TruckCartVisiblePayload,
} from './types';

export const GET_TRUCK_CART_LIST = 'GET_TRUCK_CART_LIST';
export const GET_TRUCK_CART_LIST_SUCCESS = 'GET_TRUCK_CART_LIST_SUCCESS';
export const GET_MY_TRUCK_CART_LIST_SUCCESS = 'GET_MY_TRUCK_CART_LIST_SUCCESS';
export const GET_TRUCK_CART_LIST_ERROR = 'GET_TRUCK_CART_LIST_ERROR';
export const SET_FILTER_TRUCK_CART_LIST = 'SET_FILTER_TRUCK_CART_LIST';
export const CLEAR_TRUCK_CART_LIST = 'CLEAR_TRUCK_CART_LIST';

export const GET_TRUCK_CART_INSIDE_TRUCK_LIST = 'GET_TRUCK_CART_INSIDE_TRUCK_LIST';
export const GET_TRUCK_CART_INSIDE_TRUCK_LIST_SUCCESS = 'GET_TRUCK_CART_INSIDE_TRUCK_LIST_SUCCESS';
export const GET_TRUCK_CART_INSIDE_TRUCK_LIST_ERROR = 'GET_TRUCK_CART_INSIDE_TRUCK_LIST_ERROR';
export const SET_TRUCK_CART_INSIDE_TRUCK_LIST = 'SET_TRUCK_CART_INSIDE_TRUCK_LIST';
export const SET_FILTER_TRUCK_CART_INSIDE_TRUCK_LIST = 'SET_FILTER_TRUCK_CART_INSIDE_TRUCK_LIST';
export const CLEAR_TRUCK_CART_INSIDE_TRUCK_LIST = 'CLEAR_TRUCK_CART_INSIDE_TRUCK_LIST';
export const DELETE_TRUCK_CART_ITEM_INSIDE_TRUCK_SUCCESS =
  'DELETE_TRUCK_CART_ITEM_INSIDE_TRUCK_SUCCESS';

export const GET_TRUCK_CART_ITEM = 'GET_TRUCK_CART_ITEM';
export const GET_TRUCK_CART_ITEM_SUCCESS = 'GET_TRUCK_CART_ITEM_SUCCESS';
export const GET_TRUCK_CART_ITEM_ERROR = 'GET_TRUCK_CART_ITEM_ERROR';

export const ADD_TRUCK_CART_ITEM = 'ADD_TRUCK_CART_ITEM';
export const ADD_MULTIPLE_TRUCK_CART_ITEM = 'ADD_MULTIPLE_TRUCK_CART_ITEM';
export const ADD_TRUCK_CART_ITEM_SUCCESS = 'ADD_TRUCK_CART_ITEM_SUCCESS';
export const ADD_TRUCK_CART_ITEM_ERROR = 'ADD_TRUCK_CART_ITEM_ERROR';

export const UPDATE_TRUCK_CART_ITEM = 'UPDATE_TRUCK_CART_ITEM';
export const UPDATE_TRUCK_CART_ITEM_SUCCESS = 'UPDATE_TRUCK_CART_ITEM_SUCCESS';
export const UPDATE_TRUCK_CART_ITEM_ERROR = 'UPDATE_TRUCK_CART_ITEM_ERROR';

export const DELETE_TRUCK_CART_ITEM = 'DELETE_TRUCK_CART_ITEM';
export const DELETE_TRUCK_CART_ITEM_SUCCESS = 'DELETE_TRUCK_CART_ITEM_SUCCESS';
export const DELETE_TRUCK_CART_ITEM_ERROR = 'DELETE_TRUCK_CART_ITEM_ERROR';

export const SET_TRUCK_CART_VISIBLE = 'SET_TRUCK_CART_VISIBLE';

export const GET_TRUCK_CART_BY_DELIVERY_LIST = 'GET_TRUCK_CART_BY_DELIVERY_LIST';
export const GET_TRUCK_CART_BY_DELIVERY_LIST_SUCCESS = 'GET_TRUCK_CART_BY_DELIVERY_LIST_SUCCESS';
export const GET_TRUCK_CART_BY_DELIVERY_LIST_ERROR = 'GET_TRUCK_CART_BY_DELIVERY_LIST_ERROR';

export const getTruckCartList = (
  payload?: TruckCartListParams
): ActionSaga<TruckCartListParams> => ({
  type: GET_TRUCK_CART_LIST,
  payload: payload ? { ...payload } : null,
});

export const getTruckCartListSuccess = (
  payload: TruckCartListSuccessPayload
): ActionSaga<TruckCartListSuccessPayload> => ({
  type: GET_TRUCK_CART_LIST_SUCCESS,
  payload: { ...payload },
});

export const getTruckCartListError = (): ActionSaga => ({
  type: GET_TRUCK_CART_LIST_ERROR,
});

export const getTruckCartInsideTruckList = (
  payload?: TruckCartListInsideTruckParams
): ActionSaga<TruckCartListInsideTruckParams> => ({
  type: GET_TRUCK_CART_INSIDE_TRUCK_LIST,
  payload: payload ? { ...payload } : null,
});

export const getMyTruckCartListSuccess = (payload?: {
  myTruckCarts: TruckCart[];
}): ActionSaga<{
  myTruckCarts: TruckCart[];
}> => ({
  type: GET_MY_TRUCK_CART_LIST_SUCCESS,
  payload: payload ? { ...payload } : null,
});

export const getTruckCartInsideTruckListSuccess = (
  payload: TruckCartListInsideTruckSuccessPayload
): ActionSaga<TruckCartListInsideTruckSuccessPayload> => ({
  type: GET_TRUCK_CART_INSIDE_TRUCK_LIST_SUCCESS,
  payload: { ...payload },
});

export const getTruckCartInsideTruckListError = (): ActionSaga => ({
  type: GET_TRUCK_CART_INSIDE_TRUCK_LIST_ERROR,
});

export const setFilterTruckCartList = (
  payload?: TruckCartFilterable
): ActionSaga<TruckCartListParams> => ({
  type: SET_FILTER_TRUCK_CART_LIST,
  payload: { filterable: { ...payload } },
});

export const setFilterTruckCartInsideTruckList = (
  payload: TruckCartListInsideTruckParams
): ActionSaga<TruckCartListInsideTruckParams> => ({
  type: SET_FILTER_TRUCK_CART_INSIDE_TRUCK_LIST,
  payload: { ...payload },
});

export const clearTruckCartList = (): ActionSaga<TruckCartListSuccessPayload> => ({
  type: CLEAR_TRUCK_CART_LIST,
  payload: { truckCarts: [] },
});

export const getTruckCartItem = (
  payload: TruckCartItemPayload
): ActionSaga<TruckCartItemPayload> => ({
  type: GET_TRUCK_CART_ITEM,
  payload: { ...payload },
});

export const getTruckCartItemSuccess = (
  payload: TruckCartItemSuccessPayload
): ActionSaga<TruckCartItemSuccessPayload> => ({
  type: GET_TRUCK_CART_ITEM_SUCCESS,
  payload: { ...payload },
});

export const getTruckCartItemError = (): ActionSaga => ({
  type: GET_TRUCK_CART_ITEM_ERROR,
});

export const addTruckCartItem = (payload: TruckCartAddBody): ActionSaga<TruckCartAddBody> => ({
  type: ADD_TRUCK_CART_ITEM,
  payload: { ...payload },
});

export const addMultipleTruckCartItem = (
  payload: TruckCartAddBody
): ActionSaga<TruckCartAddBody> => ({
  type: ADD_MULTIPLE_TRUCK_CART_ITEM,
  payload: { ...payload },
});

export const addTruckCartItemSuccess = (
  payload: TruckCartItemSuccessPayload
): ActionSaga<TruckCartItemSuccessPayload> => ({
  type: ADD_TRUCK_CART_ITEM_SUCCESS,
  payload: { ...payload },
});

export const addTruckCartItemError = (): ActionSaga => ({
  type: ADD_TRUCK_CART_ITEM_ERROR,
});

export const updateTruckCartItem = (
  payload: TruckCartUpdateBody
): ActionSaga<TruckCartUpdateBody> => ({
  type: UPDATE_TRUCK_CART_ITEM,
  payload: { ...payload },
});

export const updateTruckCartItemSuccess = (
  payload: TruckCartItemSuccessPayload
): ActionSaga<TruckCartItemSuccessPayload> => ({
  type: UPDATE_TRUCK_CART_ITEM_SUCCESS,
  payload: { ...payload },
});

export const updateTruckCartItemError = (): ActionSaga => ({
  type: UPDATE_TRUCK_CART_ITEM_ERROR,
});

export const deleteTruckCartItem = (
  payload: TruckCartDeleteBody
): ActionSaga<TruckCartDeleteBody> => ({
  type: DELETE_TRUCK_CART_ITEM,
  payload: { ...payload },
});

export const deleteTruckCartItemSuccess = (
  payload: TruckCartItemSuccessPayload
): ActionSaga<TruckCartItemSuccessPayload> => ({
  type: DELETE_TRUCK_CART_ITEM_SUCCESS,
  payload: { ...payload },
});

export const deleteTruckCartItemError = (): ActionSaga => ({
  type: DELETE_TRUCK_CART_ITEM_ERROR,
});

export const setTruckCartVisible = (
  payload: TruckCartVisiblePayload
): ActionSaga<TruckCartUI<TruckCartVisiblePayload>> => ({
  type: SET_TRUCK_CART_VISIBLE,
  payload: { ui: { ...payload } },
});

export const deleteTruckCartItemInsideTruckSuccess = (
  payload: Pick<TruckCart, 'id'>
): ActionSaga<{ mutable: Pick<TruckCart, 'id'> }> => ({
  type: DELETE_TRUCK_CART_ITEM_INSIDE_TRUCK_SUCCESS,
  payload: { mutable: { ...payload } },
});

export const getTruckCartByDelivery = (payload?: {
  truck_delivery_id: string;
}): ActionSaga<{ truck_delivery_id: string }> => ({
  type: GET_TRUCK_CART_BY_DELIVERY_LIST,
  payload: payload ? { ...payload } : null,
});

export const getTruckCartByDeliverySuccess = (payload?: {
  deliveryTruckCarts: TruckCart[];
}): ActionSaga<{ deliveryTruckCarts: TruckCart[] }> => ({
  type: GET_TRUCK_CART_BY_DELIVERY_LIST_SUCCESS,
  payload: payload ? { ...payload } : null,
});

export const getTruckCartByDeliveryError = (): ActionSaga => ({
  type: GET_TRUCK_CART_BY_DELIVERY_LIST_ERROR,
});
