/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import { addGiftCard } from './actions';
import { addGiftCardWorker } from './workers';

export function* giftCardSaga() {
  yield takeLatest(addGiftCard, addGiftCardWorker);
}
