/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { ActionSaga } from 'src/types/saga.types';
import {
  addTruckCartItemError,
  addTruckCartItemSuccess,
  clearTruckCartList,
  deleteTruckCartItemError,
  deleteTruckCartItemInsideTruckSuccess,
  deleteTruckCartItemSuccess,
  getMyTruckCartListSuccess,
  getTruckCartByDeliveryError,
  getTruckCartByDeliverySuccess,
  getTruckCartInsideTruckListError,
  getTruckCartInsideTruckListSuccess,
  getTruckCartItemError,
  getTruckCartItemSuccess,
  getTruckCartListError,
  getTruckCartListSuccess,
  setTruckCartVisible,
  updateTruckCartItemError,
  updateTruckCartItemSuccess,
} from './actions';
import {
  addTruckCartItemAPI,
  deleteTruckCartItemAPI,
  getTruckCartItemAPI,
  getTruckCartListAPI,
  getTruckCartListByDeliveryAPI,
  getTruckCartListInsideTruckAPI,
  updateTruckCartItemAPI,
} from './apis';
import {
  TruckCartAddBody,
  TruckCartDeleteBody,
  TruckCartItemPayload,
  TruckCartListInsideTruckParams,
  TruckCartListParams,
  TruckCartUpdateBody,
} from './types';
import { increaseKeyCurrentUser } from '../currentUser/actions';

export function* getTruckCartListWorker(action: ActionSaga<TruckCartListParams>) {
  try {
    const { data }: SagaReturnType<typeof getTruckCartListAPI> = yield call(
      getTruckCartListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getTruckCartListSuccess({
        truckCarts: data.data,
        pagination: newPagination,
      })
    );
  } catch {
    yield put(getTruckCartListError());
  }
}

export function* getTruckCartListInsideTruckWorker(
  action: ActionSaga<TruckCartListInsideTruckParams>
) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getTruckCartListInsideTruckAPI> = yield call(
        getTruckCartListInsideTruckAPI,
        action.payload
      );
      const newPagination = action.payload?.pagination
        ? {
            ...action.payload.pagination,
            showPagination: data.data?.length === action.payload.pagination.limit,
          }
        : undefined;
      if (action.payload?.isMyTruck) {
        yield put(
          getMyTruckCartListSuccess({
            myTruckCarts: data.data,
          })
        );
      } else {
        yield put(
          getTruckCartInsideTruckListSuccess({
            insideTrucks: data.data,
            pagination: newPagination,
          })
        );
      }
    }
  } catch {
    yield put(getTruckCartInsideTruckListError());
  }
}

export function* setFilterTruckCartListWorker(action: ActionSaga<TruckCartListParams>) {
  yield put(clearTruckCartList());
  yield call(getTruckCartListWorker, action);
}

export function* setFilterTruckCartInsideTruckListWorker(
  action: ActionSaga<TruckCartListInsideTruckParams>
) {
  yield put(clearTruckCartList());
  yield call(getTruckCartListInsideTruckWorker, action);
}

export function* getTruckCartItemWorker(action: ActionSaga<TruckCartItemPayload>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getTruckCartItemAPI> = yield call(
        getTruckCartItemAPI,
        action.payload
      );
      yield put(getTruckCartItemSuccess({ truckCart: data.data }));
    }
  } catch {
    yield put(getTruckCartItemError());
  }
}

export function* addTruckCartItemWorker(action: ActionSaga<TruckCartAddBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof addTruckCartItemAPI> = yield call(
        addTruckCartItemAPI,
        action.payload
      );
      yield put(addTruckCartItemSuccess({ truckCart: data.data }));
      yield put(setTruckCartVisible({ visible: false }));
      yield put(increaseKeyCurrentUser());

      if (action.payload.callback) {
        action.payload.callback();
      }

      document.querySelector<HTMLElement>('.rbt-close')?.click();
    }
  } catch (e) {
    yield put(addTruckCartItemError());
  }
}

export function* addMultipleTruckCartItemWorker(action: ActionSaga<TruckCartAddBody>) {
  // for (let i = 10; i <= 500; i++) {
  //   try {
  //     if (action.payload) {
  //       const { data }: SagaReturnType<typeof addTruckCartItemAPI> = yield call(addTruckCartItemAPI, {
  //         name: action.payload.name + '_' + i,
  //       });
  //       yield put(addTruckCartItemSuccess({ truckCart: data.data }));
  //     }
  //   } catch {
  //     yield put(addTruckCartItemError());
  //   }
  // }
}

export function* updateTruckCartItemWorker(action: ActionSaga<TruckCartUpdateBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateTruckCartItemAPI> = yield call(
        updateTruckCartItemAPI,
        action.payload
      );
      yield put(updateTruckCartItemSuccess({ truckCart: data.data }));
    }
  } catch {
    yield put(updateTruckCartItemError());
  }
}

export function* deleteTruckCartItemWorker(action: ActionSaga<TruckCartDeleteBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof deleteTruckCartItemAPI> = yield call(
        deleteTruckCartItemAPI,
        action.payload
      );

      yield put(deleteTruckCartItemSuccess({ truckCart: { ...data.data, id: action.payload.id } }));
      yield put(deleteTruckCartItemInsideTruckSuccess({ id: action.payload.id }));
    }
  } catch {
    yield put(deleteTruckCartItemError());
  }
}

export function* getTruckCartListByDeliveryWorker(
  action: ActionSaga<{ truck_delivery_id: string }>
) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getTruckCartListByDeliveryAPI> = yield call(
        getTruckCartListByDeliveryAPI,
        action.payload
      );
      yield put(getTruckCartByDeliverySuccess({ deliveryTruckCarts: data.data }));
    }
  } catch {
    yield put(getTruckCartByDeliveryError());
  }
}

// logic CRUD flow
// link tham khảo: https://redux-saga.js.org/docs/advanced/NonBlockingCalls
