/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  addCustomerItem,
  addCustomerItemError,
  addCustomerItemSuccess,
  clearCustomerList,
  customerError,
  customerImport,
  customerImportSuccess,
  deleteCustomerItem,
  deleteCustomerItemError,
  deleteCustomerItemSuccess,
  getCreditLimitList,
  getCreditLimitListSuccess,
  getCustomerList,
  getCustomerListError,
  getCustomerListSuccess,
  setCustomerUI,
  setFilterCustomerList,
  updateCreditLimitItemSuccess,
  updateCustomerItem,
  updateCustomerItemError,
  updateCustomerItemSuccess,
  updateCustomerPassword,
  updateCustomerPasswordError,
  updateCustomerPasswordSuccess,
} from './actions';
import { CustomerReducer } from './types';

const initialState: CustomerReducer = {
  customers: undefined,
};

const customerReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getCustomerList, (state) => {
      state.ui = { ...state?.ui, loading: true };
    })
    .addCase(getCreditLimitList, (state) => {
      state.ui = { ...state?.ui, loading: true };
    })
    .addCase(getCustomerListSuccess, (state, { payload }) => {
      state.ui = { ...state?.ui, loading: false };
      state.customers = (state.customers || []).concat(payload?.customers);
      if (payload?.pagination) {
        state.pagination = { ...state?.pagination, ...payload.pagination };
      }
    })
    .addCase(getCreditLimitListSuccess, (state, { payload }) => {
      state.ui = { ...state?.ui, loading: false };
      state.creditLimits = (state.customers || []).concat(payload?.customers);
      if (payload?.pagination) {
        state.pagination = { ...state?.pagination, ...payload.pagination };
      }
    })
    // GET LIST OF CUSTOMERS

    .addCase(addCustomerItem, (state) => {
      state.ui = { ...state?.ui, loadingBtn: true };
    })
    .addCase(addCustomerItemSuccess, (state, { payload }) => {
      const key = (state?.ui?.key || 0) + 1;
      state.ui = { ...state?.ui, loadingBtn: false, visible: false, key };
      state.customers = [payload].concat(state?.customers || []);
      state.newCustomer = payload;
    }) // ADD CUSTOMER

    .addCase(updateCustomerItem, (state) => {
      state.ui = { ...state?.ui, loadingBtn: true };
    })
    .addCase(updateCustomerItemSuccess, (state, { payload }) => {
      state.ui = { ...state?.ui, loadingBtn: false, visible: false };
      state.customers = state?.customers?.map((e) =>
        e.id === payload?.id ? { ...e, ...payload } : e
      );
    })
    .addCase(updateCreditLimitItemSuccess, (state, { payload }) => {
      state.ui = { ...state?.ui, loadingBtn: false, visible: false };
      state.creditLimits = state?.customers?.map((e) =>
        e.id === payload?.id ? { ...e, ...payload } : e
      );
    }) // UPDATE CUSTOMER

    .addCase(updateCustomerPassword, (state) => {
      state.ui = { ...state?.ui, loadingBtn: true };
    })
    .addCase(updateCustomerPasswordSuccess, (state, { payload }) => {
      state.ui = { ...state?.ui, loadingBtn: false, visible: false };
      state.customers = state.customers?.map((e) =>
        e.id === payload?.id ? { ...e, ...payload } : e
      );
    }) // UPDATE PASSWORD

    .addCase(deleteCustomerItem, (state) => {
      state.ui = { ...state?.ui, loading: true };
    })
    .addCase(deleteCustomerItemSuccess, (state, { payload }) => {
      state.ui = { ...state?.ui, loading: false };
      state.customers = state.customers?.filter((e) => e.id !== payload?.id);
    }) // DELETE CUSTOMER

    .addCase(clearCustomerList, (state, { payload }) => {
      state.customers = [];
    }) // CLEAR LIST

    .addCase(setFilterCustomerList, (state, { payload }) => {
      state.ui = { ...state?.ui, loading: true };
    }) // FILTER LIST

    // IMPORT
    .addCase(customerImport, (state) => {
      state.ui = { ...state?.ui, loadingBtn: true, loadingPage: true };
    })
    .addCase(customerImportSuccess, (state) => {
      state.ui = { ...state?.ui, loadingBtn: false, loadingPage: false };
    })

    .addCase(setCustomerUI, (state, { payload }) => {
      state.ui = { ...state?.ui, ...payload };
    })
    .addCase(customerError, (state) => {
      state.ui = {};
    })

    .addMatcher(
      isAnyOf(updateCustomerPasswordError, updateCustomerItemError, addCustomerItemError),
      (state) => {
        state.ui = { ...state?.ui, loadingBtn: false };
      }
    )

    .addMatcher(isAnyOf(deleteCustomerItemError, getCustomerListError), (state) => {
      state.ui = { ...state?.ui, loading: false };
    })
);

export default customerReducer;
