import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  getSettingDefaultPhone,
  getSettingDefaultPhoneSuccess,
  getSettingGlobalTax,
  getSettingGlobalTaxError,
  getSettingGlobalTaxSuccess,
  getSettingList,
  getSettingListError,
  getSettingListSuccess,
  setSettingUI,
  updateSetting,
  updateSettingError,
  updateSettingSuccess,
} from './actions';
import { SettingReducer } from './types';

const INITIAL_STATE = { settings: undefined };

const settingReducer = createReducer<SettingReducer>(INITIAL_STATE, (builder) => {
  builder.addCase(getSettingListSuccess, (state, action) => {
    state.ui = { ...state.ui, loading: false };
    state.settings = action.payload.settings;
  });
  builder.addCase(getSettingGlobalTaxSuccess, (state, action) => {
    state.ui = { ...state.ui, loading: false };
    state.global_tax = action.payload.global_tax;
  });
  builder.addCase(getSettingDefaultPhoneSuccess, (state, action) => {
    state.ui = { ...state.ui, loading: false };
    state.default_phone_sms = action.payload.default_phone_sms;
  });
  builder.addCase(updateSetting, (state) => {
    state.ui = { ...state.ui, loadingBtn: true };
  });
  builder.addCase(updateSettingSuccess, (state, action) => {
    state.settings = state.settings?.map((setting) =>
      setting.id === action.payload?.id ? { ...setting, ...action.payload } : setting
    );
    state.ui = { ...state.ui, loadingBtn: false, visible: false };
  });
  builder.addCase(setSettingUI, (state, action) => {
    state.ui = { ...state.ui, ...action.payload };
  });
  builder.addMatcher(
    isAnyOf(getSettingListError, getSettingGlobalTaxError, updateSettingError),
    (state) => {
      state.ui = { ...state.ui, loading: false };
    }
  );
  builder
    .addMatcher(isAnyOf(getSettingList, getSettingGlobalTax), (state) => {
      state.ui = { ...state.ui, loading: true };
    })
    .addDefaultCase((state, action) => {});
});

export default settingReducer;
