/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  createDeliveryLoadOutAdmin,
  deleteDeliveryTruckCartAdmin,
  getDeliveryLoadInAdmin,
  getTruckDeliveryAdmin,
  sendBackToLoadInByTruckDelivery,
  updateDeliveryAdmin,
  updateDeliveryLoadInAdmin,
  updateDeliveryLoadOutAdmin,
} from './actions';
import {
  createDeliveryLoadOutAdminWorker,
  deleteDeliveryTruckCartAdminWorker,
  getDeliveryLoadInAdminWorker,
  getTruckDeliveryAdminWorker,
  sendBackToLoadInByTruckDeliveryWorker,
  updateDeliveryAdminWorker,
  updateDeliveryLoadInAdminWorker,
  updateDeliveryLoadOutAdminWorker,
} from './workers';

export function* adminSaga() {
  yield takeLatest(getTruckDeliveryAdmin, getTruckDeliveryAdminWorker);
  yield takeLatest(updateDeliveryLoadOutAdmin, updateDeliveryLoadOutAdminWorker);
  yield takeLatest(createDeliveryLoadOutAdmin, createDeliveryLoadOutAdminWorker);
  yield takeLatest(updateDeliveryAdmin, updateDeliveryAdminWorker);
  yield takeLatest(updateDeliveryLoadInAdmin, updateDeliveryLoadInAdminWorker);
  yield takeLatest(deleteDeliveryTruckCartAdmin, deleteDeliveryTruckCartAdminWorker);
  yield takeLatest(getDeliveryLoadInAdmin, getDeliveryLoadInAdminWorker);
  yield takeLatest(sendBackToLoadInByTruckDelivery, sendBackToLoadInByTruckDeliveryWorker);
}
