import 'react-app-polyfill/stable';
import 'core-js';
import './i18n';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import React, { Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import PageLoading from './components/common/loading/PageLoading';

const root = createRoot(document.getElementById('root') as Element);

const renderApp = () =>
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <Suspense fallback="loading">
          <App />
          <Toaster position="top-right" />
        </Suspense>
      </Provider>
    </React.StrictMode>
  );

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./App', renderApp);
}

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
