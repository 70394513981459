import { takeLatest } from 'redux-saga/effects';
import {
  createOrUpdateRequestOrderItem,
  createRequestOrderItem,
  deleteRequestOrderItem,
  updateRequestOrderItem,
} from './actions';
import {
  createOrUpdateRequestOrderItemWorker,
  createRequestOrderItemWorker,
  deleteRequestOrderItemWorker,
  updateRequestOrderItemWorker,
} from './workers';

export function* requestOrderItemSaga() {
  yield takeLatest(createRequestOrderItem, createRequestOrderItemWorker);
  yield takeLatest(createOrUpdateRequestOrderItem, createOrUpdateRequestOrderItemWorker);
  yield takeLatest(updateRequestOrderItem, updateRequestOrderItemWorker);
  yield takeLatest(deleteRequestOrderItem, deleteRequestOrderItemWorker);
}
