import toast from 'react-hot-toast';
import i18n from 'src/i18n';

const { t } = i18n;

export const showSuccessToast = (content?: string) => {
  const { language } = i18n;
  const messageDefault =
    language === 'en' ? t(`en:action-success`) : language === 'vie' ? t(`vie:action-success`) : '';
  toast.success(content || messageDefault);
};

export const showErrorToast = (content?: string) => {
  const { language } = i18n;
  const messageDefault =
    language === 'en' ? t(`en:action-error`) : language === 'vie' ? t(`vie:action-error`) : '';
  toast.error(content || messageDefault);
};
