/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { WarehouseReturnItemBody } from './types';

export const createWarehouseReturnItem = createAction<WarehouseReturnItemBody>(
  'warehouseReturnItem/createWarehouseReturnItem'
);
export const createWarehouseReturnItemSuccess = createAction(
  'warehouseReturnItem/createWarehouseReturnItem/success'
);

export const updateWarehouseReturnItem = createAction<WarehouseReturnItemBody>(
  'warehouseReturnItem/updateWarehouseReturnItem'
);
export const updateWarehouseReturnItemSuccess = createAction(
  'warehouseReturnItem/updateWarehouseReturnItem/success'
);

export const deleteWarehouseReturnItem = createAction<WarehouseReturnItemBody>(
  'warehouseReturnItem/deleteWarehouseReturnItem'
);
export const deleteWarehouseReturnItemSuccess = createAction(
  'warehouseReturnItem/deleteWarehouseReturnItem/success'
);

export const warehouseReturnItemError = createAction('warehouseReturnItem/error');
