/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { ActionSaga } from 'src/types/saga.types';
import { WarehouseReturnItemBody } from './types';
import { SagaReturnType, call, put } from 'redux-saga/effects';
import {
  createWarehouseReturnItemAPI,
  deleteWarehouseReturnItemAPI,
  updateWarehouseReturnItemAPI,
} from './apis';
import {
  createWarehouseReturnItemSuccess,
  deleteWarehouseReturnItemSuccess,
  updateWarehouseReturnItemSuccess,
  warehouseReturnItemError,
} from './actions';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';

export function* updateWarehouseReturnItemWORKER({ payload }: ActionSaga<WarehouseReturnItemBody>) {
  try {
    if (!payload?.id) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof updateWarehouseReturnItemAPI> = yield call(
      updateWarehouseReturnItemAPI,
      payload
    );
    yield put(updateWarehouseReturnItemSuccess());
    showSuccessToast('Update Warehouse Return Item Success');
    if (payload.callback) {
      payload.callback(data.data);
    }
  } catch (e) {
    const message = e.response?.data?.message ?? '';
    console.error(e);
    showErrorToast(message);
    yield put(warehouseReturnItemError());
  }
}

export function* createWarehouseReturnItemWORKER({ payload }: ActionSaga<WarehouseReturnItemBody>) {
  try {
    if (!payload) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof createWarehouseReturnItemAPI> = yield call(
      createWarehouseReturnItemAPI,
      payload
    );
    yield put(createWarehouseReturnItemSuccess());
    showSuccessToast('Add Warehouse Return Item Success');
    if (payload.callback) {
      payload.callback(data.data);
    }
  } catch (e) {
    const message = e.response?.data?.message ?? '';
    console.error(e);
    showErrorToast(message);
    yield put(warehouseReturnItemError());
  }
}

export function* deleteWarehouseReturnItemWORKER({ payload }: ActionSaga<WarehouseReturnItemBody>) {
  try {
    if (!payload?.id) {
      throw new Error('Payload must be required');
    }
    const { data }: SagaReturnType<typeof deleteWarehouseReturnItemAPI> = yield call(
      deleteWarehouseReturnItemAPI,
      payload
    );
    yield put(deleteWarehouseReturnItemSuccess());
    showSuccessToast('Delete Warehouse Return Item Success');

    if (payload.callback) {
      payload.callback(data.data);
    }
  } catch (e) {
    console.error(e);
    showErrorToast();
    yield put(warehouseReturnItemError());
  }
}
