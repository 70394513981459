export const DEFAULT_PAGINATION = {
  pagination: { skip: 0, limit: 1000, showPagination: true },
};

export const MOBILE_WIDTH = 992;
export const TABLET_WIDTH = 1200;

export const SMALL_WIDTH = 768;

export const DISCOUNT_TYPE = [
  { label: '%', value: 'percentage' },
  { label: '$', value: 'currency' },
];

export const DATE_TIME_FORMAT = 'MM/DD/YY HH:mm';
export const DATE_FORMAT = 'MM/DD/YYYY';

export const DEFAULT_LIMIT = 100;
export const DEFAULT_PAGE = 0;
