/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import {
  User,
  UserAddBody,
  UserDeleteBody,
  UserItemPayload,
  UserListParams,
  UserUpdateBody,
} from './types';

const rootAPI = 'users';

export const getUserListAPI = (payload?: UserListParams) => {
  return axiosQuery.get<ResponseStructure<User[]>>(rootAPI, {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getUserItemAPI = (payload?: UserItemPayload) => {
  return axiosQuery.get<ResponseStructure<User>>(`${rootAPI}/${payload?.id}`);
};

export const addUserItemAPI = (payload?: UserAddBody) => {
  return axiosQuery.post<ResponseStructure<User>>(rootAPI, payload);
};

export const updateUserItemAPI = (payload?: UserUpdateBody) => {
  return axiosQuery.post<ResponseStructure<User>>(`${rootAPI}/${payload?.id}`, {
    _method: 'PUT',
    ...payload,
  });
};

export const deleteUserItemAPI = (payload?: UserDeleteBody) => {
  return axiosQuery.delete<ResponseStructure<User>>(`${rootAPI}/${payload?.id}`);
};
