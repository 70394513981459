/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  addInvoiceItem,
  addInvoiceItemError,
  addInvoiceItemSuccess,
  addPaymentItemFromPayment,
  cancelledInvoiceItem,
  cancelledInvoiceItemError,
  cancelledInvoiceItemSuccess,
  clearFieldInvoiceItem,
  clearInvoiceList,
  deleteInvoiceItem,
  deleteInvoiceItemError,
  deleteInvoiceItemSuccess,
  getInvoiceItem,
  getInvoiceItemError,
  getInvoiceItemSuccess,
  getInvoiceList,
  getInvoiceListError,
  getInvoiceListSuccess,
  getMyInvoiceList,
  getMyInvoiceListError,
  getMyInvoiceListSuccess,
  mapInvoiceToList,
  pushInvoiceItemToInvoice,
  removeInvoiceItemToInvoice,
  removePaymentItemFromPayment,
  setFilterInvoiceList,
  setInvoiceVisible,
  updateCreateInvoice,
  updateInvoiceItem,
  updateInvoiceItemError,
  updateInvoiceItemSuccess,
  updateInvoiceUI,
  updateTemporarySelectPayment,
} from './actions';
import { InvoiceReducer } from './types';
import { DEFAULT_LIMIT } from 'src/constants/app.constants';

const initialState: InvoiceReducer = {
  invoices: undefined,
  myInvoices: undefined,
};

const invoiceReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getInvoiceListSuccess, (state, { payload }) => {
      state.ui = {
        ...state.ui,
        loading: false,
        isShowPagination: payload.invoices?.length === (state.pagination?.limit ?? DEFAULT_LIMIT),
      };
      state.pagination = payload?.pagination;
      if ((state.invoices?.length ?? 0) > 0) {
        state.invoices = state.invoices?.concat(payload.invoices);
        return;
      }
      state.invoices = payload.invoices;
    })
    .addCase(getMyInvoiceListSuccess, (state, { payload }) => {
      state.ui = {
        ...state.ui,
        loading: false,
        isShowPagination: payload.myInvoices?.length === (state.pagination?.limit ?? DEFAULT_LIMIT),
      };
      state.pagination = payload?.pagination;
      if ((payload?.myInvoices?.length ?? 0) > 0) {
        state.myInvoices = state.myInvoices?.concat(payload.myInvoices);
        return;
      }
      state.myInvoices = payload.myInvoices;
    })
    .addCase(getInvoiceItemSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
      state.invoice = payload.invoice;
    })
    .addCase(addInvoiceItemSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, visible: false, loadingBtn: false };
      state.invoices = (payload?.invoice ? [payload?.invoice] : []).concat(state.invoices || []);
      state.createInvoice = undefined;
    })
    .addCase(updateInvoiceItemSuccess, (state, { payload }) => {
      state.ui = {
        ...state.ui,
        visible: false,
        loadingBtn: false,
        loading: false,
        key: state.ui?.key && +state.ui.key >= 0 ? state.ui.key + 1 : 1,
      };
      state.invoice = state.invoice
        ? { ...state.invoice, ...payload.invoice }
        : { ...payload.invoice };
    })
    .addCase(deleteInvoiceItemSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, visible: false, loadingBtn: false, loading: false };
      state.invoices = state.invoices?.filter((e) => e.id !== payload?.invoice?.id);
    })
    .addCase(setFilterInvoiceList, (state, { payload }) => {
      state.ui = { ...state.ui, loading: true };
      if (payload.isMyInvoice) {
        state.myFilterable = { ...state.myFilterable, ...payload.myFilterable };
      } else {
        state.filterable = { ...state.filterable, ...payload.filterable };
      }
    })
    .addCase(clearInvoiceList, (state, { payload }) => {
      state.ui = { ...state.ui, loading: true };
      if (payload?.isMyInvoice) {
        state.myInvoices = [];
      } else {
        state.invoices = [];
      }
    })
    .addCase(setInvoiceVisible, (state, { payload }) => {
      state.ui = { ...state.ui, ...payload };
    })
    .addCase(pushInvoiceItemToInvoice, (state, { payload }) => {
      const newInvoiceItems = (payload ? [payload] : []).concat(
        state?.invoice?.invoice_items || []
      );

      state.invoice = state?.invoice
        ? { ...state.invoice, invoice_items: [...newInvoiceItems] }
        : undefined;
    })
    .addCase(removeInvoiceItemToInvoice, (state, { payload }) => {
      state.invoice = state?.invoice
        ? {
            ...state.invoice,
            invoice_items: state?.invoice?.invoice_items?.filter((e) => e.id !== payload?.id),
          }
        : undefined;
    })
    .addCase(updateCreateInvoice, (state, { payload }) => {
      state.createInvoice = { ...state.createInvoice, ...payload };
    })
    .addCase(updateTemporarySelectPayment, (state, { payload }) => {
      state.temporarySelectPayment = payload;
    })
    .addCase(updateInvoiceUI, (state, { payload }) => {
      state.invoice = { ...state.invoice, ...payload };
    })
    .addCase(cancelledInvoiceItemError, (state, { payload }) => {
      state.ui = { ...state.ui, loadingBtn: false };
    })
    .addCase(cancelledInvoiceItemSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loadingBtn: false };
    })
    .addCase(addPaymentItemFromPayment, (state, { payload }) => {
      if (state.invoice) {
        const newPayments = state.invoice?.invoice_payments
          ? [payload, ...state.invoice.invoice_payments]
          : [];

        state.invoice = {
          ...state.invoice,
          total_paid: newPayments?.reduce((a, b) => a + b.cash_amount, 0),
          invoice_payments: [...newPayments],
        };
      }
    })
    .addCase(removePaymentItemFromPayment, (state, { payload }) => {
      if (state.invoice) {
        const newPayments =
          state.invoice?.invoice_payments?.filter((e) => e.id !== payload.id) ?? [];
        state.invoice = {
          ...state.invoice,
          invoice_payments: [...newPayments],
          total_paid: newPayments?.reduce((a, b) => a + b.cash_amount, 0),
        };
      }
    })
    .addCase(clearFieldInvoiceItem, (state) => {
      state.invoice = undefined;
    })
    .addCase(mapInvoiceToList, (state, { payload }) => {
      if (payload.isMyInvoice) {
        state.myInvoices = state.myInvoices?.map((e) =>
          e.id === payload.id ? { ...e, ...payload } : e
        );
      } else {
        state.invoices = state.invoices?.map((e) =>
          e.id === payload.id ? { ...e, ...payload } : e
        );
      }
    })
    .addMatcher(isAnyOf(getInvoiceList, getMyInvoiceList, getInvoiceItem), (state) => {
      state.ui = { ...state.ui, loading: true };
    })
    .addMatcher(
      isAnyOf(addInvoiceItem, updateInvoiceItem, deleteInvoiceItem, cancelledInvoiceItem),
      (state) => {
        state.ui = { ...state.ui, loadingBtn: true };
      }
    )
    .addMatcher(
      isAnyOf(
        getInvoiceListError,
        getMyInvoiceListError,
        getInvoiceItemError,
        addInvoiceItemError,
        updateInvoiceItemError,
        deleteInvoiceItemError
      ),
      (state) => {
        state.ui = { loading: false, loadingPage: false };
      }
    )
);

export default invoiceReducer;
