/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ReducerSaga } from 'src/types/saga.types';
import {
  ADD_INVENTORY_ITEM,
  ADD_INVENTORY_ITEM_ERROR,
  ADD_INVENTORY_ITEM_SUCCESS,
  CLEAR_INVENTORY_LIST,
  DELETE_INVENTORY_ITEM,
  DELETE_INVENTORY_ITEM_ERROR,
  DELETE_INVENTORY_ITEM_SUCCESS,
  GET_INVENTORY_ITEM,
  GET_INVENTORY_ITEM_ERROR,
  GET_INVENTORY_ITEM_SUCCESS,
  GET_INVENTORY_LIST,
  GET_INVENTORY_LIST_ERROR,
  GET_INVENTORY_LIST_SUCCESS,
  GET_INVENTORY_OPTIONS_LIST,
  GET_INVENTORY_OPTIONS_LIST_ERROR,
  GET_INVENTORY_OPTIONS_LIST_SUCCESS,
  IMPORT_INVENTORY_FILE,
  IMPORT_INVENTORY_FILE_ERROR,
  IMPORT_INVENTORY_FILE_SUCCESS,
  ROLL_BACK_INVENTORY,
  ROLL_BACK_INVENTORY_ERROR,
  ROLL_BACK_INVENTORY_SUCCESS,
  SET_FILTER_INVENTORY_LIST,
  SET_INVENTORY_VISIBLE,
  UPDATE_INVENTORY_ITEM,
  UPDATE_INVENTORY_ITEM_ERROR,
  UPDATE_INVENTORY_ITEM_SUCCESS,
} from './actions';
import { InventoryReducer } from './types';

const initialState: InventoryReducer = {
  inventories: undefined,
};

const inventoryReducer: ReducerSaga<InventoryReducer> = (
  state = initialState,
  payload
): InventoryReducer => {
  const { type, ...rest } = payload;
  switch (type) {
    case GET_INVENTORY_LIST:
      return { ...state, ...rest.payload, ui: { loadingOption: true, loading: true } };
    case GET_INVENTORY_OPTIONS_LIST:
    case GET_INVENTORY_ITEM:
      return { ...state, ...rest.payload, ui: { loadingOption: true } };

    case ADD_INVENTORY_ITEM:
    case UPDATE_INVENTORY_ITEM:
      return { ...state, ...rest.payload, ui: { loadingBtn: true, visible: true } };

    case DELETE_INVENTORY_ITEM:
      return { ...state, ...rest.payload, ui: { loadingBtn: true, loadingPage: true } };

    case IMPORT_INVENTORY_FILE:
      return { ...state, ...rest.payload, ui: { loadingBtn: true, loadingPage: true } };

    case ROLL_BACK_INVENTORY:
      return { ...state, ui: { loadingBtn: true, loadingPage: true } };

    case GET_INVENTORY_LIST_ERROR:
    case GET_INVENTORY_OPTIONS_LIST_ERROR:
    case GET_INVENTORY_ITEM_ERROR:
      showErrorToast();
      return {
        ...state,
        ui: { loading: false, loadingBtn: false, loadingPage: false, loadingOption: false },
      };

    case ADD_INVENTORY_ITEM_ERROR:
      return {
        ...state,
        ui: { loadingBtn: false, visible: true },
      };

    case DELETE_INVENTORY_ITEM_ERROR:
      showErrorToast();
      return {
        ...state,
        ui: { loadingBtn: false },
      };
    case UPDATE_INVENTORY_ITEM_ERROR:
    case IMPORT_INVENTORY_FILE_ERROR:
    case ROLL_BACK_INVENTORY_ERROR:
      showErrorToast();
      return {
        ...state,
        ui: { loading: false, loadingBtn: false, loadingPage: false, visible: true },
      };

    case GET_INVENTORY_LIST_SUCCESS:
      const { isPagination } = rest.payload?.pagination || {};
      return {
        ...state,
        ...rest.payload,
        inventories: isPagination
          ? (state.inventories || [])?.concat(rest.payload?.inventories || [])
          : rest.payload?.inventories,
        pagination: rest.payload?.pagination ? { ...rest.payload?.pagination } : undefined,
        ui: {
          loading: false,
          loadingBtn: false,
        },
      };

    case GET_INVENTORY_OPTIONS_LIST_SUCCESS:
      return {
        ...state,
        ...rest.payload,
        options:
          rest.payload?.options && rest.payload?.options?.length > 0
            ? [...rest.payload.options]
            : [],
        ui: {
          loading: false,
          loadingBtn: false,
          loadingOption: false,
        },
      };
    case GET_INVENTORY_ITEM_SUCCESS:
      return { ...state, ...rest.payload, ui: { loading: false } };
    case ADD_INVENTORY_ITEM_SUCCESS:
      showSuccessToast();
      return {
        ...state,
        ...rest.payload,
        inventories: (rest.payload?.inventory ? [rest.payload?.inventory] : []).concat(
          state.inventories || []
        ),
        ui: {},
      };
    case UPDATE_INVENTORY_ITEM_SUCCESS:
      showSuccessToast();
      return {
        ...state,
        ...rest.payload,
        inventories: state.inventories?.map((e) =>
          e.id === rest.payload?.inventory?.id ? { ...e, ...rest.payload.inventory } : e
        ),
        ui: {
          loading: false,
          loadingBtn: false,
          visible: false,
        },
      };
    case DELETE_INVENTORY_ITEM_SUCCESS:
      showSuccessToast();
      return {
        ...state,
        ...rest.payload,
        inventories: state.inventories?.filter((e) => e.id !== rest.payload?.inventory?.id),
        ui: {
          loading: false,
          loadingBtn: false,
        },
      };
    case IMPORT_INVENTORY_FILE_SUCCESS:
      showSuccessToast(
        'Import success. The process is processing in the background, please check again after 5 minutes'
      );
      return {
        ...state,
        ui: {
          loading: false,
          loadingBtn: false,
          loadingPage: false,
        },
      };
    case ROLL_BACK_INVENTORY_SUCCESS:
      showSuccessToast();
      return {
        ...state,
        ui: {
          loading: false,
          loadingBtn: false,
          loadingPage: false,
        },
      };

    case SET_FILTER_INVENTORY_LIST:
      return { ...state, filterable: rest.payload?.filterable, ui: { loading: true } };
    case CLEAR_INVENTORY_LIST:
      return { ...state, inventories: [], ui: { loading: false } };
    case SET_INVENTORY_VISIBLE:
      return { ...state, ...rest.payload };

    default:
      return { ...state };
  }
};

export default inventoryReducer;
