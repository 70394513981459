import { CBreadcrumb, CBreadcrumbItem, CButton, CButtonGroup, CCol, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/define/store-hooks.interface';
import { getInvoiceItem } from 'src/features/invoice/actions';
import html2pdf from 'html2pdf.js';
import { printSystemInvoiceAPI } from 'src/features/invoice/apis';
import PageLoading from 'src/components/common/loading/PageLoading';
import SendSMSButton from './components/SendSMSButton';
import Popconfirm from 'src/components/common/popconfirm/Popconfirm';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import InvoicePrint2 from './InvoicePrint2';
import InvoicePrint1 from './InvoicePrint1';
import useUserPermissions from '../permission/hooks/useUserPermissions';

type InvoicePrintProps = {
  isPublic?: boolean;
};

const InvoicePrint: React.FC<InvoicePrintProps> = ({ isPublic }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { invoice_id } = useParams();
  const { invoice, ui } = useAppSelector((s) => s.invoice);
  const [loadingPrint, setLoadingPrint] = useState<boolean>(false);
  const [isTemplate2, setIsTemplate2] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const template = searchParams.get('template');
  const { canSendSMSInvoice } = useUserPermissions();

  const { loading } = ui || {};

  useEffect(() => {
    if (invoice_id) {
      dispatch(getInvoiceItem({ id: invoice_id, isPublic }));
    }
  }, [invoice_id, isPublic, dispatch]);

  useEffect(() => {
    setIsTemplate2(template === '2');
  }, []);

  const handlePrintSystem = async () => {
    if (invoice?.id) {
      try {
        setLoadingPrint(true);
        const element = document.getElementsByClassName('for-print')?.[0];
        const blogFile = await html2pdf().from(element).toPdf().output('blob');
        const { data } = await printSystemInvoiceAPI({
          file: blogFile,
          id: invoice.id,
        });
        showSuccessToast('Print successfully');
      } catch (err) {
        showErrorToast('Print failed');
        console.error(err);
      } finally {
        setLoadingPrint(false);
      }
    }
  };

  const onChangeTemplate = (value: boolean) => {
    setIsTemplate2(value);
    setSearchParams(value ? '?template=2' : '');
  };

  return (
    <>
      <PageLoading loading={loadingPrint} />
      {isPublic && <br />}
      {!isPublic && (
        <CRow>
          <CCol xl={12} xxl={5}>
            <CBreadcrumb>
              <CBreadcrumbItem>{t('dashboard')}</CBreadcrumbItem>
              <CBreadcrumbItem href="#/invoices">{t('invoice')}</CBreadcrumbItem>
              <CBreadcrumbItem>{invoice?.truck?.code || invoice?.truck?.name}</CBreadcrumbItem>
              <CBreadcrumbItem href={'#/invoices/' + invoice_id}>{invoice_id}</CBreadcrumbItem>
              <CBreadcrumbItem active>{t('print')}</CBreadcrumbItem>
            </CBreadcrumb>
          </CCol>
          <CCol xl={12} xxl={7}>
            <div className="print-action text-right">
              {!isPublic && (
                <CButton
                  className="me-2"
                  variant="outline"
                  color="secondary"
                  href={`#/invoices/${invoice_id}`}
                >
                  <i className="icofont-arrow-left"></i> Back
                </CButton>
              )}

              <CButtonGroup className="me-2" aria-label="Template">
                <CButton
                  color={!isTemplate2 ? 'primary' : 'secondary'}
                  onClick={() => onChangeTemplate(false)}
                >
                  Template 1
                </CButton>
                <CButton
                  color={isTemplate2 ? 'primary' : 'secondary'}
                  onClick={() => onChangeTemplate(true)}
                >
                  Template 2
                </CButton>
              </CButtonGroup>

              <CButton
                className="me-2"
                variant="outline"
                color="primary"
                onClick={() => window.print()}
              >
                <i className="icofont-printer"></i> Device
              </CButton>

              <Popconfirm
                onConfirm={handlePrintSystem}
                message="Do you want to print in local system?"
                trigger={
                  <CButton className="me-2" variant="outline" color="info">
                    <i className="icofont-printer"></i> System
                  </CButton>
                }
              />

              {canSendSMSInvoice && invoice && !isPublic ? (
                <SendSMSButton invoice={invoice} />
              ) : null}
            </div>
          </CCol>
        </CRow>
      )}

      {isTemplate2 ? (
        <InvoicePrint2 invoice={invoice} loading={loading} />
      ) : (
        <InvoicePrint1 invoice={invoice} loading={loading} />
      )}
    </>
  );
};

export default React.memo(InvoicePrint);
