/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import {
  addInvoiceDraftItemError,
  addInvoiceDraftItemSuccess,
  cancelledInvoiceDraftItemError,
  cancelledInvoiceDraftItemSuccess,
  clearInvoiceDraftList,
  deleteInvoiceDraftItemError,
  deleteInvoiceDraftItemSuccess,
  getInvoiceDraftItem,
  getInvoiceDraftItemError,
  getInvoiceDraftItemSuccess,
  getInvoiceDraftListError,
  getInvoiceDraftListSuccess,
  getMyInvoiceDraftListError,
  getMyInvoiceDraftListSuccess,
  updateInvoiceDraftItemError,
  updateInvoiceDraftItemSuccess,
} from './actions';
import {
  addInvoiceDraftItemAPI,
  cancelledInvoiceDraftItemAPI,
  deleteInvoiceDraftItemAPI,
  getInvoiceDraftItemAPI,
  getInvoiceDraftManagerListAPI,
  getInvoiceDraftPersonalListAPI,
  updateInvoiceDraftItemAPI,
} from './apis';
import {
  CreateInvoiceDraft,
  InvoiceDraftAddBody,
  InvoiceDraftDeleteBody,
  InvoiceDraftItemPayload,
  InvoiceDraftListParams,
  InvoiceDraftUpdateBody,
} from './types';
import { sortByField } from 'src/helpers/common.helpers';

export function* getInvoiceDraftListWorker(action: ActionSaga<InvoiceDraftListParams>) {
  try {
    const { data }: SagaReturnType<typeof getInvoiceDraftManagerListAPI> = yield call(
      getInvoiceDraftManagerListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getInvoiceDraftListSuccess({
        invoiceDrafts: sortByField(data.data, ['date_time'], 'desc'),
        pagination: newPagination,
      })
    );
  } catch {
    showErrorToast();
    yield put(getInvoiceDraftListError());
  }
}

export function* getMyInvoiceDraftListWorker(action: ActionSaga<InvoiceDraftListParams>) {
  try {
    const { data }: SagaReturnType<typeof getInvoiceDraftPersonalListAPI> = yield call(
      getInvoiceDraftPersonalListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getMyInvoiceDraftListSuccess({
        myInvoiceDrafts: sortByField(data.data, ['date_time'], 'desc'),
        pagination: newPagination,
      })
    );
  } catch {
    showErrorToast();
    yield put(getMyInvoiceDraftListError());
  }
}

export function* setFilterInvoiceDraftListWorker(action: ActionSaga<InvoiceDraftListParams>) {
  if (action.payload?.isMyInvoiceDraft) {
    yield put(clearInvoiceDraftList({ isMyInvoiceDraft: true }));
    yield call(getMyInvoiceDraftListWorker, action);
  } else {
    yield put(clearInvoiceDraftList());
    yield call(getInvoiceDraftListWorker, action);
  }
}

export function* getInvoiceDraftItemWorker(action: ActionSaga<InvoiceDraftItemPayload>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getInvoiceDraftItemAPI> = yield call(
        getInvoiceDraftItemAPI,
        action.payload
      );
      yield put(
        getInvoiceDraftItemSuccess({
          invoiceDraft: {
            ...data.data,
            invoice_draft_items: data.data?.invoice_draft_items?.map((e) => ({
              ...e,
              author_id: e?.truck_cart?.author_id,
              user_id: e?.truck_cart?.user_id,
            })),
          },
        })
      );
    }
  } catch {
    yield put(getInvoiceDraftItemError());
    showErrorToast();
  }
}

/**
 *
 * Flow: 1. Create new invoice -> 2. Insert invoice item to new invoice -> 3. Done
 */
export function* addInvoiceDraftItemWorker(action: ActionSaga<CreateInvoiceDraft>) {
  try {
    if (action.payload) {
      const newInventories =
        action.payload.temporary_invoice_draft_items?.map((e) => ({
          id: e.inventory_id,
          name: e.item_name,
          price: e.item_price,
          quantity: e.item_quantity,
          discount: e.discount,
          truck_cart_id: e.truck_cart_id,
        })) || [];
      const newInvoiceDraft = {
        ...action.payload,
        tax: action.payload.tax ? +action.payload.tax : undefined,
        inventories: [...newInventories],
      } as InvoiceDraftAddBody;
      delete newInvoiceDraft.temporary_invoice_draft_items;
      const { data }: SagaReturnType<typeof addInvoiceDraftItemAPI> = yield call(
        addInvoiceDraftItemAPI,
        newInvoiceDraft
      );
      yield put(addInvoiceDraftItemSuccess({ invoiceDraft: data.data }));
      showSuccessToast();
      window.location.replace(`#/invoice-drafts/${data.data.id}`);
    }
  } catch (e) {
    yield put(addInvoiceDraftItemError());
    showErrorToast();
  }
}

export function* updateInvoiceDraftItemWorker(action: ActionSaga<InvoiceDraftUpdateBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateInvoiceDraftItemAPI> = yield call(
        updateInvoiceDraftItemAPI,
        action.payload
      );
      yield put(updateInvoiceDraftItemSuccess({ invoiceDraft: data.data }));

      if (action.payload.reload) {
        yield put(getInvoiceDraftItem({ id: data.data.id }));
      }

      if (action.payload.callback) {
        action.payload.callback();
      }
      showSuccessToast();
    }
  } catch {
    yield put(updateInvoiceDraftItemError());
    showErrorToast();
  }
}

export function* deleteInvoiceDraftItemWorker(action: ActionSaga<InvoiceDraftDeleteBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof deleteInvoiceDraftItemAPI> = yield call(
        deleteInvoiceDraftItemAPI,
        action.payload
      );
      yield put(
        deleteInvoiceDraftItemSuccess({ invoiceDraft: { ...data.data, id: action.payload.id } })
      );
      showSuccessToast();
      window.location.replace(`#/invoice-drafts`);
    }
  } catch {
    yield put(deleteInvoiceDraftItemError());
    showErrorToast();
  }
}

export function* cancelledInvoiceDraftItemWorker(action: ActionSaga<InvoiceDraftDeleteBody>) {
  try {
    if (action.payload?.id) {
      const { data }: SagaReturnType<typeof cancelledInvoiceDraftItemAPI> = yield call(
        cancelledInvoiceDraftItemAPI,
        action.payload
      );
      showSuccessToast();
      if (action.payload?.callback) {
        action.payload.callback();
      }
    }
  } catch {
    yield put(cancelledInvoiceDraftItemError());
    showErrorToast();
  }
}

// logic CRUD flow
// link tham khảo: https://redux-saga.js.org/docs/advanced/NonBlockingCalls
