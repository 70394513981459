/**
 * For process asynchronous
 * The apis fetch
 */

import { ResponseStructure } from 'src/types/axios.types';
import { CustomerReturnItem, CustomerReturnItemBody } from './types';
import { axiosQuery } from 'src/services/axiosQuery';

const rootAPI = 'customer-return-items';

export const createCustomerReturnItemAPI = (payload: CustomerReturnItemBody) => {
  return axiosQuery.post<ResponseStructure<CustomerReturnItem>>(rootAPI, payload);
};

export const updateCustomerReturnItemAPI = (payload: CustomerReturnItemBody) => {
  return axiosQuery.put<ResponseStructure<CustomerReturnItem>>(rootAPI + '/' + payload.id, payload);
};

export const deleteCustomerReturnItemAPI = (payload: CustomerReturnItemBody) => {
  return axiosQuery.delete<ResponseStructure<CustomerReturnItem>>(rootAPI + '/' + payload.id);
};
