import { CFormFeedback, CFormInput } from '@coreui/react';
import { CFormInputProps } from '@coreui/react/dist/components/form/CFormInput';
import React, { useEffect, useRef, useState } from 'react';
import { formatPhoneNumber, revertPhoneNumber } from 'src/helpers/common.helpers';

type PhoneNumberInputProps = CFormInputProps & {
  onChangeInput?: (value?: string | null) => void;
};

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  onChangeInput,
  defaultValue,
  value,
  ...inputProps
}) => {
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [key, setKey] = useState<number>(0);
  const inputReference = useRef<HTMLInputElement>(null);

  const handleInput = (value?: string | null) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(value);
    // we'll set the input value using our setInputValue
    setInputValue(formattedPhoneNumber || '');
    onChangeInput?.(revertPhoneNumber(value));
    setKey(key + 1);
  };

  useEffect(() => {
    if (defaultValue) handleInput(defaultValue as string);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (key !== 0) inputReference?.current?.focus();
  }, [key]);

  return (
    <>
      <CFormInput
        key={key}
        ref={inputReference}
        {...inputProps}
        className="form-control"
        placeholder="Enter a phone number"
        onChange={(e) => handleInput(e.target.value)}
        value={inputValue}
      />
      <CFormFeedback invalid>Phone number include 10 characters.</CFormFeedback>
    </>
  );
};

export default React.memo(PhoneNumberInput);
