import { spawn } from 'redux-saga/effects';
import { adminSaga } from './features/admin/saga';
import { authenticationSaga } from './features/authentication/saga';
import { categorySaga } from './features/category/saga';
import { currentUserSaga } from './features/currentUser/saga';
import { customerSaga } from './features/customer/saga';
import { dashboardSaga } from './features/dashboard/saga';
import { giftCardSaga } from './features/giftCard/saga';
import { inventorySaga } from './features/inventory/saga';
import { invoiceSaga } from './features/invoice/saga';
import { invoiceItemSaga } from './features/invoiceItem/saga';
import layoutSaga from './features/layout/saga';
import { loadInSaga } from './features/loadIn/saga';
import { loadOutSaga } from './features/loadOut/saga';
import { requestOrderSaga } from './features/requestOrder/saga';
import settingSaga from './features/setting/saga';
import { truckSaga } from './features/truck/saga';
import { truckCartSaga } from './features/truckCart/saga';
import TruckDeliverySaga from './features/truckDeliveries/saga';
import { userSaga } from './features/user/saga';
import { requestOrderItemSaga } from './features/requestOrderItem/saga';
import { invoicePaymentSaga } from './features/invoicePayment/saga';
import { warehouseReturnSaga } from './features/warehouseReturn/saga';
import { warehouseReturnItemSaga } from './features/warehouseReturnItem/saga';
import { customerReturnSaga } from './features/customerReturn/saga';
import { customerReturnItemSaga } from './features/customerReturnItem/saga';
import { permissionSaga } from './features/permission/saga';
import { invoiceDraftItemSaga } from './features/invoiceDraftItem/saga';
import { invoiceDraftSaga } from './features/invoiceDraft/saga';

export default function* rootSaga() {
  yield spawn(layoutSaga);
  yield spawn(authenticationSaga);
  yield spawn(truckSaga);
  yield spawn(userSaga);
  yield spawn(inventorySaga);
  yield spawn(categorySaga);
  yield spawn(customerSaga);
  yield spawn(truckCartSaga);
  yield spawn(invoiceSaga);
  yield spawn(invoiceItemSaga);
  yield spawn(settingSaga);
  yield spawn(dashboardSaga);
  yield spawn(loadInSaga);
  yield spawn(loadOutSaga);
  yield spawn(giftCardSaga);
  yield spawn(TruckDeliverySaga);
  yield spawn(adminSaga);
  yield spawn(currentUserSaga);
  yield spawn(requestOrderSaga);
  yield spawn(requestOrderItemSaga);
  yield spawn(invoicePaymentSaga);
  yield spawn(warehouseReturnSaga);
  yield spawn(warehouseReturnItemSaga);
  yield spawn(customerReturnSaga);
  yield spawn(customerReturnItemSaga);
  yield spawn(permissionSaga);
  yield spawn(invoiceDraftSaga);
  yield spawn(invoiceDraftItemSaga);
}
