/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer } from '@reduxjs/toolkit';
import {
  currentUserError,
  getCurrentUserTruckCart,
  getCurrentUserTruckCartSuccess,
  increaseKeyCurrentUser,
} from './actions';
import { CurrentUserReducer } from './types';

const INITIAL_STATE = {};

const currentUserReducer = createReducer<CurrentUserReducer>(INITIAL_STATE, (builder) => {
  builder.addCase(getCurrentUserTruckCart, (state, { payload }) => {
    state.ui = { ...state?.ui, loading: false };
  });
  builder.addCase(getCurrentUserTruckCartSuccess, (state, { payload }) => {
    state.ui = { ...state?.ui, loading: false };
    state.myTruckCarts = [...payload];
  });
  builder.addCase(increaseKeyCurrentUser, (state) => {
    state.ui = { ...state?.ui, key: (state?.ui?.key ?? 0) + 1 };
  });
  builder.addCase(currentUserError, (state, { payload }) => {
    state.ui = {};
  });
});

export default currentUserReducer;
