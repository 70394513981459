/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import { getCurrentUser, GET_CURRENT_USER } from '../authentication/actions';
import { addTruckCartItem } from '../truckCart/actions';
import { TruckCart, TruckCartAddBody } from '../truckCart/types';
import {
  createTruckDeliveryError,
  createTruckDeliverySuccess,
  deleteTruckDeliveryError,
  deleteTruckDeliverySuccess,
  getCurrentTruckDeliveryError,
  getCurrentTruckDeliverySuccess,
  updateTruckDeliveryError,
  updateTruckDeliverySuccess,
} from './actions';
import {
  createTruckDeliveryAPI,
  deleteTruckDeliveryAPI,
  getCurrentTruckDeliveryAPI,
  updateTruckDeliveryAPI,
} from './apis';
import { TruckDeliveryBody, TruckDeliveryParams } from './types';

export function* getCurrentTruckDeliveryWorker(action: ActionSaga<TruckDeliveryParams>) {
  try {
    if (!action.payload) throw new Error('Payload is required');
    const { data }: SagaReturnType<typeof getCurrentTruckDeliveryAPI> = yield call(
      getCurrentTruckDeliveryAPI,
      action.payload || {}
    );
    yield put(getCurrentTruckDeliverySuccess(data.data));
  } catch (e) {
    yield put(getCurrentTruckDeliveryError());
    console.error(e);
  }
}

export function* createTruckDeliveryWorker(action: ActionSaga<TruckDeliveryBody>) {
  try {
    if (!action.payload) throw new Error('Payload is required');
    const { data }: SagaReturnType<typeof createTruckDeliveryAPI> = yield call(
      createTruckDeliveryAPI,
      action.payload || {}
    );
    yield put(createTruckDeliverySuccess(data.data));
    showSuccessToast();
    window.location.replace(`#/load-in-to-truck?truck_id=${data.data?.truck_id}`);
  } catch (e) {
    showErrorToast();
    yield put(createTruckDeliveryError());
    console.error(e);
  }
}

export function* updateTruckDeliveryWorker(action: ActionSaga<TruckDeliveryBody & { id: string }>) {
  try {
    if (!action.payload) throw new Error('Payload is required');
    const { data }: SagaReturnType<typeof updateTruckDeliveryAPI> = yield call(
      updateTruckDeliveryAPI,
      action.payload || {}
    );
    yield put(updateTruckDeliverySuccess(data.data));
    if (data?.data?.new_truck_delivery) {
      window.location.replace(
        `#/load-in-to-truck?truck_id=${data?.data?.new_truck_delivery.truck_id}`
      );
    }
    if (data.data?.is_complete_unload === 1) {
      yield put(getCurrentUser());
    }
    showSuccessToast();
  } catch (e) {
    showErrorToast();
    yield put(updateTruckDeliveryError());
    console.error(e);
  }
}

export function* deleteTruckDeliveryWorker(action: ActionSaga<TruckDeliveryBody & { id: string }>) {
  try {
    if (!action.payload) throw new Error('Payload is required');
    yield call(deleteTruckDeliveryAPI, action.payload || {});
    yield put(deleteTruckDeliverySuccess());
    showSuccessToast();
  } catch (e) {
    showErrorToast();
    yield put(deleteTruckDeliveryError());
    console.error(e);
  }
}

export function* createTruckDeliveryAndAddInventoryWorker(
  action: ActionSaga<TruckDeliveryBody & { truckCart: TruckCartAddBody }>
) {
  try {
    if (!action.payload) throw new Error('Payload is required');
    const { truckCart, callback } = action.payload;
    const { data }: SagaReturnType<typeof createTruckDeliveryAPI> = yield call(
      createTruckDeliveryAPI,
      action.payload || {}
    );
    document.querySelector<HTMLElement>('.rbt-close')?.click();
    yield put(createTruckDeliverySuccess(data.data));
    yield put(addTruckCartItem({ ...truckCart, truck_delivery_id: data.data.id }));
    yield put(getCurrentUser());
  } catch (e) {
    showErrorToast();
    yield put(createTruckDeliveryError());
    console.error(e);
  }
}
