/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import { CustomerReturn, CustomerReturnBody } from './types';

const rootAPI = 'customer-returns';

export const getListCustomerReturnAPI = (payload?: CustomerReturnBody | null) => {
  return axiosQuery.get<ResponseStructure<CustomerReturn[]>>(rootAPI, { params: payload });
};

export const getCustomerReturnTruckDeliveryListAPI = (payload?: CustomerReturnBody | null) => {
  if (!payload?.truck_delivery_id) {
    throw new Error('truck delivery id is required');
  }
  return axiosQuery.get<ResponseStructure<CustomerReturn[]>>(
    rootAPI + '/truck-delivery/' + payload.truck_delivery_id,
    { params: payload }
  );
};

export const getCustomerReturnAPI = (payload: CustomerReturnBody) => {
  return axiosQuery.get<ResponseStructure<CustomerReturn>>(rootAPI + '/' + payload.id);
};

export const createCustomerReturnAPI = (payload: CustomerReturnBody) => {
  return axiosQuery.post<ResponseStructure<CustomerReturn>>(rootAPI, payload);
};

export const updateCustomerReturnAPI = (payload: CustomerReturnBody) => {
  return axiosQuery.put<ResponseStructure<CustomerReturn>>(rootAPI + '/' + payload.id, payload);
};

export const deleteCustomerReturnAPI = (payload: CustomerReturnBody) => {
  return axiosQuery.delete<ResponseStructure<CustomerReturn>>(rootAPI + '/' + payload.id);
};
