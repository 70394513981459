/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import { Dashboard } from './types';

const name = 'dashboards';

export const getDashboardAPI = () => {
  return axiosQuery.get<ResponseStructure<Dashboard>>(name);
};
