/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import { WarehouseReturn, WarehouseReturnBody } from './types';

const rootAPI = 'warehouse-returns';

export const getListWarehouseReturnAPI = (payload?: WarehouseReturnBody | null) => {
  return axiosQuery.get<ResponseStructure<WarehouseReturn[]>>(rootAPI, { params: payload });
};

export const getWarehouseReturnTruckDeliveryListAPI = (payload?: WarehouseReturnBody | null) => {
  if (!payload?.truck_delivery_id) {
    throw new Error('truck delivery id is required');
  }
  return axiosQuery.get<ResponseStructure<WarehouseReturn[]>>(
    rootAPI + '/truck-delivery/' + payload.truck_delivery_id,
    { params: payload }
  );
};

export const getWarehouseReturnAPI = (payload: WarehouseReturnBody) => {
  return axiosQuery.get<ResponseStructure<WarehouseReturn>>(rootAPI + '/' + payload.id);
};

export const createWarehouseReturnAPI = (payload: WarehouseReturnBody) => {
  return axiosQuery.post<ResponseStructure<WarehouseReturn>>(rootAPI, payload);
};

export const updateWarehouseReturnAPI = (payload: WarehouseReturnBody) => {
  return axiosQuery.put<ResponseStructure<WarehouseReturn>>(rootAPI + '/' + payload.id, payload);
};

export const deleteWarehouseReturnAPI = (payload: WarehouseReturnBody) => {
  return axiosQuery.delete<ResponseStructure<WarehouseReturn>>(rootAPI + '/' + payload.id);
};
