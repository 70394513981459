/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, race, SagaReturnType, select } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import { UserAuth } from 'src/types/user.types';
import { UserUpdatePasswordBody, UserUpdateProfileBody } from '../user/types';
import {
  getCurrentUser,
  getCurrentUserError,
  getCurrentUserSuccess,
  loginErrorAction,
  loginSuccessAction,
  updatePasswordErrorAction,
  updatePasswordSuccessAction,
  updateProfileErrorAction,
  updateProfileSuccessAction,
} from './actions';
import { getCurrentUserApi, loginApi, updatePasswordApi, updateProfileApi } from './apis';

const REACT_APP_STORED = process.env?.REACT_APP_STORED || '';

export function* loginWorker(action: ActionSaga<UserAuth>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof loginApi> = yield call(loginApi, action.payload);
      yield put(loginSuccessAction(data));
      if (data?.token) {
        localStorage.setItem(REACT_APP_STORED, data.token);
      }
      showSuccessToast('Login success');
    }
  } catch (e) {
    yield put(loginErrorAction());
    showErrorToast('Please check username and password');
    console.error('login error', e);
  }
}

export function logoutWorker() {
  try {
    localStorage.removeItem(REACT_APP_STORED);
    showSuccessToast('Logout success');
  } catch (e) {
    showErrorToast('Logout error');
    console.error('logout error', e);
  }
}

export function* getCurrentUserWorker() {
  try {
    const token = localStorage.getItem(REACT_APP_STORED);
    if (token) {
      const { data }: SagaReturnType<typeof getCurrentUserApi> = yield call(getCurrentUserApi);
      yield put(getCurrentUserSuccess({ user: data }));
    }
  } catch (e) {
    showErrorToast();
    yield put(getCurrentUserError());
    console.error('logout error', e);
  }
}

export function* updatePasswordWorker(action: ActionSaga<UserUpdatePasswordBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updatePasswordApi> = yield call(
        updatePasswordApi,
        action.payload
      );
      yield put(updatePasswordSuccessAction(data));
      showSuccessToast('Update password success');
    }
  } catch (e) {
    yield put(updatePasswordErrorAction());
    showErrorToast('Update password error');
    console.error('error', e);
  }
}

export function* updateProfileWorker(action: ActionSaga<UserUpdateProfileBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateProfileApi> = yield call(
        updateProfileApi,
        action.payload
      );
      yield put(updateProfileSuccessAction({ user: data.data }));
      showSuccessToast('Update profile success');
    }
  } catch (e) {
    yield put(updateProfileErrorAction());
    showErrorToast('Update profile error');
    console.error('error', e);
  }
}
