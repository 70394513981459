import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableFoot,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react';
import React from 'react';
import OverlayLoading from 'src/components/common/loading/OverlayLoading';
import { Invoice } from 'src/features/invoice/types';
import { convertToCurrency, numberToRound, sumByField } from 'src/helpers/common.helpers';
import { PAYMENT_TYPE_ENUM } from '../constants/enum.constants';

type TablePrint2Props = {
  invoice?: Invoice;
  loading?: boolean;
  hiddenTotal?: boolean;
};

const TablePrint2: React.FC<TablePrint2Props> = ({ invoice, loading, hiddenTotal }) => {
  return (
    <OverlayLoading loading={loading}>
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col" style={{ width: '5%' }}>
              #
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" style={{ width: '55%' }}>
              Item Name
            </CTableHeaderCell>
            <CTableHeaderCell className="text-right" scope="col" style={{ width: '10%' }}>
              Qty
            </CTableHeaderCell>
            <CTableHeaderCell className="text-right" scope="col" style={{ width: '15%' }}>
              Sale Price
            </CTableHeaderCell>
            <CTableHeaderCell className="text-right" scope="col" style={{ width: '15%' }}>
              Total
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {invoice?.invoice_items?.map((item, index) => (
            <CTableRow key={item.id}>
              <CTableHeaderCell scope="row">{index + 1}</CTableHeaderCell>
              <CTableDataCell>
                <span className="two-line">{item?.item_name}</span>
              </CTableDataCell>
              <CTableDataCell className="text-right">{item.item_quantity}</CTableDataCell>
              <CTableDataCell className="text-right">
                {convertToCurrency(item.item_price)}
              </CTableDataCell>
              <CTableDataCell className="text-right">
                {convertToCurrency(item.total_price || 0)}
              </CTableDataCell>
            </CTableRow>
          ))}
          {invoice?.invoice_items?.length === 0 && (
            <CTableRow>
              <CTableDataCell colSpan={5}>Empty</CTableDataCell>
            </CTableRow>
          )}
        </CTableBody>
      </CTable>

      {!hiddenTotal && (
        <CTable className="mt-3">
          <CTableBody>
            <CTableRow>
              <CTableHeaderCell scope="row" style={{ width: '15%' }}>
                Sub-Total
              </CTableHeaderCell>
              <CTableDataCell style={{ width: '45%' }}></CTableDataCell>
              <CTableDataCell className="text-right" style={{ width: '10%' }}>
                {sumByField(invoice?.invoice_items ?? [], 'item_quantity')}
              </CTableDataCell>
              <CTableDataCell className="text-right" style={{ width: '15%' }}></CTableDataCell>
              <CTableHeaderCell scope="row" className="text-right" style={{ width: '15%' }}>
                {convertToCurrency(sumByField(invoice?.invoice_items ?? [], 'total_price'))}
              </CTableHeaderCell>
            </CTableRow>

            <CTableRow>
              <CTableHeaderCell scope="row" style={{ width: '15%' }}>
                Grand-Total
              </CTableHeaderCell>
              <CTableDataCell style={{ width: '45%' }}></CTableDataCell>
              <CTableDataCell className="text-right" style={{ width: '10%' }}></CTableDataCell>
              <CTableDataCell className="text-right" style={{ width: '15%' }}></CTableDataCell>
              <CTableHeaderCell scope="row" className="text-right" style={{ width: '15%' }}>
                {convertToCurrency(+(invoice?.total ?? 0))}
              </CTableHeaderCell>
            </CTableRow>

            <CTableRow>
              <CTableHeaderCell scope="row">Total Paid</CTableHeaderCell>
              <CTableDataCell>
                {[
                  ...new Set(
                    invoice?.invoice_payments
                      ?.filter((payment) => PAYMENT_TYPE_ENUM[payment.type] !== 'Due')
                      ?.map((payment) => PAYMENT_TYPE_ENUM[payment.type])
                  ),
                ]?.join(', ')}
              </CTableDataCell>

              <CTableDataCell className="text-right"></CTableDataCell>
              <CTableDataCell className="text-right"></CTableDataCell>
              <CTableHeaderCell scope="row" className="text-right">
                {convertToCurrency(sumByField(invoice?.invoice_payments ?? [], 'cash_amount'))}
              </CTableHeaderCell>
            </CTableRow>

            <CTableRow>
              <CTableHeaderCell scope="row">Remaining</CTableHeaderCell>
              <CTableDataCell></CTableDataCell>
              <CTableDataCell className="text-right"></CTableDataCell>
              <CTableDataCell className="text-right"></CTableDataCell>
              <CTableHeaderCell scope="row" className="text-right">
                {convertToCurrency(
                  numberToRound(
                    +(invoice?.total ?? 0) -
                      sumByField(invoice?.invoice_payments ?? [], 'cash_amount')
                  )
                )}
              </CTableHeaderCell>
            </CTableRow>
          </CTableBody>
        </CTable>
      )}
    </OverlayLoading>
  );
};

export default React.memo(TablePrint2);
