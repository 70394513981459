/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import { RequestOrder, RequestOrderBody, RequestOrderParams } from './types';

const rootAPI = 'request-orders';

export const getRequestOrderPersonalListAPI = (payload?: RequestOrderParams) => {
  return axiosQuery.get<ResponseStructure<RequestOrder[]>>(rootAPI + '/by-user', {
    params: { sort: 'date_time', ...payload },
  });
};

export const getRequestOrderManagerListAPI = (payload?: RequestOrderParams) => {
  return axiosQuery.get<ResponseStructure<RequestOrder[]>>(rootAPI, {
    params: { sort: 'date_time', ...payload },
  });
};

export const getRequestOrderItemAPI = (payload?: RequestOrderParams) => {
  return axiosQuery.get<ResponseStructure<RequestOrder>>(`${rootAPI}/${payload?.id}`);
};

export const createRequestOrderAPI = (payload?: RequestOrderBody) => {
  return axiosQuery.post<ResponseStructure<RequestOrder>>(rootAPI, {
    ...payload,
    discount: payload?.discount || undefined,
  });
};

export const addToTruckRequestOrderAPI = (payload?: RequestOrderBody) => {
  if (!payload?.truck_id || !payload?.id)
    throw new Error('Truck id or request order id must be provided');
  return axiosQuery.post<ResponseStructure<RequestOrder>>(rootAPI + `/${payload.id}/add-to-truck`, {
    ...payload,
  });
};

export const revertTruckRequestOrderAPI = (payload?: RequestOrderBody) => {
  if (!payload?.id) throw new Error('Request order id must be provided');
  return axiosQuery.post<ResponseStructure<RequestOrder>>(
    rootAPI + `/${payload.id}/revert-add-to-truck`,
    {
      ...payload,
    }
  );
};

export const createInvoiceRequestOrderAPI = (payload?: RequestOrderBody) => {
  if (!payload?.id) throw new Error('Request order id must be provided');
  return axiosQuery.post<ResponseStructure<RequestOrder>>(
    rootAPI + `/${payload.id}/create-invoice`,
    {
      ...payload,
    }
  );
};

export const revertInvoiceRequestOrderAPI = (payload?: RequestOrderBody) => {
  if (!payload?.id) throw new Error('Request order id must be provided');
  return axiosQuery.post<ResponseStructure<RequestOrder>>(
    rootAPI + `/${payload.id}/revert-invoice`,
    {
      ...payload,
    }
  );
};

export const updateRequestOrderAPI = (payload?: RequestOrderBody) => {
  return axiosQuery.put<ResponseStructure<RequestOrder>>(`${rootAPI}/${payload?.id}`, payload);
};

export const deleteRequestOrderAPI = (payload?: RequestOrderBody) => {
  return axiosQuery.delete<ResponseStructure<RequestOrder>>(`${rootAPI}/${payload?.id}`);
};
