/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, delay, put, SagaReturnType, select } from 'redux-saga/effects';
import { ActionSaga } from 'src/types/saga.types';
import {
  addInventoryItemError,
  addInventoryItemSuccess,
  clearInventoryList,
  deleteInventoryItemError,
  deleteInventoryItemSuccess,
  getInventoryItemError,
  getInventoryItemSuccess,
  getInventoryListError,
  getInventoryListSuccess,
  getInventoryOptionsListError,
  getInventoryOptionsListSuccess,
  importInventoryFileError,
  importInventoryFileSuccess,
  rollBackInventoryError,
  rollBackInventorySuccess,
  updateInventoryItemError,
  updateInventoryItemSuccess,
} from './actions';
import {
  addInventoryItemAPI,
  deleteInventoryItemAPI,
  getInventoryItemAPI,
  getInventoryListAPI,
  importInventoryFileAPI,
  rollBackInventoryAPI,
  updateInventoryItemAPI,
} from './apis';
import {
  InventoryAddBody,
  InventoryDeleteBody,
  InventoryImportBody,
  InventoryItemPayload,
  InventoryListParams,
  InventoryUpdateBody,
} from './types';
import { showErrorToast } from 'src/helpers/toast.helpers';
import {
  checkEmptyObject,
  getCachedDataStorage,
  setCachedDataStorage,
} from 'src/helpers/common.helpers';

export function* getInventoryListWorker(action: ActionSaga<InventoryListParams>) {
  try {
    const { data }: SagaReturnType<typeof getInventoryListAPI> = yield call(
      getInventoryListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getInventoryListSuccess({
        inventories: data.data,
        pagination: newPagination,
      })
    );

    if (action.payload?.callback) {
      action.payload.callback(data.data[0]);
    }
  } catch {
    yield put(getInventoryListError());
  }
}

export function* getInventoryOptionsListWorker(action: ActionSaga<InventoryListParams>) {
  try {
    const sessionStorageData = getCachedDataStorage('inventoryTypeahead');

    if (!checkEmptyObject(sessionStorageData)) {
      yield put(
        getInventoryOptionsListSuccess({
          options: sessionStorageData,
        })
      );
      return;
    }

    const { data }: SagaReturnType<typeof getInventoryListAPI> = yield call(
      getInventoryListAPI,
      action.payload ?? undefined
    );

    if (data) {
      setCachedDataStorage(
        data.data.map((e) => e.name + (e.item_code ? `|${e.item_code}` : '')),
        'inventoryTypeahead'
      );
    }

    yield put(
      getInventoryOptionsListSuccess({
        options: data.data.map((e) => e.name + (e.item_code ? `|${e.item_code}` : '')),
      })
    );
  } catch {
    yield put(getInventoryOptionsListError());
  }
}

export function* setFilterInventoryListWorker(action: ActionSaga<InventoryListParams>) {
  yield call(clearInventoryList);
  yield delay(1000);
  yield call(getInventoryListWorker, action);
}

export function* getInventoryItemWorker(action: ActionSaga<InventoryItemPayload>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getInventoryItemAPI> = yield call(
        getInventoryItemAPI,
        action.payload
      );
      yield put(getInventoryItemSuccess({ inventory: data.data }));
    }
  } catch {
    yield put(getInventoryItemError());
  }
}

export function* addInventoryItemWorker(action: ActionSaga<InventoryAddBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof addInventoryItemAPI> = yield call(
        addInventoryItemAPI,
        action.payload
      );
      yield put(addInventoryItemSuccess({ inventory: data.data }));
    }
  } catch (e) {
    showErrorToast(e?.response?.data?.message);
    yield put(addInventoryItemError());
  }
}

export function* addMultipleInventoryItemWorker(action: ActionSaga<InventoryAddBody>) {
  // for (let i = 10; i <= 500; i++) {
  //   try {
  //     if (action.payload) {
  //       const { data }: SagaReturnType<typeof addInventoryItemAPI> = yield call(addInventoryItemAPI, {
  //         name: action.payload.name + '_' + i,
  //       });
  //       yield put(addInventoryItemSuccess({ inventory: data.data }));
  //     }
  //   } catch {
  //     yield put(addInventoryItemError());
  //   }
  // }
}

export function* updateInventoryItemWorker(action: ActionSaga<InventoryUpdateBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateInventoryItemAPI> = yield call(
        updateInventoryItemAPI,
        action.payload
      );
      yield put(updateInventoryItemSuccess({ inventory: data.data }));
    }
  } catch (err) {
    showErrorToast(err?.response?.data?.message);
    yield put(updateInventoryItemError());
  }
}

export function* deleteInventoryItemWorker(action: ActionSaga<InventoryDeleteBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof deleteInventoryItemAPI> = yield call(
        deleteInventoryItemAPI,
        action.payload
      );
      yield put(deleteInventoryItemSuccess({ inventory: { ...data.data, id: action.payload.id } }));
      window.location.replace(`#/inventories`);
    }
  } catch {
    yield put(deleteInventoryItemError());
  }
}

export function* importInventoryItemWorker(action: ActionSaga<InventoryImportBody>) {
  try {
    if (action.payload) {
      yield call(importInventoryFileAPI, action.payload);
      if (action.payload?.callback) {
        action.payload.callback();
      }
      yield put(importInventoryFileSuccess());
    }
  } catch {
    yield put(importInventoryFileError());
  }
}

export function* rollbackInventoryWorker(action: ActionSaga<InventoryImportBody>) {
  try {
    yield call(rollBackInventoryAPI);
    yield put(rollBackInventorySuccess());
  } catch {
    yield put(rollBackInventoryError());
  }
}

// logic CRUD flow
// link tham khảo: https://redux-saga.js.org/docs/advanced/NonBlockingCalls
