/**
 * The event send to the store
 */

import { ActionSaga } from 'src/types/saga.types';
import {
  CategoryAddBody,
  CategoryDeleteBody,
  CategoryFilterable,
  CategoryItemPayload,
  CategoryItemSuccessPayload,
  CategoryListParams,
  CategoryListSuccessPayload,
  CategoryUpdateBody,
} from './types';

export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_LIST_ERROR = 'GET_CATEGORY_LIST_ERROR';
export const SET_FILTER_CATEGORY_LIST = 'SET_FILTER_CATEGORY_LIST';
export const CLEAR_CATEGORY_LIST = 'CLEAR_CATEGORY_LIST';

export const GET_CATEGORY_ITEM = 'GET_CATEGORY_ITEM';
export const GET_CATEGORY_ITEM_SUCCESS = 'GET_CATEGORY_ITEM_SUCCESS';
export const GET_CATEGORY_ITEM_ERROR = 'GET_CATEGORY_ITEM_ERROR';

export const ADD_CATEGORY_ITEM = 'ADD_CATEGORY_ITEM';
export const ADD_MULTIPLE_CATEGORY_ITEM = 'ADD_MULTIPLE_CATEGORY_ITEM';
export const ADD_CATEGORY_ITEM_SUCCESS = 'ADD_CATEGORY_ITEM_SUCCESS';
export const ADD_CATEGORY_ITEM_ERROR = 'ADD_CATEGORY_ITEM_ERROR';

export const UPDATE_CATEGORY_ITEM = 'UPDATE_CATEGORY_ITEM';
export const UPDATE_CATEGORY_ITEM_SUCCESS = 'UPDATE_CATEGORY_ITEM_SUCCESS';
export const UPDATE_CATEGORY_ITEM_ERROR = 'UPDATE_CATEGORY_ITEM_ERROR';

export const DELETE_CATEGORY_ITEM = 'DELETE_CATEGORY_ITEM';
export const DELETE_CATEGORY_ITEM_SUCCESS = 'DELETE_CATEGORY_ITEM_SUCCESS';
export const DELETE_CATEGORY_ITEM_ERROR = 'DELETE_CATEGORY_ITEM_ERROR';

export const getCategoryList = (payload?: CategoryListParams): ActionSaga<CategoryListParams> => ({
  type: GET_CATEGORY_LIST,
  payload: payload ? { ...payload } : null,
});

export const getCategoryListSuccess = (
  payload: CategoryListSuccessPayload
): ActionSaga<CategoryListSuccessPayload> => ({
  type: GET_CATEGORY_LIST_SUCCESS,
  payload: { ...payload },
});

export const getCategoryListError = (): ActionSaga => ({
  type: GET_CATEGORY_LIST_ERROR,
});

export const setFilterCategoryList = (
  payload?: CategoryFilterable
): ActionSaga<CategoryListParams> => ({
  type: SET_FILTER_CATEGORY_LIST,
  payload: { filterable: { ...payload } },
});

export const clearCategoryList = (): ActionSaga<CategoryListSuccessPayload> => ({
  type: CLEAR_CATEGORY_LIST,
  payload: { categories: [] },
});

export const getCategoryItem = (payload: CategoryItemPayload): ActionSaga<CategoryItemPayload> => ({
  type: GET_CATEGORY_ITEM,
  payload: { ...payload },
});

export const getCategoryItemSuccess = (
  payload: CategoryItemSuccessPayload
): ActionSaga<CategoryItemSuccessPayload> => ({
  type: GET_CATEGORY_ITEM_SUCCESS,
  payload: { ...payload },
});

export const getCategoryItemError = (): ActionSaga => ({
  type: GET_CATEGORY_ITEM_ERROR,
});

export const addCategoryItem = (payload: CategoryAddBody): ActionSaga<CategoryAddBody> => ({
  type: ADD_CATEGORY_ITEM,
  payload: { ...payload },
});

export const addMultipleCategoryItem = (payload: CategoryAddBody): ActionSaga<CategoryAddBody> => ({
  type: ADD_MULTIPLE_CATEGORY_ITEM,
  payload: { ...payload },
});

export const addCategoryItemSuccess = (
  payload: CategoryItemSuccessPayload
): ActionSaga<CategoryItemSuccessPayload> => ({
  type: ADD_CATEGORY_ITEM_SUCCESS,
  payload: { ...payload },
});

export const addCategoryItemError = (): ActionSaga => ({
  type: ADD_CATEGORY_ITEM_ERROR,
});

export const updateCategoryItem = (
  payload: CategoryUpdateBody
): ActionSaga<CategoryUpdateBody> => ({
  type: UPDATE_CATEGORY_ITEM,
  payload: { ...payload },
});

export const updateCategoryItemSuccess = (
  payload: CategoryItemSuccessPayload
): ActionSaga<CategoryItemSuccessPayload> => ({
  type: UPDATE_CATEGORY_ITEM_SUCCESS,
  payload: { ...payload },
});

export const updateCategoryItemError = (): ActionSaga => ({
  type: UPDATE_CATEGORY_ITEM_ERROR,
});

export const deleteCategoryItem = (
  payload: CategoryDeleteBody
): ActionSaga<CategoryDeleteBody> => ({
  type: DELETE_CATEGORY_ITEM,
  payload: { ...payload },
});

export const deleteCategoryItemSuccess = (
  payload: CategoryItemSuccessPayload
): ActionSaga<CategoryItemSuccessPayload> => ({
  type: DELETE_CATEGORY_ITEM_SUCCESS,
  payload: { ...payload },
});

export const deleteCategoryItemError = (): ActionSaga => ({
  type: DELETE_CATEGORY_ITEM_ERROR,
});
