/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { AppUI } from 'src/types/common.types';
import { LoadIn, LoadInInventoriesParams } from '../loadIn/types';
import { LoadOut } from '../loadOut/types';
import { TruckDelivery, TruckDeliveryBody, TruckDeliveryParams } from '../truckDeliveries/types';

export const getTruckDeliveryAdmin = createAction<{ id: string }>('admin/getTruckDeliveryAdmin');

export const getTruckDeliveryAdminSuccess = createAction<TruckDelivery>(
  'admin/getTruckDeliveryAdmin/success'
);

export const getDeliveryLoadInAdmin = createAction<{ truck_delivery_id?: string }>(
  'admin/getDeliveryLoadInAdmin'
);

export const getDeliveryLoadInAdminSuccess = createAction<LoadIn[]>(
  'admin/getDeliveryLoadInAdmin/success'
);

export const updateDeliveryLoadOutAdmin = createAction<LoadOut>('admin/updateDeliveryLoadOutAdmin');

export const updateDeliveryLoadOutAdminSuccess = createAction<LoadOut>(
  'admin/updateDeliveryLoadOutAdmin/success'
);

export const createDeliveryLoadOutAdmin = createAction<LoadOut>('admin/createDeliveryLoadOutAdmin');

export const createDeliveryLoadOutAdminSuccess = createAction<LoadOut>(
  'admin/createDeliveryLoadOutAdmin/success'
);

export const updateDeliveryAdmin = createAction<TruckDeliveryBody>('admin/updateDeliveryAdmin');

export const updateDeliveryLoadInAdmin = createAction<TruckDeliveryBody>(
  'admin/updateDeliveryLoadInAdmin'
);

export const updateDeliveryAdminSuccess = createAction<TruckDelivery>(
  'admin/updateDeliveryAdmin/success'
);

export const deleteDeliveryTruckCartAdmin = createAction<LoadIn>(
  'admin/deleteDeliveryTruckCartAdmin'
);

export const deleteDeliveryTruckCartAdminSuccess = createAction<LoadIn>(
  'admin/deleteDeliveryTruckCartAdmin/success'
);

export const getAdminError = createAction('admin/error');

export const sendBackToLoadInByTruckDelivery = createAction<{ id: string }>(
  'truckDeliveries/sendBackToLoadInByTruckDelivery'
);
export const sendBackToLoadInByTruckDeliverySuccess = createAction(
  'truckDeliveries/sendBackToLoadInByTruckDelivery/success'
);

export const setAdminUI = createAction<AppUI>('admin/setAdminUI');
