/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  addToTruckRequestOrder,
  createInvoiceRequestOrder,
  createRequestOrder,
  createRequestOrderSuccess,
  deleteRequestOrder,
  deleteRequestOrderSuccess,
  getRequestOrderItem,
  getRequestOrderItemSuccess,
  getRequestOrderListSuccess,
  getRequestOrderManagerList,
  getRequestOrderPersonalList,
  requestOrderError,
  resetRequestOrder,
  restoreRequestOrder,
  revertInvoiceRequestOrder,
  revertTruckRequestOrder,
  updateRequestOrder,
  updateRequestOrderItemInRequestOrder,
  updateRequestOrderSuccess,
} from './actions';
import { RequestOrderReducer } from './types';

const initialState: RequestOrderReducer = {};

const requestOrderReducer = createReducer(initialState, (builder) => {
  builder.addCase(getRequestOrderListSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.requestOrders = payload;
  });
  builder.addCase(getRequestOrderItemSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loading: false };
    state.requestOrder = { ...payload };
  });
  builder.addCase(createRequestOrderSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: false };
    state.requestOrder = payload;
  });
  builder.addCase(updateRequestOrderSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: false };
    state.requestOrder = payload;
  });
  builder.addCase(deleteRequestOrderSuccess, (state, { payload }) => {
    state.ui = { ...state.ui, loadingBtn: false };
    state.requestOrder = undefined;
  });
  builder.addCase(restoreRequestOrder, (state) => {
    state.requestOrder = undefined;
  });
  builder.addCase(updateRequestOrderItemInRequestOrder, (state, { payload }) => {
    if (state.requestOrder) {
      state.requestOrder = {
        ...state.requestOrder,
        request_order_items: state.requestOrder.request_order_items
          ? [...state.requestOrder.request_order_items, payload]
          : [payload],
      };
    }
  });
  builder.addCase(resetRequestOrder, (state) => {
    state.requestOrder = undefined;
  });
  builder.addCase(requestOrderError, (state) => {
    state.ui = {};
  });
  builder.addMatcher(
    isAnyOf(
      getRequestOrderManagerList,
      getRequestOrderPersonalList,
      getRequestOrderItem,
      revertInvoiceRequestOrder
    ),
    (state) => {
      state.ui = { ...state.ui, loading: true };
    }
  );
  builder.addMatcher(
    isAnyOf(
      updateRequestOrder,
      createRequestOrder,
      addToTruckRequestOrder,
      revertTruckRequestOrder,
      createInvoiceRequestOrder,
      deleteRequestOrder
    ),
    (state) => {
      state.ui = { ...state.ui, loadingBtn: true };
    }
  );
  builder.addDefaultCase((state) => {});
});

export default requestOrderReducer;
