import React from 'react';
import { CButton, CSpinner } from '@coreui/react';
import { CButtonProps } from '@coreui/react/dist/components/button/CButton';

export interface ButtonLoadingProps extends CButtonProps {
  loading?: boolean;
}

const ButtonLoading: React.FC<ButtonLoadingProps> = (props) => {
  const { loading, children, disabled, ...remainProps } = props;
  return (
    <CButton color="primary" {...remainProps} disabled={disabled || loading}>
      {loading && (
        <>
          <CSpinner component="span" size="sm" aria-hidden="true" /> <span className="px-2"></span>
        </>
      )}
      {children}
    </CButton>
  );
};

export default React.memo(ButtonLoading);
