/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import {
  addInvoiceItemError,
  addInvoiceItemSuccess,
  cancelledInvoiceItemError,
  cancelledInvoiceItemSuccess,
  clearInvoiceList,
  deleteInvoiceItemError,
  deleteInvoiceItemSuccess,
  getInvoiceItem,
  getInvoiceItemError,
  getInvoiceItemSuccess,
  getInvoiceListError,
  getInvoiceListSuccess,
  getMyInvoiceListError,
  getMyInvoiceListSuccess,
  updateInvoiceItemError,
  updateInvoiceItemSuccess,
} from './actions';
import {
  addInvoiceItemAPI,
  cancelledInvoiceItemAPI,
  deleteInvoiceItemAPI,
  getInvoiceItemAPI,
  getInvoiceManagerListAPI,
  getInvoicePersonalListAPI,
  updateInvoiceItemAPI,
} from './apis';
import {
  CreateInvoice,
  InvoiceAddBody,
  InvoiceDeleteBody,
  InvoiceItemPayload,
  InvoiceListParams,
  InvoiceUpdateBody,
} from './types';
import { sortByField } from 'src/helpers/common.helpers';

export function* getInvoiceListWorker(action: ActionSaga<InvoiceListParams>) {
  try {
    const { data }: SagaReturnType<typeof getInvoiceManagerListAPI> = yield call(
      getInvoiceManagerListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getInvoiceListSuccess({
        invoices: sortByField(data.data, ['date_time'], 'desc'),
        pagination: newPagination,
      })
    );
  } catch {
    showErrorToast();
    yield put(getInvoiceListError());
  }
}

export function* getMyInvoiceListWorker(action: ActionSaga<InvoiceListParams>) {
  try {
    const { data }: SagaReturnType<typeof getInvoicePersonalListAPI> = yield call(
      getInvoicePersonalListAPI,
      action.payload || undefined
    );
    const newPagination = action.payload?.pagination
      ? {
          ...action.payload.pagination,
          showPagination: data.data?.length === action.payload.pagination.limit,
        }
      : undefined;
    yield put(
      getMyInvoiceListSuccess({
        myInvoices: sortByField(data.data, ['date_time'], 'desc'),
        pagination: newPagination,
      })
    );
  } catch {
    showErrorToast();
    yield put(getMyInvoiceListError());
  }
}

export function* setFilterInvoiceListWorker(action: ActionSaga<InvoiceListParams>) {
  if (action.payload?.isMyInvoice) {
    yield put(clearInvoiceList({ isMyInvoice: true }));
    yield call(getMyInvoiceListWorker, action);
  } else {
    yield put(clearInvoiceList());
    yield call(getInvoiceListWorker, action);
  }
}

export function* getInvoiceItemWorker(action: ActionSaga<InvoiceItemPayload>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof getInvoiceItemAPI> = yield call(
        getInvoiceItemAPI,
        action.payload
      );
      yield put(
        getInvoiceItemSuccess({
          invoice: {
            ...data.data,
            invoice_items: data.data?.invoice_items?.map((e) => ({
              ...e,
              author_id: e?.truck_cart?.author_id,
              user_id: e?.truck_cart?.user_id,
            })),
          },
        })
      );
    }
  } catch {
    yield put(getInvoiceItemError());
    showErrorToast();
  }
}

/**
 *
 * Flow: 1. Create new invoice -> 2. Insert invoice item to new invoice -> 3. Done
 */
export function* addInvoiceItemWorker(action: ActionSaga<CreateInvoice>) {
  try {
    if (action.payload) {
      const newInventories =
        action.payload.temporary_invoice_items?.map((e) => ({
          id: e.inventory_id,
          name: e.item_name,
          price: e.item_price,
          quantity: e.item_quantity,
          discount: e.discount,
          truck_cart_id: e.truck_cart_id,
        })) || [];
      const newInvoice = {
        ...action.payload,
        tax: action.payload.tax ? +action.payload.tax : undefined,
        inventories: [...newInventories],
      } as InvoiceAddBody;
      delete newInvoice.temporary_invoice_items;
      const { data }: SagaReturnType<typeof addInvoiceItemAPI> = yield call(
        addInvoiceItemAPI,
        newInvoice
      );
      yield put(addInvoiceItemSuccess({ invoice: data.data }));
      showSuccessToast();
      window.location.replace(`#/invoices/${data.data.id}`);
    }
  } catch (e) {
    console.log(e);
    const error = e?.response?.data?.message;
    yield put(addInvoiceItemError());
    showErrorToast(error?.message);
    action?.payload?.callbackError?.(error);
  }
}

export function* updateInvoiceItemWorker(action: ActionSaga<InvoiceUpdateBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof updateInvoiceItemAPI> = yield call(
        updateInvoiceItemAPI,
        action.payload
      );
      yield put(updateInvoiceItemSuccess({ invoice: data.data }));

      if (action.payload.reload) {
        yield put(getInvoiceItem({ id: data.data.id }));
      }

      if (action.payload.callback) {
        action.payload.callback();
      }
      showSuccessToast();
    }
  } catch {
    yield put(updateInvoiceItemError());
    showErrorToast();
  }
}

export function* deleteInvoiceItemWorker(action: ActionSaga<InvoiceDeleteBody>) {
  try {
    if (action.payload) {
      const { data }: SagaReturnType<typeof deleteInvoiceItemAPI> = yield call(
        deleteInvoiceItemAPI,
        action.payload
      );
      yield put(deleteInvoiceItemSuccess({ invoice: { ...data.data, id: action.payload.id } }));
      showSuccessToast();
      window.location.replace(`#/invoices`);
    }
  } catch {
    yield put(deleteInvoiceItemError());
    showErrorToast();
  }
}

export function* cancelledInvoiceItemWorker(action: ActionSaga<InvoiceDeleteBody>) {
  try {
    if (action.payload?.id) {
      const { data }: SagaReturnType<typeof cancelledInvoiceItemAPI> = yield call(
        cancelledInvoiceItemAPI,
        action.payload
      );
      yield put(cancelledInvoiceItemSuccess({ invoice: { ...data.data, id: action.payload.id } }));
      showSuccessToast();
      yield put(getInvoiceItem({ id: action.payload.id }));
      if (action.payload?.callback) {
        action.payload.callback();
      }
    }
  } catch {
    yield put(cancelledInvoiceItemError());
    showErrorToast();
  }
}

// logic CRUD flow
// link tham khảo: https://redux-saga.js.org/docs/advanced/NonBlockingCalls
