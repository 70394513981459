import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import { Permission, PermissionBody, Role, RoleBody } from './types';

export const getListPermissionAPI = () => {
  return axiosQuery.get<ResponseStructure<Permission[]>>('permissions');
};

export const getListRoleAPI = () => {
  return axiosQuery.get<ResponseStructure<Role[]>>('roles');
};

export const setPermissionAPI = (payload: { permission: PermissionBody; role: RoleBody }) => {
  const { permission, role } = payload;
  return axiosQuery.post<ResponseStructure<Permission>>('permissions/' + permission.id, {
    ...role,
    roleId: role.id,
  });
};
