/**
 * The event send to the store
 */

import { ActionSaga } from 'src/types/saga.types';
import {
  UserAddBody,
  UserDeleteBody,
  UserFilterable,
  UserItemPayload,
  UserItemSuccessPayload,
  UserListParams,
  UserListSuccessPayload,
  UserUI,
  UserUpdateBody,
} from './types';

export const GET_USER_LIST = 'GET_USER_LIST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_ERROR = 'GET_USER_LIST_ERROR';
export const SET_FILTER_USER_LIST = 'SET_FILTER_USER_LIST';
export const CLEAR_USER_LIST = 'CLEAR_USER_LIST';

export const GET_USER_ITEM = 'GET_USER_ITEM';
export const GET_USER_ITEM_SUCCESS = 'GET_USER_ITEM_SUCCESS';
export const GET_USER_ITEM_ERROR = 'GET_USER_ITEM_ERROR';

export const ADD_USER_ITEM = 'ADD_USER_ITEM';
export const ADD_MULTIPLE_USER_ITEM = 'ADD_MULTIPLE_USER_ITEM';
export const ADD_USER_ITEM_SUCCESS = 'ADD_USER_ITEM_SUCCESS';
export const ADD_USER_ITEM_ERROR = 'ADD_USER_ITEM_ERROR';

export const UPDATE_USER_ITEM = 'UPDATE_USER_ITEM';
export const UPDATE_USER_ITEM_SUCCESS = 'UPDATE_USER_ITEM_SUCCESS';
export const UPDATE_USER_ITEM_ERROR = 'UPDATE_USER_ITEM_ERROR';

export const DELETE_USER_ITEM = 'DELETE_USER_ITEM';
export const DELETE_USER_ITEM_SUCCESS = 'DELETE_USER_ITEM_SUCCESS';
export const DELETE_USER_ITEM_ERROR = 'DELETE_USER_ITEM_ERROR';

export const SET_USER_VISIBLE = 'SET_USER_VISIBLE';

export const getUserList = (payload?: UserListParams): ActionSaga<UserListParams> => ({
  type: GET_USER_LIST,
  payload: payload ? { ...payload } : null,
});

export const getUserListSuccess = (
  payload: UserListSuccessPayload
): ActionSaga<UserListSuccessPayload> => ({
  type: GET_USER_LIST_SUCCESS,
  payload: { ...payload },
});

export const getUserListError = (): ActionSaga => ({
  type: GET_USER_LIST_ERROR,
});

export const setFilterUserList = (payload?: UserFilterable): ActionSaga<UserListParams> => ({
  type: SET_FILTER_USER_LIST,
  payload: { filterable: { ...payload } },
});

export const clearUserList = (): ActionSaga<UserListSuccessPayload> => ({
  type: CLEAR_USER_LIST,
  payload: { users: [] },
});

export const getUserItem = (payload: UserItemPayload): ActionSaga<UserItemPayload> => ({
  type: GET_USER_ITEM,
  payload: { ...payload },
});

export const getUserItemSuccess = (
  payload: UserItemSuccessPayload
): ActionSaga<UserItemSuccessPayload> => ({
  type: GET_USER_ITEM_SUCCESS,
  payload: { ...payload },
});

export const getUserItemError = (): ActionSaga => ({
  type: GET_USER_ITEM_ERROR,
});

export const addUserItem = (payload: UserAddBody): ActionSaga<UserAddBody> => ({
  type: ADD_USER_ITEM,
  payload: { ...payload },
});

export const addMultipleUserItem = (payload: UserAddBody): ActionSaga<UserAddBody> => ({
  type: ADD_MULTIPLE_USER_ITEM,
  payload: { ...payload },
});

export const addUserItemSuccess = (
  payload: UserItemSuccessPayload
): ActionSaga<UserItemSuccessPayload> => ({
  type: ADD_USER_ITEM_SUCCESS,
  payload: { ...payload },
});

export const addUserItemError = (): ActionSaga => ({
  type: ADD_USER_ITEM_ERROR,
});

export const updateUserItem = (payload: UserUpdateBody): ActionSaga<UserUpdateBody> => ({
  type: UPDATE_USER_ITEM,
  payload: { ...payload },
});

export const updateUserItemSuccess = (
  payload: UserItemSuccessPayload
): ActionSaga<UserItemSuccessPayload> => ({
  type: UPDATE_USER_ITEM_SUCCESS,
  payload: { ...payload },
});

export const updateUserItemError = (): ActionSaga => ({
  type: UPDATE_USER_ITEM_ERROR,
});

export const deleteUserItem = (payload: UserDeleteBody): ActionSaga<UserDeleteBody> => ({
  type: DELETE_USER_ITEM,
  payload: { ...payload },
});

export const deleteUserItemSuccess = (
  payload: UserItemSuccessPayload
): ActionSaga<UserItemSuccessPayload> => ({
  type: DELETE_USER_ITEM_SUCCESS,
  payload: { ...payload },
});

export const deleteUserItemError = (): ActionSaga => ({
  type: DELETE_USER_ITEM_ERROR,
});

export const setUserVisible = (payload: {
  visible?: boolean;
}): ActionSaga<
  UserUI<{
    visible?: boolean;
  }>
> => ({
  type: SET_USER_VISIBLE,
  payload: { ui: { ...payload } },
});
