import { CAlert, CButton, CCol, CForm, CFormLabel } from '@coreui/react';
import React, { useEffect, useMemo, useState } from 'react';
import PhoneNumberInput from 'src/components/common/input/PhoneNumberInput';
import SubmitModal from 'src/components/common/modal/SubmitModal';
import { useAppDispatch, useAppSelector } from 'src/define/store-hooks.interface';
import { Invoice } from 'src/features/invoice/types';
import { getSettingDefaultPhone } from 'src/features/setting/actions';
import { showDateTime } from 'src/helpers/common.helpers';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { useModal } from 'src/hooks/useModal';
import { axiosQuery } from 'src/services/axiosQuery';
import { validatePhoneNumber } from 'src/validated/common.validated';

type SendSMSButtonProps = {
  invoice: Invoice;
};

const { REACT_APP_BUSINESS_NAME } = process.env;

const SendSMSButton: React.FC<SendSMSButtonProps> = ({ invoice }) => {
  const { visible, closeModal, showModal } = useModal();
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [form, setForm] = useState<any>({
    phone: '',
  });
  const { default_phone_sms } = useAppSelector((s) => s.setting);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!default_phone_sms) {
      dispatch(getSettingDefaultPhone());
    }

    if (default_phone_sms) {
      setForm((s) => ({
        ...s,
        phone: default_phone_sms.value,
      }));
    }
  }, [dispatch, default_phone_sms, invoice.id]);

  const handleSendSMS = async (e) => {
    e.preventDefault();
    const formCurrent = e.currentTarget;

    if (!validatePhoneNumber(form?.phone)) {
      showErrorToast('Invalid phone number');
      return;
    }

    if (formCurrent.checkValidity()) {
      try {
        setLoadingBtn(true);
        setValidated(true);
        await axiosQuery.post('invoices/send-sms', {
          message: `${REACT_APP_BUSINESS_NAME} - Date: ${showDateTime(
            new Date().toISOString(),
            'MM/DD/YY HH:mm'
          )} - Invoice Id ${invoice.id}`,
          link: window.location.href,
          phone: form.phone,
          hash: window.location.hash + '/public',
        });
        showSuccessToast('Send SMS successfully');
        closeModal();
      } catch (e) {
        const message = e.response?.data?.message ?? '';
        showErrorToast(message || 'Cannot send sms');
        console.error(e);
      } finally {
        setValidated(false);
        setLoadingBtn(false);
      }
    }
  };
  return (
    <>
      <CButton onClick={showModal}>Send SMS</CButton>
      <SubmitModal
        visible={visible}
        headerTitle={'Send SMS'}
        content={
          <>
            <CAlert color="warning">This action is only working with US Phone Number</CAlert>
            <CForm
              id="send-sms-form"
              className="row needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSendSMS}
            >
              <CCol xs={12}>
                <CFormLabel>
                  Phone <span className="text-danger">*</span>
                </CFormLabel>
                <PhoneNumberInput
                  defaultValue={form?.phone}
                  onChangeInput={(value) => setForm((s) => ({ ...s, phone: value }))}
                  invalid={!validatePhoneNumber(form?.phone)}
                  required
                />
              </CCol>
            </CForm>
          </>
        }
        onClose={closeModal}
        okProps={{ loading: loadingBtn, form: 'send-sms-form' }}
      />
    </>
  );
};

export default React.memo(SendSMSButton);
