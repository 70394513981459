/**
 * For process asynchronous
 * The pure functions to get current state, work the action and return new a state
 */

import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { WarehouseReturnReducer } from './types';
import {
  createWarehouseReturn,
  createWarehouseReturnSuccess,
  deleteWarehouseReturn,
  deleteWarehouseReturnSuccess,
  getListWarehouseReturn,
  getListWarehouseReturnSuccess,
  getWarehouseReturn,
  warehouseReturnError,
  getWarehouseReturnSuccess,
  updateWarehouseReturn,
  updateWarehouseReturnSuccess,
} from './actions';

const initialState: WarehouseReturnReducer = {};

const warehouseReturnReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getListWarehouseReturnSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
      state.warehouseReturns = payload;
    })
    .addCase(getWarehouseReturnSuccess, (state, { payload }) => {
      state.ui = { ...state.ui, loading: false };
      state.warehouseReturn = payload;
    })
    .addCase(warehouseReturnError, (state) => {
      state.ui = {};
    })
    .addMatcher(isAnyOf(getWarehouseReturn, getListWarehouseReturn), (state) => {
      state.ui = { ...state.ui, loading: true };
    })
    .addMatcher(
      isAnyOf(updateWarehouseReturn, createWarehouseReturn, deleteWarehouseReturn),
      (state) => {
        state.ui = { ...state.ui, loadingBtn: true };
      }
    )
    .addMatcher(
      isAnyOf(
        updateWarehouseReturnSuccess,
        createWarehouseReturnSuccess,
        deleteWarehouseReturnSuccess
      ),
      (state) => {
        state.ui = { ...state.ui, loadingBtn: false };
      }
    )
);

export default warehouseReturnReducer;
