/**
 * The event send to the store
 */

import { createAction } from '@reduxjs/toolkit';
import { ActionSaga } from 'src/types/saga.types';
import {
  InventoryAddBody,
  InventoryDeleteBody,
  InventoryFilterable,
  InventoryImportBody,
  InventoryItemPayload,
  InventoryItemSuccessPayload,
  InventoryListOptionsSuccessPayload,
  InventoryListParams,
  InventoryListSuccessPayload,
  InventoryUI,
  InventoryUpdateBody,
} from './types';

export const GET_INVENTORY_LIST = 'GET_INVENTORY_LIST';
export const GET_INVENTORY_LIST_SUCCESS = 'GET_INVENTORY_LIST_SUCCESS';
export const GET_INVENTORY_LIST_ERROR = 'GET_INVENTORY_LIST_ERROR';
export const SET_FILTER_INVENTORY_LIST = 'SET_FILTER_INVENTORY_LIST';
export const CLEAR_INVENTORY_LIST = 'CLEAR_INVENTORY_LIST';

export const GET_INVENTORY_OPTIONS_LIST = 'GET_INVENTORY_OPTIONS_LIST';
export const GET_INVENTORY_OPTIONS_LIST_SUCCESS = 'GET_INVENTORY_OPTIONS_LIST_SUCCESS';
export const GET_INVENTORY_OPTIONS_LIST_ERROR = 'GET_INVENTORY_OPTIONS_LIST_ERROR';

export const GET_INVENTORY_ITEM = 'GET_INVENTORY_ITEM';
export const GET_INVENTORY_ITEM_SUCCESS = 'GET_INVENTORY_ITEM_SUCCESS';
export const GET_INVENTORY_ITEM_ERROR = 'GET_INVENTORY_ITEM_ERROR';

export const ADD_INVENTORY_ITEM = 'ADD_INVENTORY_ITEM';
export const ADD_MULTIPLE_INVENTORY_ITEM = 'ADD_MULTIPLE_INVENTORY_ITEM';
export const ADD_INVENTORY_ITEM_SUCCESS = 'ADD_INVENTORY_ITEM_SUCCESS';
export const ADD_INVENTORY_ITEM_ERROR = 'ADD_INVENTORY_ITEM_ERROR';

export const UPDATE_INVENTORY_ITEM = 'UPDATE_INVENTORY_ITEM';
export const UPDATE_INVENTORY_ITEM_SUCCESS = 'UPDATE_INVENTORY_ITEM_SUCCESS';
export const UPDATE_INVENTORY_ITEM_ERROR = 'UPDATE_INVENTORY_ITEM_ERROR';

export const DELETE_INVENTORY_ITEM = 'DELETE_INVENTORY_ITEM';
export const DELETE_INVENTORY_ITEM_SUCCESS = 'DELETE_INVENTORY_ITEM_SUCCESS';
export const DELETE_INVENTORY_ITEM_ERROR = 'DELETE_INVENTORY_ITEM_ERROR';

export const IMPORT_INVENTORY_FILE = 'IMPORT_INVENTORY_FILE';
export const IMPORT_INVENTORY_FILE_SUCCESS = 'IMPORT_INVENTORY_FILE_SUCCESS';
export const IMPORT_INVENTORY_FILE_ERROR = 'IMPORT_INVENTORY_FILE_ERROR';

export const ROLL_BACK_INVENTORY = 'ROLL_BACK_INVENTORY';
export const ROLL_BACK_INVENTORY_SUCCESS = 'ROLL_BACK_INVENTORY_SUCCESS';
export const ROLL_BACK_INVENTORY_ERROR = 'ROLL_BACK_INVENTORY_ERROR';

export const SET_INVENTORY_VISIBLE = 'SET_INVENTORY_VISIBLE';

export const getInventoryList = (
  payload?: InventoryListParams
): ActionSaga<InventoryListParams> => ({
  type: GET_INVENTORY_LIST,
  payload: payload ? { ...payload } : null,
});

export const getInventoryListSuccess = (
  payload: InventoryListSuccessPayload
): ActionSaga<InventoryListSuccessPayload> => ({
  type: GET_INVENTORY_LIST_SUCCESS,
  payload: { ...payload },
});

export const getInventoryListError = (): ActionSaga => ({
  type: GET_INVENTORY_LIST_ERROR,
});

export const setFilterInventoryList = (
  payload?: InventoryListParams
): ActionSaga<InventoryListParams> => ({
  type: SET_FILTER_INVENTORY_LIST,
  payload: { ...payload },
});

export const clearInventoryList = (): ActionSaga<InventoryListSuccessPayload> => ({
  type: CLEAR_INVENTORY_LIST,
  payload: { inventories: [] },
});

export const getInventoryOptionsList = (
  payload?: InventoryListParams
): ActionSaga<InventoryListParams> => ({
  type: GET_INVENTORY_OPTIONS_LIST,
  payload: payload ? { ...payload } : null,
});

export const getInventoryOptionsListSuccess = (
  payload: InventoryListOptionsSuccessPayload
): ActionSaga<InventoryListOptionsSuccessPayload> => ({
  type: GET_INVENTORY_OPTIONS_LIST_SUCCESS,
  payload: { ...payload },
});

export const getInventoryOptionsListError = (): ActionSaga => ({
  type: GET_INVENTORY_OPTIONS_LIST_ERROR,
});

export const getInventoryItem = (
  payload: InventoryItemPayload
): ActionSaga<InventoryItemPayload> => ({
  type: GET_INVENTORY_ITEM,
  payload: { ...payload },
});

export const getInventoryItemSuccess = (
  payload: InventoryItemSuccessPayload
): ActionSaga<InventoryItemSuccessPayload> => ({
  type: GET_INVENTORY_ITEM_SUCCESS,
  payload: { ...payload },
});

export const getInventoryItemError = (): ActionSaga => ({
  type: GET_INVENTORY_ITEM_ERROR,
});

export const addInventoryItem = (payload: InventoryAddBody): ActionSaga<InventoryAddBody> => ({
  type: ADD_INVENTORY_ITEM,
  payload: { ...payload },
});

export const addMultipleInventoryItem = (
  payload: InventoryAddBody
): ActionSaga<InventoryAddBody> => ({
  type: ADD_MULTIPLE_INVENTORY_ITEM,
  payload: { ...payload },
});

export const addInventoryItemSuccess = (
  payload: InventoryItemSuccessPayload
): ActionSaga<InventoryItemSuccessPayload> => ({
  type: ADD_INVENTORY_ITEM_SUCCESS,
  payload: { ...payload },
});

export const addInventoryItemError = (): ActionSaga => ({
  type: ADD_INVENTORY_ITEM_ERROR,
});

export const updateInventoryItem = (
  payload: InventoryUpdateBody
): ActionSaga<InventoryUpdateBody> => ({
  type: UPDATE_INVENTORY_ITEM,
  payload: { ...payload },
});

export const updateInventoryItemSuccess = (
  payload: InventoryItemSuccessPayload
): ActionSaga<InventoryItemSuccessPayload> => ({
  type: UPDATE_INVENTORY_ITEM_SUCCESS,
  payload: { ...payload },
});

export const updateInventoryItemError = (): ActionSaga => ({
  type: UPDATE_INVENTORY_ITEM_ERROR,
});

export const deleteInventoryItem = (
  payload: InventoryDeleteBody
): ActionSaga<InventoryDeleteBody> => ({
  type: DELETE_INVENTORY_ITEM,
  payload: { ...payload },
});

export const deleteInventoryItemSuccess = (
  payload: InventoryItemSuccessPayload
): ActionSaga<InventoryItemSuccessPayload> => ({
  type: DELETE_INVENTORY_ITEM_SUCCESS,
  payload: { ...payload },
});

export const deleteInventoryItemError = (): ActionSaga => ({
  type: DELETE_INVENTORY_ITEM_ERROR,
});

export const importInventoryFile = (
  payload: InventoryImportBody
): ActionSaga<InventoryImportBody> => ({
  type: IMPORT_INVENTORY_FILE,
  payload: { ...payload },
});

export const importInventoryFileSuccess = () => ({
  type: IMPORT_INVENTORY_FILE_SUCCESS,
});

export const importInventoryFileError = (): ActionSaga => ({
  type: IMPORT_INVENTORY_FILE_ERROR,
});

export const setInventoryVisible = (payload: {
  visible: boolean;
}): ActionSaga<InventoryUI<{ visible: boolean }>> => ({
  type: SET_INVENTORY_VISIBLE,
  payload: { ui: { ...payload } },
});

export const rollBackInventory = (): ActionSaga => ({
  type: ROLL_BACK_INVENTORY,
});

export const rollBackInventorySuccess = () => ({
  type: ROLL_BACK_INVENTORY_SUCCESS,
});

export const rollBackInventoryError = (): ActionSaga => ({
  type: ROLL_BACK_INVENTORY_ERROR,
});
