/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import { getLoadInInventories } from './actions';
import { getLoadInInventoriesWorker } from './workers';

export function* loadInSaga() {
  yield takeLatest(getLoadInInventories, getLoadInInventoriesWorker);
}
