/**
 * For process asynchronous
 * Handle the action and terminate
 */

import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import { getTruckCartListByDeliveryAPI } from '../truckCart/apis';
import { currentUserError, getCurrentUserTruckCartSuccess } from './actions';

export function* getCurrentUserTruckCartWorker(action: ActionSaga<{ truck_delivery_id: string }>) {
  try {
    const { payload } = action;
    if (payload?.truck_delivery_id) {
      const { data }: SagaReturnType<typeof getTruckCartListByDeliveryAPI> = yield call(
        getTruckCartListByDeliveryAPI,
        payload
      );
      yield put(getCurrentUserTruckCartSuccess(data.data));
    }
  } catch (e) {
    showErrorToast();
    yield put(currentUserError());
    console.error('Get truck cart error', e);
  }
}
