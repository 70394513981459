import { call, put, SagaReturnType } from 'redux-saga/effects';
import { showErrorToast, showSuccessToast } from 'src/helpers/toast.helpers';
import { ActionSaga } from 'src/types/saga.types';
import {
  getSettingDefaultPhoneError,
  getSettingDefaultPhoneSuccess,
  getSettingGlobalTaxError,
  getSettingGlobalTaxSuccess,
  getSettingListError,
  getSettingListSuccess,
  updateSettingError,
  updateSettingSuccess,
} from './actions';
import { getSettingListAPI, updateSettingAPI } from './apis';
import { SettingAddBody, SettingParams } from './types';

export function* getSettingListWorker(action: ActionSaga<SettingParams>) {
  try {
    const { data }: SagaReturnType<typeof getSettingListAPI> = yield call(
      getSettingListAPI,
      action.payload || {}
    );
    yield put(
      getSettingListSuccess({
        settings: data.data,
      })
    );
  } catch {
    yield put(getSettingListError());
  }
}

export function* getGlobalTaxItemWorker() {
  try {
    const { data }: SagaReturnType<typeof getSettingListAPI> = yield call(getSettingListAPI, {
      filterable: { key: 'global_tax' },
    });
    yield put(
      getSettingGlobalTaxSuccess({
        global_tax: data.data?.[0],
      })
    );
  } catch {
    yield put(getSettingGlobalTaxError());
  }
}

export function* getDefaultPhoneWorker() {
  try {
    const { data }: SagaReturnType<typeof getSettingListAPI> = yield call(getSettingListAPI, {
      filterable: { key: 'default_phone_sms' },
    });
    yield put(
      getSettingDefaultPhoneSuccess({
        default_phone_sms: data.data?.[0],
      })
    );
  } catch {
    yield put(getSettingDefaultPhoneError());
  }
}

export function* updateSettingWorker(action: ActionSaga<SettingAddBody>) {
  try {
    const { payload } = action;
    if (payload) {
      const { data }: SagaReturnType<typeof updateSettingAPI> = yield call(
        updateSettingAPI,
        payload
      );
      yield put(updateSettingSuccess(data.data));
      showSuccessToast();
    }
  } catch {
    yield put(updateSettingError());
    showErrorToast();
  }
}
