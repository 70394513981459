/**
 * For process asynchronous
 * The apis fetch
 */

import { axiosQuery } from 'src/services/axiosQuery';
import { ResponseStructure } from 'src/types/axios.types';
import {
  InvoiceDraftItem,
  InvoiceDraftItemAddBody,
  InvoiceDraftItemDeleteBody,
  InvoiceDraftItemImportBody,
  InvoiceDraftSubItemPayload,
  InvoiceDraftItemListParams,
  InvoiceDraftItemUpdateBody,
} from './types';

const rootAPI = 'invoice-draft-items';

export const getInvoiceDraftItemListAPI = (payload?: InvoiceDraftItemListParams) => {
  return axiosQuery.get<ResponseStructure<InvoiceDraftItem[]>>(rootAPI, {
    params: { ...payload?.pagination, ...payload?.filterable },
  });
};

export const getInvoiceDraftSubItemAPI = (payload?: InvoiceDraftSubItemPayload) => {
  return axiosQuery.get<ResponseStructure<InvoiceDraftItem>>(`${rootAPI}/${payload?.id}`);
};

export const addInvoiceDraftSubItemAPI = (payload?: InvoiceDraftItemAddBody) => {
  return axiosQuery.post<ResponseStructure<InvoiceDraftItem>>(rootAPI, payload, {});
};

export const updateInvoiceDraftSubItemAPI = (payload?: InvoiceDraftItemUpdateBody) => {
  if (payload?.request_order_item_id) {
    return axiosQuery.post<ResponseStructure<InvoiceDraftItem>>(
      `${rootAPI}/${payload?.id}/update-from-request-order`,
      {
        ...payload,
      }
    );
  }
  return axiosQuery.post<ResponseStructure<InvoiceDraftItem>>(
    `${rootAPI}/${payload?.id}`,
    {
      _method: 'PUT',
      ...payload,
    },
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
};

export const deleteInvoiceDraftSubItemAPI = (payload?: InvoiceDraftItemDeleteBody) => {
  if (payload?.request_order_item_id) {
    return axiosQuery.delete<ResponseStructure<InvoiceDraftItem>>(
      `${rootAPI}/${payload?.id}/destroy-from-request-order`
    );
  }
  return axiosQuery.delete<ResponseStructure<InvoiceDraftItem>>(`${rootAPI}/${payload?.id}`);
};

export const importInvoiceDraftItemFileAPI = (payload?: InvoiceDraftItemImportBody) => {
  return axiosQuery.post<ResponseStructure<InvoiceDraftItem>>(`${rootAPI}/import`, payload?.file);
};
