/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeLatest } from 'redux-saga/effects';
import {
  addInvoiceItem,
  cancelledInvoiceItem,
  deleteInvoiceItem,
  getInvoiceItem,
  getInvoiceList,
  getMyInvoiceList,
  setFilterInvoiceList,
  updateInvoiceItem,
} from './actions';
import {
  addInvoiceItemWorker,
  deleteInvoiceItemWorker,
  getInvoiceItemWorker,
  getInvoiceListWorker,
  setFilterInvoiceListWorker,
  updateInvoiceItemWorker,
  getMyInvoiceListWorker,
  cancelledInvoiceItemWorker,
} from './workers';

export function* invoiceSaga() {
  yield takeLatest(getInvoiceList, getInvoiceListWorker);
  yield takeLatest(getMyInvoiceList, getMyInvoiceListWorker);
  yield takeLatest(getInvoiceItem, getInvoiceItemWorker);
  yield takeLatest(setFilterInvoiceList, setFilterInvoiceListWorker);
  yield takeLatest(addInvoiceItem, addInvoiceItemWorker);
  yield takeLatest(updateInvoiceItem, updateInvoiceItemWorker);
  yield takeLatest(deleteInvoiceItem, deleteInvoiceItemWorker);
  yield takeLatest(cancelledInvoiceItem, cancelledInvoiceItemWorker);
}
