import { createAction } from '@reduxjs/toolkit';
import { RequestOrderItem, RequestOrderItemBody } from './types';

export const createRequestOrderItem = createAction<RequestOrderItemBody>(
  'requestOrderItem/createRequestOrderItem'
);

export const createOrUpdateRequestOrderItem = createAction<RequestOrderItemBody>(
  'requestOrderItem/createOrUpdateRequestOrderItem'
);
export const createRequestOrderItemSuccess = createAction<RequestOrderItem>(
  'requestOrderItem/createRequestOrderItem/success'
);

export const updateRequestOrderItem = createAction<
  RequestOrderItemBody & { callback?: (item?: RequestOrderItemBody) => void }
>('requestOrderItem/updateRequestOrderItem');
export const updateRequestOrderItemSuccess = createAction<RequestOrderItem>(
  'requestOrderItem/updateRequestOrderItem/success'
);

export const deleteRequestOrderItem = createAction<RequestOrderItemBody>(
  'requestOrderItem/deleteRequestOrderItem'
);
export const deleteRequestOrderItemSuccess = createAction<RequestOrderItem>(
  'requestOrderItem/deleteRequestOrderItem/success'
);

export const requestOrderItemError = createAction('requestOrderItem/requestOrderItemError');
