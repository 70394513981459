/**
 * For process asynchronous
 * Watch for dispatched actions and fork a worker on every action
 */

import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createLoadOutInventories,
  getLoadOutInventories,
  updateLoadOutInventories,
} from './actions';
import {
  createLoadOutInventoriesWorker,
  getLoadOutInventoriesWorker,
  updateLoadOutInventoriesWorker,
} from './workers';

export function* loadOutSaga() {
  yield takeLatest(getLoadOutInventories, getLoadOutInventoriesWorker);
  yield takeEvery(createLoadOutInventories, createLoadOutInventoriesWorker);
  yield takeEvery(updateLoadOutInventories, updateLoadOutInventoriesWorker);
}
